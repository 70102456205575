import * as React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import BootstrapButton from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import styles from "./button.module.scss";

export default function Button(props) {
  const { loading, disabled, onClick, className, children, iconButton, ...extraProps } =
    props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <BootstrapButton
      disabled={loading || disabled}
      onClick={handleClick}
      className={cx(iconButton ? styles.iconButton : styles.button, className)}
      {...extraProps}
    >
      {loading ? (
        <Spinner animation="border" size="sm" className={styles.spinner} />
      ) : (
        <>{children}</>
      )}
    </BootstrapButton>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  iconButton: PropTypes.bool
};

Button.defaultProps = {
  iconButton: false
};
