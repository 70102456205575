import React from 'react';
import { useRouter } from 'next/router';

export const useGetUrl = () => {
  const { asPath, locale } = useRouter();
  const rootUrl = typeof window !== 'undefined' ? window.location.origin : '';
  const localePath = locale === 'en' ? '/en' : '';
  const canonicalUrl = `${rootUrl}${localePath}${asPath === '/' ? '' : asPath?.split("?")[0]}`;

  const fullyQualify = React.useCallback((href) => {
    return `${rootUrl}${localePath}${href}`;
  }, [rootUrl, localePath]);

  return { rootUrl, canonicalUrl, localePath, asPath, fullyQualify };
};
