import { setCookie, getCookie } from "@separate/utilities/cookies";

const EXPIRATION_TIME = 24 * 60 * 60;

const COOKIE_KEY = "utm_tags";

export function filterTags(query) {
  if (!query) return null;

  const {
    utm_source = null,
    utm_medium = null,
    utm_campaign = null,
    utm_term = null,
    utm_content = null,
    utm_source_url = null
  } = query;

  const result = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    utm_source_url
  };

  if (!Object.values(result).some(value => value != null)) {
    return null;
  }

  return result;
}

export function rememberUtmTags(queryParams) {
  const tags = filterTags(queryParams);
  if (!tags) return;

  const existingValue = getCookie(COOKIE_KEY);
  const existingValueIsEmpty = JSON.stringify(existingValue) === '{}';

  if (!existingValue || existingValueIsEmpty) {
    setCookie(COOKIE_KEY, tags, EXPIRATION_TIME);
  }
}

export function getUtmTags() {
  const tags = getCookie(COOKIE_KEY) || {};
  if (!tags.utm_source_url && typeof window !== "undefined") {
    tags.utm_source_url = window.location.href;
  }
  return tags;
}
