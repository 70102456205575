import merge from 'lodash/merge';
import separateEn from '@separate/locales/en';
import address from './address';
import articles from './articles';
import benefits from './benefits';
import creditcard from './creditcard';
import earn from './earn';
import farmasep from './farmasep';
import home from './home';
import jobs from './jobs';
import learn from './learn';
import login from './login';
import menu from './menu';
import meta from './meta';
import newBenefitsPharmacySearch from './newBenefitsPharmacySearch';
import podercard from './podercard';
import premium from './premium';
import qa from './qa';
import studies from './studies';
import surveys from './surveys';
import surveys_landing from './surveysLanding';
import user from './user';
import validations from './validations';

const en = {
  translation: {
    ...separateEn.translation.common,
    address,
    articles,
    creditcard,
    earn,
    farmasep,
    home,
    jobs,
    learn,
    login,
    menu,
    meta,
    newBenefitsPharmacySearch,
    premium: { ...premium, ...separateEn.translation.premium },
    qa,
    studies,
    surveys,
    surveys_landing,
    user: merge(separateEn.translation.user, user),
    validations: { ...validations, ...separateEn.translation.validations },
    podercard,
    benefits: {...benefits, ...separateEn.translation.benefits},
  }
};

export default en;
