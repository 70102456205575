import { useMutation } from 'react-query';
import { assumeRoleFor, revokeAccessToken } from '@separate/api/auth';
import { login, registration, confirm, resendCode, singleSignOn } from '@separate/api/login';
import { useSetCurrentUserSession } from '@separate/hooks/session';
import * as auth from '@separate/utilities/auth';
import sleep from '@separate/utilities/sleep';

export function useLogin({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ phoneNumber }) => login(phoneNumber),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 404
    }
  );
  return {
    isLoading,
    login: mutate,
  };
}

export function useResendCode({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ phoneNumber }) => resendCode(phoneNumber),
    {
      onSuccess,
      onError,
    }
  );
  return {
    isLoading,
    resendCode: mutate,
  };
}

export function useRegistration({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    (registrationParams) => registration(registrationParams),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 409 && response?.status !== 422
    }
  );
  return {
    isLoading,
    registration: mutate,
  };
}

async function completeLogin({ data, setCurrentUserSession }) {
  if (!data) {
    // TODO:  Handle this somehow
    return;
  }

  const roleData = await assumeRoleFor(data.id_token);
  auth.setAccessToken(data.access_token);
  auth.setIdToken(data.id_token);
  auth.setRefreshToken(data.refresh_token);
  auth.setTemporaryCredentials(roleData);

  // go to sleep, race condition when calling API gateway too quickly after keys are issued
  await sleep(500);

  setCurrentUserSession(data.id_token);
}

async function completeLoginUponConfirmation({ phoneNumber, code, setCurrentUserSession }) {
  const data = await confirm({ phoneNumber, code });

  await completeLogin({ data, setCurrentUserSession });
}

async function completeLoginWithSingleSignOn({ token, setCurrentUserSession, onError }) {
  if (auth.getIdToken()) return;

  const data = await singleSignOn(token);

  if (data.errors) {
    onError(data.errors);
    return;
  }

  await completeLogin({ data, setCurrentUserSession });
}

export function useConfirm({ onSuccess, onError }) {
  const setCurrentUserSession = useSetCurrentUserSession();

  const { isLoading, mutate } = useMutation(
    ({ phoneNumber, code }) => completeLoginUponConfirmation({ phoneNumber, code, setCurrentUserSession }),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 404
    }
  );
  return {
    isLoading,
    confirm: mutate,
  };
}

export function useSingleSignOn({ onSuccess, onError }) {
  const setCurrentUserSession = useSetCurrentUserSession();

  const { isLoading, mutate } = useMutation(
    (token) => completeLoginWithSingleSignOn({ token, setCurrentUserSession, onError }),
    {
      onSuccess,
    }
  );
  return {
    isLoading,
    signOn: mutate,
  };
}

async function completeLogout({ setCurrentUserSession }) {
  await revokeAccessToken();
  auth.reset();
  setCurrentUserSession(null);
}

export function useLogout({ onSuccess }) {
  const setCurrentUserSession = useSetCurrentUserSession();

  const { isLoading, mutate } = useMutation(
    () => completeLogout({ setCurrentUserSession }),
    { onSuccess }
  );
  return {
    isLoading,
    logout: mutate,
  };
}
