import { useMutation } from 'react-query';
import { addDependent, deleteDependent, getDependents } from '@separate/api/dependents';

export const useAddDependent = ({ onSuccess, onError }) => {
  const result = useMutation(addDependent, {
    onSuccess,
    onError,
    useErrorBoundary: ({ response }) => response?.status !== 422,
  });

  const { isLoading, mutate } = result;

  return { isLoading, addUserDependent: mutate };
};

export const useGetDependents = ({ onSuccess, onError }) => {
  const result = useMutation(getDependents, {
    onSuccess,
    onError,
  });

  const { isLoading, mutate } = result;

  return { isLoading, fetchUserDependents: mutate };
};

export const useDeleteDependent = ({ onSuccess, onError }) => {
  const result = useMutation(deleteDependent, {
    onSuccess,
    onError,
  });

  const { isLoading, mutate } = result;

  return { isLoading, deleteUserDependent: mutate };
};
