import { set, get, clear } from '@separate/utilities/tokens';

const TEMPORARY_CREDENTIALS = "temporary_credentials";
const ID_TOKEN = "id_token";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const PHONE_NUMBER = "phone_number";

export function setTemporaryCredentials(data) {
  const temporaryCredentials = {
    accessKey: data.Credentials.AccessKeyId,
    secretKey: data.Credentials.SecretAccessKey,
    sessionToken: data.Credentials.SessionToken,
    expiration: data.Credentials.Expiration
  };
  set(
    TEMPORARY_CREDENTIALS,
    JSON.stringify(temporaryCredentials)
  );
}

export function getTemporaryCredentials() {
  return JSON.parse(get(TEMPORARY_CREDENTIALS));
}

export function setAccessToken(accessToken) {
  set(ACCESS_TOKEN, accessToken);
}

export function getAccessToken() {
  return get(ACCESS_TOKEN);
}

export function setIdToken(idToken) {
  set(ID_TOKEN, idToken);
}

export function getIdToken() {
  return get(ID_TOKEN);
}

export function setRefreshToken(refreshToken) {
  set(REFRESH_TOKEN, refreshToken);
}

export function getRefreshToken() {
  return get(REFRESH_TOKEN);
}

export function setPhoneNumber(phoneNumber) {
  set(PHONE_NUMBER, phoneNumber);
}

export function getPhoneNumber() {
  return get(PHONE_NUMBER);
}

export function loggedIn() {
  return !!getIdToken();
}

export function reset() {
  clear(TEMPORARY_CREDENTIALS);
  clear(ACCESS_TOKEN);
  clear(ID_TOKEN);
  clear(REFRESH_TOKEN);
  clear(PHONE_NUMBER);
}
