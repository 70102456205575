import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { LanguageEN, LanguageES } from '@/icons';
import LangSelectModal from './LangSelectModal';
import styles from './languageSelector.module.scss';

export default function LanguageSelector(props) {
  const { className, locale, onSelect, disabled, ...extraProps } = props;
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleSelect = (val) => {
    onSelect(val);
    setShow(false);
  };

  return (
    <>
      <Button onClick={handleClick} className={className} iconButton disabled={disabled}>
        {locale === 'en' ? (
          <LanguageEN className={styles.icon} />
        ) : (
          <LanguageES className={styles.icon} />
        )}
      </Button>
      <LangSelectModal show={show} locale={locale} onHide={handleSelect} />
    </>
  );
}

LanguageSelector.propTypes = {
  locale: PropTypes.oneOf(['en', 'es']).isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
