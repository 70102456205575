const dynamicRoutes = {
  // Manually added
  article: {
    coronavirus: '*',
    finance: '*',
    health: '*',
    education: '*',
    legal: '*',
    benefits: '*',
  },
  articles: {
    coronavirus: '',
    finance: '',
    health: '',
    education: '',
    legal: '',
    benefits: '',
    search: '',
  },

  // Pasted in from v3routes
  "benefits": {
    "*": {
      "*": {
        "faq": "",
        "providers": "",
        "/": ""
      },
      "faq": "",
      "providers": "",
      "/": ""
    },
    "favorite_providers": "",
    "/": ""
  },
  "earn": {
    "/": "",
    "learn": "",
    "surveys": ""
  },
  "farmasep": {
    "/": "",
    "result": ""
  },
  "podercard": "",
  "podersalud": {
    "/": "",
    "address": "",
    "intro": "",
    "payment": "",
    "personal_info": ""
  },
  "podersalud_cancellation_policy": "",
  "poderseguros": "",
  "premium": {
    "/": "",
    "enrollment": {
      "/": "",
      "success": ""
    }
  },
  "qa": "",
  "rewards": "",
  "rewards_center": "",
  "success": "*",
  "survey_center": "",
  "surveys": "*",
  "tnc": ""
};

export default dynamicRoutes;
