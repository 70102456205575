const surveys = {
  access: {
    back: "Volver a",
    error_subtitle: "Gracias por intentar participar. ¡Visite Recompensas frecuentemente para ser de las primeras personas en postularse a futuras encuestas!",
    footer: "Obtenga sus ingresos rápidamente con la cuenta bancaria PODERcard",
    inactive: "Lo sentimos, esta encuesta ya no está disponible. Favor de tomar las encuestas lo más pronto que le sea posible para poder participar. Pronto recibirá mas encuestas. ¡Gracias!",
    inaccessible: "Lo sentimos, usted no califica para tomar esta encuesta.",
    notFound: "Encuesta no encontrada.",
    other_surveys: "Inténtelo de nuevo con otra encuesta",
    rewards: "Recompensas",
    untakeable: "Disculpe pero usted ya tomó esta encuesta.",
  },
  copy: "Comience a ganar dinero",
  cta: "Take survey (SP)",
  header: "Grupo de Opinión",
  insert_text: "Añadir respuesta",
  numeric_freeform: {
    placeholder: "Añadir respuesta numérica",
    error_any_number: "Ingrese cualquier número positivo o cero",
    error_with_range: "Envíe una respuesta entre {{ min }} y {{ max }}",
  },
  qualify_title: "Encuestas disponibles",
  qualify_subtitle: "¡Gane recompensas por dar su opinión!",
  reward: "¡Descubra si califica para encuestas pagadas en plataformas aliadas!",
  spotlight: "Encuesta destacada",
  text_freeform: {
    placeholder: "Ingresar tu respuesta",
  },
  time: "10-15 minutos",
  next_question: "Siguiente",
  completed: {
    title: "Buen trabajo, {{ name }}",
    title_insights: "¡Gracias, {{ name }}!",
    subtitle: "Usted completó",
    added_balance: "agregado al su cuenta",
    added_to_podercard: "agregado al su PODERcard",
    view_in_app: "Ver en la app",
  },
  declined: {
    title: "Gracias,",
  },
  footer_default_value: "¿Necesita ayuda? ¡Llámenos! 844-447-2237",
  lucid_modal: {
    title: "¡Aviso importante!",
    subtitle: "Está saliendo de SABEResPODER y será redirigido a Lucid, otra plataforma con la que nos asociamos para ofrecer más recompensas.",
    content: {
      text_one: "Lucid es un servicio tercerizado y PODERcard no controla quién califica para sus encuestas. Las ganancias no están garantizadas hasta que responda una serie de preguntas y califique para participar en cada encuesta.",
      text_two: "La integración que tenemos con Lucid le permite recibir sus ganancias aquí en nuestro Centro de Recompensas.",
    },
    cta: "Continuar a Lucid",
    back: "Volver",
  },
};

export default surveys;
