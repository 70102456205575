import psl from 'psl';
import { DEPLOY_ENV } from '@separate/env';
import { getCookie, setCookie } from './cookies';
import { clear as clearLocalStorage, get as getLocalStorage, set as setLocalStorage } from './localStorage';

const EPOCHALYPSE_NOW_SECONDS = (2147483647 * 1000 - Date.now()) / 1000;

// Utilities
function getDomainAttribute() {
  const domain = psl.get(location.hostname);

  return domain ? { domain: domain } : undefined;
}

// Replicate our localStorage API
// NOTE: We prefix the cookie key names with the DEPLOY_ENV--on set, get, and
// clear--to prevent unwanted collisions across different environments. When
// falling back to localStorage for backwards compatibility, however, we do not
// use this prefix
export function set(key, value, expirationSeconds = EPOCHALYPSE_NOW_SECONDS) {
  if (!navigator.cookieEnabled) {
    setLocalStorage(key, value);
    return;
  }

  const otherAttributes = getDomainAttribute();

  setCookie(`${DEPLOY_ENV}_${key}`, value, expirationSeconds, otherAttributes);
}

export function get(key) {
  if (!navigator.cookieEnabled) {
    return getLocalStorage(key);
  }

  const theCookie = getCookie(`${DEPLOY_ENV}_${key}`);
  if (!theCookie) {
    // Check localStorage for backwards compatibility
    const value = getLocalStorage(key);
    if (value) {
      // Set the cookie for cross-domain support
      set(key, value);
      // and clear out the localStorage copy
      clearLocalStorage(key);
    }
    return value;
  }

  return theCookie;
}

export function clear(key) {
  if (!navigator.cookieEnabled) {
    clearLocalStorage(key);
    return;
  }

  const otherAttributes = getDomainAttribute();

  // Delete the cookie by setting its expiration time to zero
  setCookie(`${DEPLOY_ENV}_${key}`, '', 0, otherAttributes);
  // Clear local storage as well, just in case
  clearLocalStorage(key);
}
