import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import SubscriptionOptionRadioCard from '@separate/components/CTA/SubscriptionOptionRadioCard';
import PaymentMethods from '@separate/components/PaymentMethods';
import AddNewCardOffcanvas from '@separate/components/PaymentMethods/AddNewCardOffcanvas';
import AlertModal from '@separate/components/PodersaludUpgradeSubscriptionPlan/AlertModal';
import { PREMIUM_ANNUAL_MEMBERSHIP_PRICE } from '@separate/constants/premium';
import { PLAN_INFORMATION, UPDATE_YOUR_PLAN, PAYMENT_METHODS } from '@separate/constants/profile';
import {
  useEditPaymentMethodFetch,
  useUpgradeSubscription,
  useUpdateSetupIntent
} from '@separate/hooks/premium';
import { useTranslation, useLocale } from '@separate/hooks/translation';
import { Diamond, Save } from '@separate/icons/index';
import { formatProfileDate, addOneYear } from '@separate/utilities/datetime';
import { asCurrency } from '@separate/utilities/string';
import styles from './podersaludUpgradeSubscriptionPlan.module.scss';
import PodersaludUpgradeSubscriptionPlanModal from './PodersaludUpgradeSubscriptionPlanModal';

const PodersaludUpgradeSubscriptionPlan = (props) => {
  const {
    container,
    subscriptionQuery,
    onDismiss,
    primaryPaymentMethodId,
    fetchSubscriptionHook,
    updateSetupIntentHook,
    pid,
    resetListPaymentMethodsHook,
    removePaymentMethodHook,
    listPaymentMethodsHook,
    upgradeSubscriptionHook,
    confirmSetupIntentHook,
    updatePaymentMethodHook,
    textButton,
    title
  } = props;
  const [showAlert, setShowAlert] = React.useState(false);
  const [subscription, setSubscription] = React.useState();
  const [view, setView] = React.useState(PLAN_INFORMATION);
  const [showNewCard, setShowNewCard] = React.useState(false);
  const [isRetryPayment, setIsRetryPayment] = React.useState(false);
  const [hidePaymentMethods, setHidePaymentMethods] = React.useState(false);

  const { t } = useTranslation();
  const locale = useLocale();

  React.useEffect(() => {
    if (!fetchSubscription) return;

    fetchSubscription({ pid });
  }, [fetchSubscription, pid]);

  const onFetchSubscriptionSuccess = React.useCallback((data) => {
    setSubscription({
      ...subscriptionQuery,
      client_secret: data?.subscription?.client_secret,
      setup_intent_id: data?.subscription?.setup_intent_id
    });
  }, [subscriptionQuery]);
  const { fetchSubscription } = fetchSubscriptionHook({ onSuccess: onFetchSubscriptionSuccess });

  const { isLoading: isLoadingUpgradeSubscription, upgradeSubscription } = upgradeSubscriptionHook({
    onSuccess: onUpgradeSubscriptionSuccess,
    onError: onUpgradeSubscriptionError
  });

  function onUpgradeSubscriptionSuccess() {
    setShowAlert({ success: true });
  }

  function onUpgradeSubscriptionError() {
    setShowAlert({ failed: true });
  }

  const handleClickUpgradeNow = () => {
    setView(UPDATE_YOUR_PLAN);
  };

  const handleClickUpdateYourPlan = () => {
    setShowNewCard(false);
    setHidePaymentMethods(false);
    setView(PAYMENT_METHODS);
  };

  const handleClickAddNewCard = () => {
    setHidePaymentMethods(true);
    setShowNewCard(true);
  };

  const handleClickSuccessPayment = () => {
    setView(PLAN_INFORMATION);
    upgradeSubscription(pid);
  };

  const handleClickRetryPaymentMethod = () => {
    setHidePaymentMethods(false);
    setShowAlert({ failed: false });
    setIsRetryPayment(true);
    setView(PAYMENT_METHODS);
  };

  const handleHidePaymentMethods = () => {
    setHidePaymentMethods(true);
  };

  return (
    <>
      <div className={styles.poderSaludSubscriptionPlan}>
        <div className={styles.priceContainer}>
          <Diamond />
          <div className={styles.info}>
            <p>
              {`PODERsalud ${t('premium.upgrade_subscription_plan.monthly')} `}
              <span className={styles.currency}>{`${asCurrency(
                subscription?.price / 100
              )}`}</span>
            </p>
            <p>
              {`${t('premium.upgrade_subscription_plan.next_billing_date')} `}
              <span className={styles.date}>{`${formatProfileDate(
                subscription?.next_payment_at
              )}`}</span>
            </p>
          </div>
        </div>
        <hr />
        <div className={styles.saveContainer}>
          <div className={styles.info}>
            <Save />
            <p>{title ? title : t('premium.upgrade_subscription_plan.save_more')}</p>
          </div>
          <Button onClick={handleClickUpgradeNow}>
            {textButton ? textButton : t('premium.upgrade_subscription_plan.upgrade_plan')}
          </Button>
        </div>
      </div>

      {view === UPDATE_YOUR_PLAN && (
        <PodersaludUpgradeSubscriptionPlanModal
          onHide={() => setView(false)}
          title={t('premium.upgrade_subscription_plan.update_your_plan')}
          subtitle={t('premium.upgrade_subscription_plan.save_over_50_per_year')}
          description={t(
            'premium.upgrade_subscription_plan.you_will_be_charge_today_the_full_year',
            { next_payment: formatProfileDate(addOneYear()) }
          )}
          buttonText={t('premium.upgrade_subscription_plan.proceed_to_payment')}
          onClick={handleClickUpdateYourPlan}
          container={container}
        >
          <SubscriptionOptionRadioCard
            isAnnual
            annualPrice={PREMIUM_ANNUAL_MEMBERSHIP_PRICE}
            className={styles.subscriptionRadioCard}
          />
        </PodersaludUpgradeSubscriptionPlanModal>
      )}

      {view === PAYMENT_METHODS && (
        <PodersaludUpgradeSubscriptionPlanModal
          onHide={() => setView(false)}
          container={container}
          onClick={handleClickSuccessPayment}
          hidePaymentMethods={hidePaymentMethods}
          isRetryPayment={isRetryPayment}
          handleClickAddNewCard={handleClickAddNewCard}
          title={
            isRetryPayment
              ? t('premium.upgrade_subscription_plan.revise_your_payment_method')
              : t('premium.upgrade_subscription_plan.select_payment_option')
          }
          buttonText={
            isRetryPayment
              ? t('premium.upgrade_subscription_plan.retry_with_this_card')
              : locale === 'es'
                ? t('premium.payment.pay_now')
                : t('premium.upgrade_subscription_plan.upgrade_now')
          }
          subtitle={isRetryPayment && t('premium.upgrade_subscription_plan.you_can_try_again')}
        >
          <PaymentMethods
            container={container}
            handleClickAddNewCard={handleClickAddNewCard}
            primaryPaymentMethodId={primaryPaymentMethodId}
            isRetryPayment={isRetryPayment}
            handleHidePaymentMethods={handleHidePaymentMethods}
            updateSetupIntentHook={updateSetupIntentHook}
            pid={pid}
            listPaymentMethodsHook={listPaymentMethodsHook}
            fetchSubscriptionHook={fetchSubscriptionHook}
            confirmSetupIntentHook={confirmSetupIntentHook}
            resetListPaymentMethodsHook={resetListPaymentMethodsHook}
            removePaymentMethodHook={removePaymentMethodHook}
          />
        </PodersaludUpgradeSubscriptionPlanModal>
      )}

      <AddNewCardOffcanvas
        clientSecret={subscription?.client_secret}
        container={container}
        onHide={() => setShowNewCard(false)}
        setupIntentId={subscription?.setup_intent_id}
        show={showNewCard}
        onSuccess={handleClickSuccessPayment}
        onCancel={handleClickUpdateYourPlan}
        isUpgradeSubscriptionPlan
        pid={pid}
        updateSetupIntentHook={updateSetupIntentHook}
        updatePaymentMethodHook={updatePaymentMethodHook}
      />

      <AlertModal
        onHide={() => onDismiss()}
        container={container}
        isLoading={isLoadingUpgradeSubscription}
        success={showAlert.success}
        failed={showAlert.failed}
        onDismiss={onDismiss}
        retryPaymentMethods={handleClickRetryPaymentMethod}
        nextPayment={formatProfileDate(addOneYear())}
      />
    </>
  );
};

PodersaludUpgradeSubscriptionPlan.propTypes = {
  container: PropTypes.any,
  listPaymentMethodsHook: PropTypes.func,
  subscriptionQuery: PropTypes.object,
  onDismiss: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  resetListPaymentMethodsHook: PropTypes.func,
  removePaymentMethodHook: PropTypes.func,
  fetchSubscriptionHook: PropTypes.func,
  upgradeSubscriptionHook: PropTypes.func,
  confirmSetupIntentHook: PropTypes.func,
  updateSetupIntentHook: PropTypes.func,
  updatePaymentMethodHook: PropTypes.func,
  pid: PropTypes.string,  // for zendesk-sep-admin
  title: PropTypes.string,
  textButton: PropTypes.string
};

PodersaludUpgradeSubscriptionPlan.defaultProps = {
  fetchSubscriptionHook: useEditPaymentMethodFetch,
  upgradeSubscriptionHook: useUpgradeSubscription,
  updateSetupIntentHook: useUpdateSetupIntent,
};

export default PodersaludUpgradeSubscriptionPlan;
