const premium = {
  coupon: {
    placeholder: "Añadir cupón",
    success: {
      title: "¡Éxito!",
      message: "Su cupón ha sido redimido",
    },
    error: {
      title: "¡Uh-oh!",
      message: "Código de Cupón Incorrecto",
    },
    redeem: "Redimir",
    payment_one: "pago",
    payment_other: "{{ count }} pagos",
    coupon_applied: "¡Felicidades! Recibirá un descuento del {{ discount }} en sus primeros {{ duration }}",
    your_subscription: '¡Tiene ${{ coupon }} de regalo para suscribirse!',
    discount_will_be_automatically_applied_at_checkout: 'El descuento se aplica al seleccionar su plan de pago.'
  },
  payment: {
    title: "Pago",
    choose_the_plan: "Seleccione el plan de suscripción que le funcione mejor",
    subtitle: "Ingrese los datos de pago para empezar su suscripción",
    plan_type: "Tipo de plan",
    selected: "Su elección:",
    plan_price: "Costo del plan",
    membership: "Membresía anual SEP+ ",
    annual_membership: "Membresía anual",
    monthly_membership: "Membresía mensual",
    monthly: "Mensual",
    price: "Precio",
    duration: "mes",
    credit_card_number: "Número de tarjeta",
    expiry: "Expiración mm/aa",
    cvv: "CVV",
    confirm_purchase: "Confirmar compra",
    failure_title: "¡Uh-oh!",
    product_name: "Membresía Premium",
    or: "O",
    pay_with_card: "pague con tarjeta",
    update_and_pay: "Actualizar + Pagar",
    yearly: "Anual",
    proceed: "Proceder al pago",
    one_time_discount: 'Cupón de uso único',
    total: 'Total',
    confirmed: 'Confirmación de pago',
    received: '¡Muchas gracias! Su pago ya fue recibido.',
    processing: 'Procesando pago...',
    change_payment_method: 'Cambiar método de pago',
    pay_now: 'Hacer pago',
    added: '¡Su tarjeta ha sido añadida!',
    added_subtitle: '¿Quiere proceder a pagar su suscripción con este método de pago?',
    failed: '¡Oh! Su pago no pasó',
    ensure_funds_or_new_card: 'Asegúrese de tener fondos en su cuenta o intente añadir una nueva tarjeta.',
    will_be_default: 'This card will be used as your default card for future payments (SP)',
  },
  past_due: {
    status_message: "El pago de su suscripción a PODERsalud no pasó. Por favor consulte el saldo de su tarjeta y actualice su método de pago.",
    success_title: "Felicidades",
    success_message: "Su pago se ha procesado correctamente y la información de su tarjeta se ha actualizado.",
  },
  not_available: "Lo sentimos, este producto no está disponible en su estado.",
  update_payment: {
    success_title: "Felicidades",
    // TODO: This is probably wrong.  We don't have copy for this in proton
    success_message: "La información de su tarjeta se ha actualizado.",
  },
  annual: {
    billed_annually: "Un solo cobro <strong>anual</strong>",
    billed_monthly: "Cobro <strong>mensual</strong>",
    discount_percentage: "{{ discount }}% de ahorro",
    months_free: "{{ number }} meses gratis",
    no_discount: "Sin discuento",
    pay_full_price: "Pagar precio regular",
    per_month: "/ mes",
    per_year: "/ año",
  },
  ask_us_anything: {
    title: "Pregúntenos cualquier duda",
    got_questions: "¿Tiene preguntas? ¿Le gustaría saber si PODERsalud es ideal para usted? ¡Con gusto le asistimos!",
    talk_with_an_expert: "Hable con un experto",
  },
  upgrade_subscription_plan: {
    monthly: 'mensual',
    yearly: 'anual',
    next_billing_date: 'Próxima fecha de facturación:',
    save_more: '¡Ahorre más de $50 con el plan anual!',
    update_your_plan: 'Actualización de plan',
    upgrade_now: 'Actualizar ahora',
    save_over_50_per_year: 'Ahorre más de $50 al año en su suscripción a PODERsalud con nuestro plan anual.',
    you_will_be_charge_today_the_full_year: 'Efectuaremos hoy el cargo por todo el año. Su próxima fecha de facturación será en {{ next_payment }}.',
    proceed_to_payment: 'Proceder al pago',
    processing_payment: 'Procesando pago...',
    your_payment_has_been_received: 'Su pago ya fue recibido. ¡Muchas gracias! Su próxima fecha de facturación es en {{ next_payment }}.',
    your_plan_has_been_upgraded: '¡Su plan ha sido actualizado!',
    close: 'Cerrar',
    your_payment_failed: '¡Oh! Su pago no pasó',
    make_sure_you_have_sufficient_funds: 'Asegúrese de tener fondos en su cuenta o intente añadir una nueva tarjeta.',
    try_again: 'Inténtelo de nuevo',
    add_payment: 'Add payment (SP)',
    revise_your_payment_method: 'Revise su método de pago',
    confirm_your_payment_method: 'Confirme su método de pago',
    retry_with_this_card: 'Reintentar con esta tarjeta',
    you_can_try_again: 'Puede intentar de nuevo con su actual método de pago o cambiarlo.',
    go_back: 'Volver',
    select_payment_option: 'Confirme su método de pago',
    change_payment_method: 'Cambiar método de pago',
    upgrade_plan: 'Actualizar plan'
  }
};

export default premium;
