import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getSubscription,
  getPendingPremiumSubscription,
  postPaymentSuccess,
  getPastDuePayment,
  getEditPaymentMethod,
  updateCouponCode,
  updatePaymentMethod,
  submitFreeTrialSetup,
} from '@/api/premium';
import { useCurrentUserSession } from '@/hooks/session';
import { isSubscriptionPastDue } from '@separate/utilities/premium';
import { useDeepLinkUpdatePaymentModal } from './updatePayment';
import { useCurrentUser } from './user';

function subscriptionQueryKey(idToken) {
  return ['subscription', idToken];
}

export function useResetSubscriptionQuery() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(subscriptionQueryKey(idToken));
}

export function useSubscriptionQuery(options = {}) {
  const { idToken } = useCurrentUserSession();

  const { fastRefresh } = options;
  const refetchOnMount = fastRefresh ? 'always' : true;

  const { isLoading, data } = useQuery(subscriptionQueryKey(idToken), getSubscription, { refetchOnMount });

  return { isLoading, subscription: data };
}

export function usePendingPremiumSubscriptionFetch({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    getPendingPremiumSubscription,
    {
      onSuccess,
      onError,
    }
  );
  return { isLoading, fetchPendingPremiumSubscription: mutate };
}

export function useActivatePremiumMembership({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    postPaymentSuccess,
    {
      onSuccess,
    }
  );
  return { isLoading, activatePremiumMembership: mutate };
}

export function usePastDuePaymentFetch({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    getPastDuePayment,
    {
      onSuccess,
    }
  );
  return { isLoading, fetchPastDuePayment: mutate };
}

export function useEditPaymentMethodFetch({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    getEditPaymentMethod,
    {
      onSuccess,
    }
  );
  return { isLoading, fetchEditPaymentMethod: mutate };
}

export function useCouponCodeUpdate({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    updateCouponCode,
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updateCouponCode: mutate };
}

export function useUpdatePaymentMethod({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    updatePaymentMethod,
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updatePaymentMethod: mutate };
}

export function useFreeTrialSetup({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    submitFreeTrialSetup,
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, setupFreeTrial: mutate };
}

export function useGetMissedPayment() {
  const { user } = useCurrentUser();

  const [pastDue, setPastDue] = React.useState(false);
  const [showMissedPaymentModal, setShowMissedPaymentModal] = React.useState(false);
  const [showMissedPaymentBanner, setShowMissedPaymentBanner] = React.useState(false);
  const [status, setStatus] = React.useState();

  const { subscription } = useSubscriptionQuery();
  const { showDeepLinkUpdatePaymentModal } = useDeepLinkUpdatePaymentModal();

  const userHasMissedPayment = pastDue && user?.is_premium_member;

  React.useEffect(() => {
    if (!subscription) return;

    setPastDue(isSubscriptionPastDue(subscription));
    setStatus(subscription.status);
  }, [subscription]);

  React.useEffect(() => {
    const turnOffMissedPayment = () => {
      setShowMissedPaymentModal(false);
      setShowMissedPaymentBanner(false);
    };

    if (userHasMissedPayment && !showDeepLinkUpdatePaymentModal) {
      setShowMissedPaymentModal(true);
    } else {
      turnOffMissedPayment();
    }

    return () => {
      turnOffMissedPayment();
    };
  }, [pastDue, showDeepLinkUpdatePaymentModal, status, userHasMissedPayment]);

  return {
    userHasMissedPayment,
    showMissedPaymentModal,
    setShowMissedPaymentModal,
    showMissedPaymentBanner,
    setShowMissedPaymentBanner,
  };
}
