import PropTypes from 'prop-types';

export const idType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const optionType = PropTypes.shape({
  id: idType,
  text: PropTypes.string.isRequired,
});

export const hrefType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool
]);

export const addressType = PropTypes.shape({
  address_line_1: PropTypes.string,
  address_line_2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
});

export const phoneNumberType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const inputFormStateType = PropTypes.shape({
  value: PropTypes.object,
  isValid: PropTypes.bool,
  fieldErrors: PropTypes.object,
  onFieldChange: PropTypes.func.isRequired,
  onFieldBlur: PropTypes.func.isRequired,
  labelFor: PropTypes.func,
});

export const addressVerificationStateType = PropTypes.shape({
  correctedAddress: PropTypes.object,
  showCorrectionModal: PropTypes.bool,
  showFailureModal: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onAcceptCorrection: PropTypes.func.isRequired,
});

export const articleType = PropTypes.shape({
  article_author: PropTypes.shape({
    author_name: PropTypes.string,
  }),
  article_category: PropTypes.string,
  article_content: PropTypes.array,
  created_at: PropTypes.string,
  hero_image: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  id: PropTypes.string,
  publish_details: PropTypes.shape({
    time: PropTypes.string,
  }),
  metaDescriptionSEO: PropTypes.string,
  metaTitleSEO: PropTypes.string,
  title: PropTypes.string,
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
  }),
  uid: PropTypes.string,
  url: PropTypes.string,
});

export const surveyType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  title_es: PropTypes.string.isRequired,
  incentive: PropTypes.string,
  interview_length: PropTypes.number,
});

export const courseType = surveyType;

export const supportedLocales = [
  'en',
  'es',
];

export const localeType = PropTypes.oneOf(supportedLocales);

export const unkeyedErrorsType = PropTypes.arrayOf(PropTypes.string);
