import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { useLocale, useTranslation } from '@/hooks/translation';
import RadioGroup from '@separate/components/RadioGroup';
import { CHILD } from '@separate/constants/dependents';
import { translateTextInObject } from '@separate/utilities/string';
import styles from '../index.module.scss';

export default function QuestionSelections(
  {
    title,
    questionOptions,
    answer,
    setAnswer,
    loading,
    onEditInputForm,
    onDependentAge
  }) {
  const locale = useLocale();
  const { t } = useTranslation();
  const { selected } = answer;

  const options = translateTextInObject(questionOptions, locale);

  const handleChange = (val) => {
    setAnswer({ ...answer, selected: val });
  };

  const handleSubmit = () => {
    if (selected === CHILD) {
      onDependentAge();
    } else {
      onEditInputForm();
    }
  };

  return (
    <div className={styles.dependentInfo}>
      <h6>{title}</h6>
      <div className='my-3'>
        <RadioGroup
          options={options}
          value={selected}
          onChange={handleChange}
          disabled={loading}
          className={cx(styles.radioGroup, 'mb-3 d-flex align-items-center')}
        />
      </div>
      <div className='d-block mt-4'>
        <Button
          className={styles.submitCta}
          onClick={handleSubmit}
          disabled={!answer.selected}
        >
          {t('next')}
        </Button>
      </div>
    </div>
  );
}

QuestionSelections.propTypes = {
  title: PropTypes.string.isRequired,
  questionOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  answer: PropTypes.object.isRequired,
  setAnswer: PropTypes.func.isRequired,
  onEditInputForm: PropTypes.func,
  onDependentAge: PropTypes.func,
};
