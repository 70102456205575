import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import Spinner from '@separate/components/Spinner';
import { PAYMENT_FAILED } from '@separate/constants/analytics';
import { useTranslation } from '@separate/hooks/translation';
import CreditCardForm from './CreditCardForm';
import styles from './paymentWidget.module.scss';
import PayWithCardButton from './PayWithCardButton';
import WalletButton from './WalletButton';

function PaymentWidget(props) {
  const {
    setupMode,
    clientSecret,
    loading,
    price,
    productName,
    onSuccess,
    submitText,
    className,
    useSepStyling,
    handleLogEvent
  } = props;

  const [walletLoading, setWalletLoading] = React.useState(true);
  const [walletAvailable, setWalletAvailable] = React.useState(false);
  const [showPayWithCard, setShowPayWithCard] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { t } = useTranslation();

  const handleWalletButtonAvailable = React.useCallback((value) => {
    setWalletLoading(false);
    setWalletAvailable(!!value);
    setShowPayWithCard(!value);
  }, []);

  const handlePaymentFailure = React.useCallback((message) => {
    setErrorMessage(message);
    setShowErrorModal(true);
    handleLogEvent(PAYMENT_FAILED, {});
  }, [handleLogEvent]);

  function handleDismiss() {
    setShowErrorModal(false);
  }

  const shouldShowPayWithCard = walletAvailable && !showPayWithCard;
  const shouldHideCreditCardForm =
    !setupMode && (walletLoading || shouldShowPayWithCard);

  return (
    <div className={styles.paymentWidgetWrapper}>
      {!setupMode && (
        <>
          {walletLoading && <Spinner />}
          <WalletButton
            clientSecret={clientSecret}
            price={price}
            productName={productName}
            hide={!walletAvailable}
            onAvailable={handleWalletButtonAvailable}
            onSuccess={onSuccess}
            onFailure={handlePaymentFailure}
          />

          {shouldShowPayWithCard && (
            <PayWithCardButton onClick={() => setShowPayWithCard(true)} />
          )}
        </>
      )}

      <CreditCardForm
        setupMode={setupMode}
        clientSecret={clientSecret}
        loading={loading}
        hide={shouldHideCreditCardForm}
        onSuccess={onSuccess}
        onFailure={handlePaymentFailure}
        submitText={submitText}
        className={className}
        useSepStyling={useSepStyling}
      />

      <Modal
        show={showErrorModal}
        onHide={handleDismiss}
        title={t('premium.payment.failure_title')}
      >
        <p>{errorMessage}</p>
        <Button onClick={handleDismiss}>{t('dismiss')}</Button>
      </Modal>
    </div>
  );
}

PaymentWidget.propTypes = {
  setupMode: PropTypes.bool,
  clientSecret: PropTypes.string,
  loading: PropTypes.bool,
  price: PropTypes.number,
  productName: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  className: PropTypes.string,
  useSepStyling: PropTypes.bool,
  handleLogEvent: PropTypes.func,
};

export default PaymentWidget;
