import * as React from 'react';
import PropTypes from 'prop-types';
import { useConfirm, useResendCode } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import styles from '../steps.module.scss';
import InputForm from './InputForm';
import useConfirmationInputFormState from './useConfirmationInputFormState';

export default function ConfirmationCode(props) {
  const { phoneNumber, onConfirmationCodeSuccess } = props;
  const { t } = useTranslation();

  const inputFormState = useConfirmationInputFormState();
  const { confirmation } = inputFormState.value;

  function onSuccess(response) {
    onConfirmationCodeSuccess();
  }

  function onError() {
    const fieldErrors = {
      confirmation: [
        t(`validations.incorrect`, {field: `^${inputFormState?.labelFor("confirmation")}`})
      ]
    };
    inputFormState.mergeErrors(fieldErrors);
  }

  function onResendSuccess({ response }) {
    // The resend is fire and forget.  This is a no-op.
  }

  function onResendError({ response }) {
    // TODO: Handle code is incorrect case.  This probably shouldn't ever happen.
    // If the resend fails it should 500 out I suspect.
    console.log("resend code error status", response.status);
  }

  const { isLoading: isConfirmLoading, confirm } = useConfirm({ onSuccess, onError });
  const { isResendLoading, resendCode } = useResendCode({ onResendSuccess, onResendError });
  const isLoading = isConfirmLoading || isResendLoading;

  function handleSubmit() {
    confirm({ phoneNumber, code: confirmation });
  }

  function handleResend() {
    resendCode({ phoneNumber });
  }

  return (
    <div className={styles.confirmationContent}>
      <h3 className={styles.label}>{t('login.enter_the_confirmation_code')}</h3>
      <InputForm
        loading={isLoading}
        onSubmit={handleSubmit}
        onResend={handleResend}
        inputFormState={inputFormState}
      />
    </div>
  );
}

ConfirmationCode.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onConfirmationCodeSuccess: PropTypes.func.isRequired
};
