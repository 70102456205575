import ADDRESS_VALIDATIONS from '@/components/address/validations';
import useInputFormState from '@/hooks/useInputFormState';
import { required, name, email } from '@/validations';

const VALIDATIONS = {
  first_name: {
    translationKey: 'user.first_name',
    rules: [required, name],
  },
  last_name: {
    translationKey: 'user.last_name',
    rules: [required, name],
  },
  gender: {
    translationKey: 'user.gender',
    rules: [required],
  },
  email: {
    translationKey: 'user.email',
    rules: [required, email],
  },
};

export default function useEditBasicInfoInputFormState(hasFullInfo) {
  let validations = {};

  function add(field) {
    validations[field] = VALIDATIONS[field];
  }

  add('first_name');
  add('last_name');

  if (hasFullInfo) {
    add('gender');
    add('email');
    validations = { ...validations, ...ADDRESS_VALIDATIONS };
  }

  return useInputFormState(validations);
}
