import { getCookie } from '@separate/utilities/cookies';
import { compact } from '@separate/utilities/object';
import baseAuthAdminQuery from './baseAuthAdminQuery';

const BASE_PATH = '/premium_subscriptions';

export async function getSubscription() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/status`,
    method: 'get',
  });

  return response?.data;
}

export async function getPendingPremiumSubscription(utmTags = {}) {
  let query_params = new URLSearchParams(compact(utmTags)).toString();
  if (getCookie('referralCode')) {
    if (query_params) {
      query_params = `${query_params}&referral_code=${getCookie('referralCode')}`;
    } else {
      query_params = `referral_code=${getCookie('referralCode')}`;
    }
  }
  const path = `${BASE_PATH}/initialize?${query_params}`;

  const response = await baseAuthAdminQuery({
    path,
    method: 'get',
  });

  return response?.data;
}

export async function postPaymentSuccess() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/activate`,
    method: 'post',
  });

  return response?.data;
}

export async function getPastDuePayment() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/edit_current_payment_method`,
    method: 'get',
  });

  return response?.data;
}

export async function getEditPaymentMethod() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/edit_future_payment_method`,
    method: 'get',
  });

  return response?.data;
}

export async function updateCouponCode(couponCode) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/coupon_code`,
    method: 'post',
    data: {
      coupon_code: couponCode,
    },
  });

  return response?.data;
}

export async function updateInterval(interval) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/interval`,
    method: 'post',
    data: {
      interval: interval,
    },
  });

  return response?.data;
}

export async function updatePaymentMethod(setupIntentId) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/future_payment_method`,
    method: 'post',
    data: {
      setup_intent_id: setupIntentId,
    },
  });

  return response?.data;
}

// This is effectively an alias.  It hits the same backend endpoint, but is named after its specific purpose
export async function submitFreeTrialSetup(setupIntentId) {
  return updatePaymentMethod(setupIntentId);
}

export async function listPaymentMethods() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/list_payment_methods`,
    method: 'get',
  });

  return response?.data;
}

export async function updateSetupIntent(setupIntentId, optional, paymentMethodId) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/future_payment_method`,
    method: 'put',
    data: {
      setup_intent_id: setupIntentId,
      optional: optional,
      payment_method_id: paymentMethodId,
    },
  });

  return response?.data;
}

export async function removePaymentMethod(paymentMethodId) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/delete_payment_method`,
    method: 'delete',
    data: {
      payment_method_id: paymentMethodId,
    },
  });

  return response?.data;
}

export async function upgradeSubscription() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/upgrade_subscription`,
    method: 'put',
  });

  return response?.data;
}
