import React from 'react';
import { useMutation } from 'react-query';
import {
  confirmCardPayment,
  confirmSetupIntent,
  createCardInput,
  createPaymentButton,
  payWithSavedCard,
} from '@separate/api/stripe';

export function useCreditCardInput(props) {
  const {
    number,
    expiry,
    cvc,
    clientSecret,
    setupMode,
    locale,
    style,
    onChange,
    onSuccess,
    onError,
  } = props;

  const [isCreateCardLoading, setIsCreateCardLoading] = React.useState(true);
  const [stripeConfirmPayment, setStripeConfirmPayment] = React.useState(null);

  React.useEffect(() => {
    if (!clientSecret) return;

    async function setupStripe() {
      const result = await createCardInput({
        number,
        expiry,
        cvc,
        clientSecret,
        setupMode,
        locale,
        style,
        onChange,
      });
      setStripeConfirmPayment(() => result.confirmPayment);
      setIsCreateCardLoading(false);
    }

    setupStripe();
  }, [clientSecret, number, expiry, cvc, setupMode, locale, style, onChange]);

  const fetcher = useMutation(
    stripeConfirmPayment,
    {
      onSuccess,
      onError,
      useErrorBoundary: false,
    }
  );
  const { isLoading: isConfirmLoading, mutate: confirmPayment } = fetcher;

  const isLoading = isCreateCardLoading || isConfirmLoading;

  return { isLoading, confirmPayment };
}

export function usePaymentButton(props) {
  const {
    clientSecret,
    selector,
    productName,
    price,
    locale,
    onAvailable,
    onSuccess,
    onError,
  } = props;

  const onCompleted = React.useCallback((result) => {
    if (result.error) {
      onError(result.error.message);
    } else {
      onSuccess(result);
    }
  }, [onSuccess, onError]);

  React.useEffect(() => {
    if (!clientSecret) return;

    async function setupStripe() {
      await createPaymentButton({
        selector,
        productName,
        price,
        clientSecret,
        locale,
        onPaymentButtonAvailability: onAvailable,
        onPaymentRequestCompleted: onCompleted,
      });
    }

    setupStripe();
  }, [clientSecret, selector, productName, price, locale, onAvailable, onCompleted]);
}

export function useCreditCardSaved(props) {
  const {
    clientSecret,
    locale,
    payment_methods,
    onSuccess,
    onError,
  } = props;

  const [stripeConfirmPayment, setStripeConfirmPayment] = React.useState(null);

  React.useEffect(() => {
    if (!clientSecret) return;

    async function setupStripe() {
      const result = await payWithSavedCard({
        clientSecret,
        payment_methods,
        locale
      });
      setStripeConfirmPayment(() => result.confirmPayment);
    }

    setupStripe();
  }, [clientSecret, locale, payment_methods]);

  const fetcher = useMutation(
    stripeConfirmPayment,
    {
      onSuccess,
      onError,
      useErrorBoundary: false,
    }
  );

  const { isLoading, mutate: confirmPayment } = fetcher;

  return { isLoading, confirmPayment };
}

export function useConfirmSetupIntent({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ clientSecret, paymentMethodId }) => confirmSetupIntent(clientSecret, paymentMethodId),
    {
      onSuccess,
      onError,
    }
  );

  return { isLoading, confirmSetupIntent: mutate };
}

export function useConfirmCardPayment({ onSuccess, onError }) {
  function handleResponse(data) {
    if (data?.paymentIntent) {
      onSuccess(data.paymentIntent);
    } else {
      onError(data?.error);
    }
  }

  const { isLoading, mutate } = useMutation(
    ({ clientSecret, data, options }) => confirmCardPayment(clientSecret, data, options),
    {
      onSuccess: handleResponse,
      onError: handleResponse,
    }
  );

  return { isLoading, confirmCardPayment: mutate };
}
