import axios from 'axios';
import * as auth from '@/utilities/auth';
import requestSigner from '@/utilities/requestSigner';

async function refreshIAMTokensIfNeeded() {
  let iamCreds = auth.getTemporaryCredentials();
  const expiration = new Date(iamCreds.expiration);

  // TODO: We should probably use dayjs here instead.  This is working for now but I'm afraid it
  // may be unreliable.  As far as I can tell there was a bug in proton causing the token to
  // _always_ be refreshed on every query.  Date.now() was returning the epoch time in millis, but
  // the expiration comes through in seconds.  The >= was always evaluating to true because the
  // now-based time was like 3 orders of magnitude larger than the token expiration.  Oops.
  // I don't like assuming the units like this.  If dayjs automatically accounts for units, that
  // would be better.
  const nowInSeconds = Math.floor(Date.now() / 1000);

  // Refresh the IAM temp token if it is going to expire in 5 mins or less
  if (5 * 60 + nowInSeconds >= expiration.getTime()) {
    await requestSigner.refreshToken();
  }
}

export default async function baseAuthAdminQuery(props) {
  const {
    path,
    method,
    data = null,
    params = {},
    locale = 'es',
  } = props;

  if (!auth.loggedIn()) { return null; }

  await refreshIAMTokensIfNeeded();
  let options = await requestSigner.sign(path, method, data, locale);
  if (options == null) {
    auth.reset();
    return null;
  }

  try {
    return await axios(options);
  } catch(error) {
    if (error.response?.status === 401) {
      auth.reset();
      return null;
    }

    throw error;
  }
}
