const creditcard = {
  header: '¡Disponible muy pronto!',
  hero: {
    credit: 'Credit',
    subtitle: 'La tarjeta de crédito para la comunidad',
    description: 'Acceda a crédito sin cargos ni cuotas anuales. La protección y poder adquisitivo que merece.',
    cta: 'Comenzar',
  },
  card_intro: {
    title: 'Recorramos este camino juntos:',
    description: [
      {
        icon: 'creditCard',
        text: 'Puede aplicar con SSN, ITIN o Matrícula Consular Mexicana',
      },
      {
        icon: 'customerService',
        text: 'Hablamos su idioma: servicio y app 100% en español',
      },
      {
        icon: 'fee',
        text: 'Sin cuota anual y pagos flexibles',
      },
      {
        icon: 'mastercard',
        text: 'Mastercard es aceptada alrededor del mundo, en línea y en persona',
      }
    ],
  },
  products: {
    title: 'Elija la opción que mejor le funciona:',
    credit_limit: 'Límite de crédito hasta',
    deposit: 'Depósito de seguridad ',
    purchase: 'Tasa de Interés Anual (APR)',
    creditcard_type: [
      {
        credit_history: 'Si ya cuenta con historial crediticio',
        type: 'PODERcard Credit',
        limit: '$15,000',
        deposit: 'No',
        purchase_apr: '0.00% - 3.99% por 6 meses, luego 16.99% - 29.99%',
        cta: 'Aplicar',
        href: 'https://link.podercard.com/ToT2YHmIm5&utm_campaign=cc-landing-cta&utm_source=sepv3&utm_content=unsecured-cta',
        terms: 'Ver Términos y Condiciones',
        terms_href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt43641f0bfc3f3bb1/63658dfb7ec6792d067bedba/10.28.2022_SP_Unsecured_PODERcard_Application_Disclosures_.docx.pdf',
      },
      {
        credit_history: 'Si no tiene buen o ningún historial crediticio',
        type: 'PODERcard Credit Secured',
        limit: '$3,500',
        deposit: 'Mínimo $300<sup>1</sup>',
        purchase_apr: '18.99%',
        cta: 'Aplicar',
        href: 'https://link.podercard.com/ToT2YHmIm5&utm_campaign=cc-landing-cta&utm_source=sepv3&utm_content=secured-cta',
        terms: 'Ver Términos y Condiciones',
        terms_href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltb07c2435132ed327/63658e4bb4bb981068d3edc4/10.24.2022_SP_Secured_PODERcard_Application_Disclosure_.docx.pdf',
      }
    ],
  },
  benefits: [
    {
      title: 'No se requiere Seguro Social',
      subtitle: '¿No tiene SSN? ¡No hay problema! Puede usar su  Número de Identificación Personal del Contribuyente (ITIN) o su Matrícula Consular Mexicana para aplicar. Aún así podría construir historial de crédito y obtener puntaje crediticio en EE.UU.<sup>2</sup>',
      icon: 'creditCard',
    },
    {
      title: 'Hablamos su idioma',
      subtitle: 'Nuestro servicio es 100% bilingüe y puede acceder a todos los servicios de la aplicación móvil PODERcard y a los especialistas de Atención al Cliente en español.',
      icon: 'customerService',
    },
    {
      title: 'Sin cuota anual y pagos flexibles',
      subtitle: 'No hay que preocuparse por cargos extras de mantenimiento. Solo tiene que ocuparse de su pago mensual a tiempo: pague el mínimo, su saldo por completo o lo que más le convenga.',
      icon: 'fee',
    },
    {
      title: 'Crédito para evolucionar juntos',
      subtitle: 'No importa donde esté en su camino crediticio, esta tarjeta se ajusta a sus necesidades y evoluciona con usted. Si comienza con <span>PODERcard Credit Secured</span> podría calificar en 12 meses o menos a <span>PODERcard Credit</span> (sin depósito).<sup>3</sup>',
      icon: 'evolve',
    },
    {
      title: 'Gane recompensas para ayudar a pagar su saldo',
      subtitle: 'Participe en el programa de Recompensas de SABEResPODER y gane dinero extra para que nunca deje pasar un pago mínimo, una de las formas más efectivas de incrementar su puntaje de crédito.<sup>4</sup>',
      icon: 'trophy',
    },
    {
      title: 'Con PODERcard app tiene el control en sus manos',
      subtitle: 'Maneje todo a través de su celular — desde aplicar a la tarjeta de crédito hasta revisar sus transacciones. Administre su cuenta 24/7 en la app: consulte sus estados de cuenta, haga sus pagos y más.',
      icon: 'cardAndPhone',
    },
    {
      title: 'Recursos para ayudarle a construir crédito',
      subtitle: 'Acceda a guías útiles para aprender cómo usar su tarjeta de crédito de manera responsable y cómo mejorar su crédito.<sup>5</sup>',
      icon: 'shield',
    },
  ],
  banner_one: {
    title: 'Tome el control de su historial crediticio',
    cta: 'Aplicar a PODERcard Credit',
  },
  banner_two: {
    title: '¡Cumpla sus metas financieras con PODERcard Credit!',
    cta: 'Aplicar ',
  },
  mastercard: {
    header_title: 'Beneficios Mastercard',
    header_subtitle: 'Use su <span>PODERcard Credit</span> donde sea que se acepte Mastercard y aproveche estas ventajas:',
    description: [
      {
        title: 'Servicio Global de Mastercard',
        subtitle: 'Asistencia 24/7 en todo el mundo para reportar y reponer su tarjeta en caso de pérdida o robo.',
      },
      {
        title: 'Mastercard ID Theft Protection<sup>TM</sup>',
        subtitle: 'Acceda al programa anti-robo de identidad y proteja su cuenta de compras no autorizadas.',
      },
      {
        title: 'Servicios de asistencia en viajes',
        subtitle: 'Acceda a información sobre requisitos de viaje, de servicios médicos o legales, y servicio en carretera.',
      },
      {
        title: 'Mastercard Airport Concierge<sup>TM</sup>',
        subtitle: 'Ahorre el 15% en servicios de recepción en aeropuertos.',
      },
    ],
  },
  faq: {
    title: 'Preguntas frecuentes',
    items: [
      {
        title: '¿Cómo solicito una PODERcard Credit?',
        htmlContent: 'Para solicitar la tarjeta de crédito PODERcard Credit debe descargar la aplicación de PODERcard en su celular. Es muy rápido, ¡toma menos de 10 minutos!',
      },
      {
        title: '¿Qué necesito para aplicar?',
        htmlContent: 'Llenar la solicitud en la app de PODERcard con los siguientes datos personales: <ul><li>Uno de estos documentos: Número de Seguro Social, Matrícula Consular Mexicana o Número de Identificación Personal del Contribuyente (ITIN).</li><li>Nombre completo, fecha de nacimiento, información de empleo y otros detalles básicos.</li><li>Domicilio en Estados Unidos (PODERcard Credit no está disponible para residentes de New York, Maryland y Wisconsin).</li></ul>'
      },
      {
        title: '¿Cómo puedo establecer crédito con PODERcard Credit Secured?',
        htmlContent: 'La tarjeta PODERcard Credit Secured está diseñada específicamente para personas que no tienen historial crediticio y quieren establecer su crédito paso a paso.<sup>2</sup>  Esta opción le puede ayudar en ese propósito siempre y cuando usted haga al menos el pago mínimo a tiempo cada mes, pues ofrece: <ul><li>Reporte de su historial de pago a las principales agencias de crédito (TransUnion y Equifax).</li><li>Acceso a recursos para ayudarle a hacer sus pagos a tiempo, incluyendo la opción de pago automático y guías prácticas sobre el uso responsable de tarjetas de crédito.<sup>5</5></li><li>Anti-robo de Identidad y Responsabilidad Cero de Mastercard, que da protección si su tarjeta se utiliza de manera fraudulenta.</li></ul>'
      },
      {
        title: '¿Cuál es la diferencia entre una tarjeta de crédito asegurada y una normal?',
        htmlContent: '<p>La principal diferencia es que la <span>PODERcard Credit Secured</span> requiere un depósito de garantía. El monto que deposite (mínimo $300)<sup>1</sup> determina su límite de crédito, que puede ser de hasta $3,500, dependiendo de su solvencia económica y otros factores. Es la mejor opción si no tiene buen o ningún historial crediticio y quiere empezar a construir su crédito.</p> <p>Mientras tanto, la <span>PODERcard Credit</span> no requiere un depósito de fondos y le permite acceder a hasta $15,000 de crédito, dependiendo de su solvencia económica y otros factores. Es la tarjeta de crédito ideal si ya cuenta con historial crediticio.</p>',
      },
    ]
  },
  about: {
    title: '¿Quiénes somos?',
    subtitle: 'En SABEResPODER nos comprometemos a elevar y empoderar a la comunidad hispana en Estados Unidos, brindando recursos que la impulsen a triunfar. Es nuestra misión proveer productos confiables para mejorar su calidad de vida. Desde la inclusión financiera hasta el cuidado de la salud y más, nos dedicamos a ayudarle a usted y a su familia a vivir mejor.',
  },
  footer: {
    footnotes: [
      {
        text: '<sup>1</sup>El depósito de seguridad no se usa para hacer los pagos de la tarjeta de crédito PODERcard Credit Secured, funciona como una garantía.',
      },
      {
        text: '<sup>2</sup>El uso responsable de su tarjeta de crédito, incluyendo hacer los pagos a tiempo cada mes, puede ayudarle a construir su puntaje de crédito. Los resultados varían.',
      },
      {
        text: '<sup>3</sup>Podría ser elegible para obtener una PODERcard Credit (sin depósito de seguridad) al cabo de 12 meses, basado en su historial de pago, su solvencia económica y otros factores.'
      },
      {
        text: '<sup>4</sup>El programa de Recompensas de SABEResPODER no está asociado con MRV Banks.',
      },
      {
        text: '<sup>5</sup>Estos recursos y guías son ofrecidos por SABEResPODER y no están relacionados con MRV Banks.',
      },
    ],
    issued_by: 'La tarjeta PODERcard Credit es emitida por MRV Banks conforme a una licencia de Mastercard International<sup>TM</sup>.',
    disclosures_and_agreements: [
      {
        title: 'Divulgaciones sobre la solicitud de PODERcard Credit PODERcard Credit',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt43641f0bfc3f3bb1/63658dfb7ec6792d067bedba/10.28.2022_SP_Unsecured_PODERcard_Application_Disclosures_.docx.pdf',
      },
      {
        title: 'Divulgaciones sobre la solicitud de PODERcard Credit PODERcard Credit Secured',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltb07c2435132ed327/63658e4bb4bb981068d3edc4/10.24.2022_SP_Secured_PODERcard_Application_Disclosure_.docx.pdf',
      },
      {
        title: 'Acuerdo de divulgación y consentimiento de firma electrónica',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt08fde5f3dbbb52ab/6374241a71c75510a0c9e254/10.20.2022_SP_E-SIGN_Disclosure_&_Consent_Agreement_-SP_E-SIGN_Acuerdo_de_Revelacion_y_Consentimiento.docx.pdf',
      },
      {
        title: 'Acuerdo de Términos de Uso',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt07904a51f5cfd3b8/637422ea2f1aba10d25a69d1/10.20.2022_SP_Terms_of_Use_Agreement_ES.docx.pdf',
      },
    ],
    mrv_banks_pivacy_policy: {
      title: 'MRV Banks Política de Privacidad',
      href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltdca0444ad083f30d/MRV_Privacy_SP_Policy_WT_Program_10.20.2022_ES_R.docx.pdf',
    },
    welcome_tech_privacy_policy: {
      title: 'Welcome Tech Política de Privacidad ',
      href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt7cf525d9f7868b09/3.3.3022_WT_Privacy_Policy_S.pdf',
    },
    contact_us: 'Contáctenos',
  }
};

export default creditcard;
