const validations = {
  is_required: "{{ field }} can't be blank",
  invalid: "{{ field }} is not valid",
  at_least_2_characters: "{{ field }} must be at least 2 characters",
  at_most_35_characters: "{{ field }} must be at most 35 characters",
  invalid_characters: "{{ field }} contains invalid characters",
  exactly_5_characters: "{{ field }} must be exactly 5 characters",
  numeric: "{{ field }} must only contain numbers",
  invalid_phone: "Please enter a valid phone number",
  incorrect: "{{field}} incorrect",
  invalid_confirmation_code: "This field is incorrect",
  invalid_phone_number: "Please enter a valid phone number",
  phone_already_taken: "The phone number is already in the system",
  dependent: {
    feature_flag_off: "Feature flag for 'allow_dependents' is off",
    max_dependent: "You can only add a maximum of 10 dependents",
    max_spouse: "You can only add one spouse",
    must_be_18_or_over: "Dependent must be 18 years old or over",
    must_be_under_18: "Dependent must be under 18 years old",
  },
};

export default validations;
