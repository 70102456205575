const benefits = {
  cancellation_policy: {
    title: 'Póliza de cancelación',
    this_is_not_insurance: 'ESTO NO ES UN SEGURO MÉDICO',
    faq: {
      items: [
        {
          title: 'Avisos legales:',
          htmlContent: '<ol><li>Este plan no es una póliza de salud o seguro médico.</li><li>Este plan ofrece descuentos de servicios médicos con ciertos proveedores de salud que forman parte de la red.</li><li>Este plan no realiza pagos directos a los proveedores de servicios médicos.</li><li>El miembro del plan está obligado a pagar por todos los servicios médicos descontados en el momento en que se reciben. Los descuentos pueden variar con base en el servicio, proveedor y área geográfica.</li></ol>'
        },
        {
          title: 'Póliza de Cancelación:',
          htmlContent: 'Para cancelar su membresía, por favor llame al (726) 202-1213 o envíe por correo una solicitud de cancelación dirigida a Member Services P.O. Box 803475, Dallas, TX 75367-1309. Esta carta debe incluir su nombre, número de identificación de membresía y ser enviada por lo menos tres (3) días antes de su próxima fecha de pago. La membresía puede ser cancelada por falta de pago.'
        },
        {
          title: 'Garantía de reembolso en 30 días:',
          htmlContent: 'Si cancela por cualquier razón durante los primeros 30 días después de la fecha en que se hace efectivo su plan, recibirá el reembolso completo del pago hecho por la membresía.'
        },
        {
          title: 'Resolución de quejas:',
          htmlContent: 'Si tiene problemas o reclamos relacionados con el uso de su membresía, por favor llame al (726) 202-1213 para obtener ayuda. Su queja será investigada y en un plazo de 3 días se le dará una resolución.'
        }
      ]
    }
  },
  podersalud: {
    how_podersalud_helps_you: '¿Para qué sirve PODERsalud?',
    get_my_membership: 'Obtener mi membresía',
    telehealth : {
      title: 'Telemedicina cuando quiera',
      description: 'Sabemos que lleva una vida ocupada. Consulte al médico 24/7 por teléfono o videollamada.',
      items: [
        {
          text: 'Atención en español'
        },
        {
          text: 'Disponible 24/7'
        },
        {
          text: 'Doctores certificados en EE.UU.'
        }
      ]
    },
    prescription: {
      title: 'Medicamentos descontados',
      description: 'Muestre su tarjeta PODERsalud y ahorre hasta 80% en medicamentos recetados.',
      items: [
        {
          text: 'Encuentre el mejor precio'
        },
        {
          text: 'Disponible en 60,000 farmacias del país'
        }
      ]
    },
    dental: {
      title: 'Descuentos con dentistas',
      description: 'Sin deducibles. Sin copagos. Reciba descuentos de 15% - 50% por consulta en sus citas con dentistas.',
      items: [
        {
          text: 'Participan más de 260,000 dentistas'
        },
        {
          text: 'Limpiezas, frenos, rayos-x, rellenos y más'
        }
      ]
    },
    vision: {
      title: 'Otros descuentos médicos',
      description: 'Mejor vista y diagnóstico. Ahorre de 10% - 60% en el cuidado de la visión y hasta 75% en resonancias.',
      items: [
        {
          text: 'Visite un proveedor de la red y presente su tarjeta'
        },
        {
          text: 'Incluye médicos de barrio'
        }
      ]
    },
    earnings: {
      title: 'Recompensas exclusivas',
      description: '¡Gane más! Acceda a las encuestas exclusivas del Grupo de Opinión.',
      items: [
        {
          text: 'Acceda encuestas exclusivas en la app'
        },
        {
          text: 'Remuneración especial'
        }
      ]
    }
  },
  savings: {
    in_savings: '${{ price }} en ahorros',
    with_podersalud: 'Con <span>PODER</span>salud',
    dental: {
      title: 'Dentistas',
      description: '<span>Costo promedio</span> de limpieza dental para un adulto o un niño',
      per_visit: '<span>${{ price }}</span> / por visita'
    },
    telemedicine: {
      title: 'Telemedicina',
      description: '<span>Costo promedio</span> de una cita médica en presencial',
      per_visit: '<span>${{ price }}</span> / por visita'
    },
    pharmacy_lisinopril: {
      title: 'Farmacia',
      description: '<span>Costo promedio</span> de Lisinopril (Presión Arterial)',
      per_visit: '<span>${{ price }}</span> / 20mg (30 tabletas)'
    },
    pharmacy_amlodipine : {
      title: 'Farmacia',
      description: '<span>Costo promedio</span> de Amlodipino (Presión Arterial)',
      per_visit: '<span>${{ price }}</span> / 10 mg (30 tabletas)'
    },
    vision : {
      title: 'Visión',
      description: '<span>Costo promedio</span> de un examen de anteojos',
      per_visit: '<span>${{ price }}</span> / por visita'
    }
  },
  rx_drug_prices: {
    header: {
      title: 'Rx Valet (SP)',
      text: 'Search and compare prices for: (SP)',
    },
    drug_name: 'DRUG NAME (SP)',
    enter_first_three_letters: 'Enter first 3 letters of drug name (SP)',
    zipcode: 'ZIP CODE (SP)',
    enter_zip_code: 'Enter zip code (SP)',
    update_location: 'Cambiar ubicación',
    find_lowest_price: 'Find the lowest price (SP)',
    pharmacies: 'Pharmacies (SP)',
    no_results: 'No results available (SP)',
    search_results: '{{ result }} providers found near (SP)',
    distance: '{{ distance }} Miles (SP)',
    view_savings: 'View savings card (SP)',
    drug_card: {
      discount: 'Discount card (SP)',
      not_insurance: 'THIS IS NOT INSURANCE. (SP)',
      estimated_discount: '*Estimated discount price (SP)',
      member_id: 'Member ID: (SP)',
      group_id: 'Group ID: (SP)',
      bin: 'BIN: (SP)',
      pcn: 'PCN: (SP)',
      customer_service: 'Customer service: (SP)',
      pharmacy_help: 'Pharmacy help desk: (SP)',
    },
  },
};

export default benefits;
