import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import styles from './successModal.module.scss';

export default function SuccessModal({ buttonText, container, description, icon, onHide, show, size, title }) {
  function handleHide() {
    onHide();
  }

  return(
    <Modal
      backdrop
      backdropClassName={styles.backdrop}
      bodyClassName={styles.body}
      centered
      className={styles.successModal}
      close={false}
      container={container}
      onHide={handleHide}
      show={show}
      size={size}
    >
      <div className={styles.body}>
        {icon}
        <div className={styles.header}>
          {title}
        </div>
        <div className={styles.bodyText}>
          {description}
        </div>
        <Button className={styles.button} onClick={handleHide}>
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  buttonText: PropTypes.string,
  container: PropTypes.any,
  description: PropTypes.string,
  icon: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  title: PropTypes.string,
};

SuccessModal.defaultProps = {
  size: 'sm',
};
