import axios from 'axios';
import baseAuthAdminQuery from './baseAuthAdminQuery';
import * as env from '@/env';

export async function assumeRoleFor(idToken) {
  const params = {
    Action: "AssumeRoleWithWebIdentity",
    Version: "2011-06-15",
    RoleArn: env.AWS_STS_ROLE_ARN,
    RoleSessionName: env.AWS_STS_SESSION_NAME,
    WebIdentityToken: idToken
  };
  const { data } = await axios.get("https://sts.amazonaws.com/", { params });
  return data.AssumeRoleWithWebIdentityResponse
    .AssumeRoleWithWebIdentityResult;
}

export async function revokeAccessToken() {
  return await baseAuthAdminQuery({
    path: 'access_token/revoke',
    method: 'post',
  });
}
