import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@/hooks/translation';
import styles from "./basicInfo.module.scss";

export default function Gender(props) {
  const { gender } = props;
  const { t } = useTranslation();

  // TODO: We need to localize the gender

  return (
    <div className='d-flex flex align-items-center mb-4'>
      <span className={styles.infoRow}>{t("user.gender")}</span>
      <span className={styles.userInfo}>{gender}</span>
    </div>
  );
}

Gender.propTypes = {
  gender: PropTypes.string,
};
