import * as React from 'react';
import PropTypes from 'prop-types';
import { useLogin } from '@separate/hooks/login';
import InputForm from './InputForm';
import useLoginInputFormState from './useLoginInputFormState';

export default function LoginStep(props) {
  const { onPhoneNumberSuccess, onPhoneNumberDoesNotExist, currentPhoneNumber, forceLogin } = props;

  const inputFormState = useLoginInputFormState();
  const { phone_number: phoneNumber } = inputFormState.value;

  const { isLoading, login } = useLogin({ onSuccess, onError });
  const { setValue } = inputFormState;

  React.useEffect(() => {
    if (currentPhoneNumber) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [currentPhoneNumber, setValue]);

  React.useEffect(() => {
    if(forceLogin && phoneNumber && String(phoneNumber).length === 10) {
      handleSubmit();
    }
  }, [forceLogin, phoneNumber, handleSubmit]);

  function onSuccess(response) {
    // TODO: We get back the pid and phone number.  Do we need to do anything with the pid?
    onPhoneNumberSuccess(phoneNumber);
  }

  function onError({ response }) {
    onPhoneNumberDoesNotExist(phoneNumber);
  }

  const handleSubmit = React.useCallback(() => {
    login({ phoneNumber });
  }, [login, phoneNumber]);

  return (
    <>
      <InputForm
        onSubmit={handleSubmit}
        loading={isLoading}
        inputFormState={inputFormState}
      />
    </>
  );
}

LoginStep.propTypes = {
  onPhoneNumberSuccess: PropTypes.func,
  onPhoneNumberDoesNotExist: PropTypes.func.isRequired,
  currentPhoneNumber: PropTypes.string,
  forceLogin: PropTypes.bool
};

LoginStep.defaultProps = {
  currentPhoneNumber: "",
};
