import baseAuthAdminQuery from './baseAuthAdminQuery';

export async function getUserAddress() {
  const response = await baseAuthAdminQuery({
    path: 'users/addresses',
    method: 'GET',
  });

  return response?.data?.address;
}

export async function updateUserAddress(pid, address) {
  const user = { pid };
  const response = await baseAuthAdminQuery({
    path: 'users/addresses',
    method: 'POST',
    data: { address }
  });

  return response?.data?.address;
}

export async function validateUserAddress(address) {
  const response = await baseAuthAdminQuery({
    path: 'users/addresses/validate',
    method: 'post',
    data: { address }
  });

  return response?.data;
}
