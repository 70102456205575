import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import TextInput from '@separate/components/TextInput';
import { useTranslation } from '@separate/hooks/translation';
import { inputFormStateType } from '@separate/types';
import styles from '../steps.module.scss';
import Terms from './Terms';

export default function InputForm(props) {
  const { inputFormState, onSubmit, loading } = props;

  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  return (
    <div>
      <TextInput
        type="text"
        name="first_name"
        inputFormState={inputFormState}
      />
      <TextInput
        type="text"
        name="last_name"
        inputFormState={inputFormState}
      />
      <TextInput
        type="tel"
        mask="(999) 999-9999"
        unmasked
        name="phone_number"
        inputFormState={inputFormState}
      />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <Terms
        checked={checked}
        onChange={setChecked}
      />
      <Button
        loading={loading}
        onClick={onSubmit}
        disabled={!inputFormState.isValid || !checked}
        className={styles.submitButton}
      >
        {t('login.register')}
      </Button>
    </div>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

InputForm.defaultProps = {
  inputFormState: {},
};
