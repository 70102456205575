import React from 'react';
import Select from '@/components/Select';
import { useTranslation } from '@/hooks/translation';

export default function GenderSelect(props) {
  const { t } = useTranslation();

  const options = [
    { id: "Male", text: t("user.gender_options.male") },
    { id: "Female", text: t("user.gender_options.female") },
    { id: "Unspecified", text: t("user.gender_options.unspecified") },
  ];

  return (
    <Select
      options={options}
      allowBlank
      {...props}
    />
  );
}
