const user = {
  first_name: "First name",
  last_name: "Last name",
  email: "Email address",
  gender: "Gender",
  phone_number: "Mobile number",
  birthday_with_format: "Date of birth mm/dd/yyyy",
  gender_options: {
    male: "Male",
    female: "Female",
    unspecified: "Unspecified",
  },
  culture_id: "Select your family’s country of origin",
  other_culture_name: "Please specify your family’s country of origin",
  mexico_state_id: "Which Mexico state does your family come from?",
  interests: "Interests",
  language: "Language",
  language_options: {
    spanish: "Spanish",
    english: "English",
  },
  profile: {
    account_info: "Account information",
    add_card: "Add card",
    add_new_card: "Add new card",
    annual_interval: "year",
    annual_plan: "PODERsalud annual",
    cancel_edit_cta: "Cancel",
    card_info: "Card information",
    change_payment: "Change payment method",
    default: "Default",
    delete: "Delete this payment method",
    delete_payment: {
      body: "The information associated with this payment method will be permanently removed. If you wish, you can add it again later.",
      cancel: "Go back",
      delete: "Delete",
      header: "You are about to delete a payment method"
    },
    expires_on: "Expires on",
    make_default: "Make this my default payment method",
    monthly_interval: "month",
    monthly_plan: "PODERsalud monthly",
    new_card_added: "New card added!",
    new_card_delay: "Please allow 5 minutes for your new card to be displayed in your profile.",
    new_default_card: "New default card!",
    new_default_delay: "Please allow 5 minutes for your new default card to be displayed in your profile.",
    next_billing: "Next billing date",
    payment_cta_title: "Payment Info",
    payment_method: "Payment Method",
    payment_methods: "Payment methods",
    plan: "Plan",
    price: "Price",
    retry_card: "Retry with this card",
    revise_payment: "Revise your payment method",
    revise_subtitle: "You can try again with your selected method of payment or change it.",
    submit_cta: "Submit Changes"
  },
  refer_a_friend: {
    earn: 'Earn ${{ discount }}',
    refer_your_friends: 'Refer your friends to \nPODERsalud and <span>earn ${{ discount }}!*</span>',
    refer_a_friend: 'Refer a friend',
    invite_your_friends: 'Invite your friends and family and <span>earn ${{ discount }}*</span> for each one that signs up to PODERsalud',
    start_earning: 'Start earning',
    sms_message: "Hi! Have you heard of PODERsalud? It's a health care membership designed to save money. Friends and family get a ${{ discount }} credit. Try it now {{ referralShortLink }}",
    how_does_it_work: 'How does it work?',
    your_reward: 'Your ${{ discount }} reward will be applied as a credit on your subscription. Invite as many people as you want to earn more!',
    terms_and_conditions: '*PODERsalud Referral Program Terms and Conditions',
  },
  dependents: {
    title: 'Dependents',
    dependent: 'Dependent {{ value }}',
    age: {
      title: 'Is the dependent you wish to add under 18 years of age?',
    },
    type: {
      title: 'Is the dependent you wish to add your spouse or child?',
    },
    no_dependents: 'There are no dependents to display',
    add_dependent: 'Add dependent',
    dependent_information: 'Dependent information',
    birthday: 'Birthday: {{ birthday }}',
    submit: 'Submit',
    success_modal: {
      title: 'New dependent added!',
      text: 'To add more dependents or make changes to current dependents, please visit your profile.',
      cta: 'Close',
    },
  },
};

export default user;
