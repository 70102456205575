import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import TextInput from '@separate/components/TextInput';
import { useTranslation } from '@separate/hooks/translation';
import { inputFormStateType } from '@separate/types';
import styles from '../steps.module.scss';

export default function InputForm(props) {
  const {
    inputFormState,
    onSubmit,
    disabled,
    loading
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.mobilePhoneInputContent}>
      <TextInput
        type="tel"
        mask="(999) 999-9999"
        unmasked
        name="phone_number"
        inputFormState={inputFormState}
      />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <Button loading={loading} onClick={onSubmit} disabled={!inputFormState.isValid} className={styles.submitButton}>
        {t('login.login')}
      </Button>
    </div>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

InputForm.defaultProps = {
  inputFormState: {},
};
