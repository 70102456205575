import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import AddDependentsOffcanvas from '@separate/components/Dependents/AddDependentsOffcanvas';
import { useTranslation } from '@separate/hooks/translation';
import { Add } from '@separate/icons';
import styles from './index.module.scss';

export default function AddDependentsCard({ buttonClassName, container, refetchDependents }) {
  const [showAddDependents, setShowAddDependents] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setShowAddDependents(true);
  };

  return (
    <div className={cx(styles.addDependents, 'd-flex flex-column align-items-center justify-content-between mt-3')}>
      <p>{t('user.dependents.no_dependents')}</p>
      <Button
        className={cx(styles.cta, 'd-flex align-items-center justify-content-center', buttonClassName)}
        onClick={handleClick}
      >
        <Add className='mx-2' />
        {t('user.dependents.add_dependent')}
      </Button>
      <AddDependentsOffcanvas
        show={showAddDependents}
        onHide={() => setShowAddDependents(false)}
        container={container}
        refetchDependents={refetchDependents}
      />
    </div>
  );
}

AddDependentsCard.propTypes = {
  buttonClassName: PropTypes.string,
  container: PropTypes.any,
  refetchDependents: PropTypes.func,
};
