import React from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Spinner }from 'react-bootstrap';
import { csLoader } from '@separate/utilities/image';
import styles from './pageLoader.module.scss';


export default function PageLoader() {
  return (
    <div className={cx(styles.pageLoader, "d-flex align-items-center justify-content-center")}>
      <Spinner animation="border" size="lg" className={cx(styles.spinner)} />
      <Image
        src={require('@separate/res/sepIcon.png')}
        alt='loader'
        loader={csLoader}
        height='80'
        width='80'
      />
    </div>
  );
}
