import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { useTranslation } from '@/hooks/translation';
import useAddressVerification from '@/hooks/useAddressVerification';
import {
  useFetchUserProfile,
  useUpdateUserProfile,
  useResetUserProfile,
  useCurrentUser,
  useInvalidateCurrentUser,
} from '@/hooks/user';
import styles from '../editViews.module.scss';
import InputForm from './InputForm';
import useEditBasicInfoInputFormState from './useEditBasicInfoInputFormState';

export default function EditBasicInfo(props) {
  const { onUpdateBasicInfoSuccess, onCancel } = props;
  const { t } = useTranslation();
  const { user: sessionUser } = useCurrentUser();
  const { isFetchingProfile, fetchUserProfile } = useFetchUserProfile({ onSuccess: onFetchSuccess });
  const { isUpdatingProfile, updateUserProfile } = useUpdateUserProfile({onSuccess: onUpdateSuccess, onError: onUpdateFail});
  const resetUserProfile = useResetUserProfile();
  const invalidateCurrentUser = useInvalidateCurrentUser();

  const hasFullInfo = sessionUser?.is_panelist || sessionUser?.is_premium_member;

  const inputFormState = useEditBasicInfoInputFormState(hasFullInfo);

  React.useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  function onFetchSuccess({ user }) {
    inputFormState.setValue(user);
  }

  function onUpdateSuccess() {
    if(onUpdateBasicInfoSuccess) {
      invalidateCurrentUser();
      onUpdateBasicInfoSuccess();
    }

    resetUserProfile();
  }

  function onUpdateFail({ response }) {
    // TODO: Is this actually possible?  The server does have a "bad_request" branch, but I don't
    // think it's possible to hit it here.
    console.log("update error status", response.status);
  }

  function performUpdate() {
    const result = { ...inputFormState.value };
    if (hasFullInfo) {
      result['usps_verified_address'] = true;
    }
    updateUserProfile({ user: result });
  }

  function onAddressVerified() {
    performUpdate();
  }

  function onReplaceAddress(correctedAddress) {
    const newAnswer = { ...inputFormState.value, ...correctedAddress, usps_verified_address: true };
    inputFormState.setValue(newAnswer);
    updateUserProfile({ user: newAnswer });
  }

  const {
    isLoading: isVerificationLoading,
    verifyAddress,
    addressVerificationState,
  } = useAddressVerification({ onAddressVerified, onReplaceAddress });

  function handleSubmit() {
    if (hasFullInfo) {
      verifyAddress(inputFormState.value);
    } else {
      performUpdate();
    }
  }

  return (
    <div>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}> {t("user.profile.edit_cta_title")} </h4>
        <Button onClick={onCancel} className={styles.editCta}> {t("user.profile.cancel_edit_cta")} </Button>
      </div>

      <InputForm
        inputFormState={inputFormState}
        addressVerificationState={addressVerificationState}
        loading={isFetchingProfile || isUpdatingProfile || isVerificationLoading}
        hasFullInfo={hasFullInfo}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

EditBasicInfo.propTypes = {
  onUpdateBasicInfoSuccess: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};
