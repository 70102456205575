import React from 'react';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { PAYMENT_METHOD_ADDED, PAYMENT_METHOD_ADD_FAILURE } from '@separate/constants/analytics';
import { useUpdatePaymentMethod, useUpdateSetupIntent } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { ChevronDownRounded, PODERcardGradient } from '@separate/icons';
import { logEvent } from '@separate/utilities/analytics';
import styles from './addNewCardOffcanvas.module.scss';
import CreditCardInputForm from './CreditCardInputForm';

export default function AddNewCardOffcanvas({
  clientSecret,
  container,
  onHide,
  onSuccess,
  setupIntentId,
  show,
  updatePaymentMethodHook,
  updateSetupIntentHook,
  isUpgradeSubscriptionPlan,
  onCancel,
  pid,
}) {
  const { t } = useTranslation();

  const [isDefault, setIsDefault] = React.useState(true);

  function handleHide() {
    onHide();
  }

  const absorbUpdateSuccess = React.useCallback(() => {
    // no-op, the update is fire and forget and only serves to expidite processing on the backend
    // before the webhook is processed.
  }, []);
  const absorbUpdateError = React.useCallback(() => {
    // similarly a no-op.
  }, []);
  const { updatePaymentMethod } = updatePaymentMethodHook({
    onSuccess: absorbUpdateSuccess,
    onError: absorbUpdateError,
  });

  function handleSuccess(result) {
    const setupIntentId = result?.setupIntent?.id;
    if(setupIntentId) {
      updatePaymentMethod({ setupIntentId, pid });
    }

    logEvent(PAYMENT_METHOD_ADDED, { as_default: isDefault });
    handleHide();
    onSuccess(isDefault);
  }

  function handleFailure() {
    logEvent(PAYMENT_METHOD_ADD_FAILURE, { as_default: isDefault });
    handleHide();
  }

  return (clientSecret && setupIntentId ? (
    <Offcanvas
      backdropClassName={styles.backdrop}
      className={styles.container}
      container={container}
      onHide={handleHide}
      placement="bottom"
      show={show}
    >
      <Offcanvas.Header className={styles.header}>
        <Offcanvas.Title className={styles.title}>
          {t('user.profile.add_new_card')}
        </Offcanvas.Title>
        <ChevronDownRounded aria-label="Hide" className={styles.closeButton} onClick={handleHide} />
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.body}>
        <div className={styles.infoBanner}>
          <PODERcardGradient className="me-1" />
          {t('user.profile.card_info')}
        </div>

        <CreditCardInputForm
          clientSecret={clientSecret}
          isDefault={isDefault}
          isUpgradeSubscriptionPlan={isUpgradeSubscriptionPlan}
          onCancel={onCancel}
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          setIsDefault={setIsDefault}
          setupIntentId={setupIntentId}
          updateSetupIntentHook={updateSetupIntentHook}
          pid={pid}
        />
      </Offcanvas.Body>
    </Offcanvas>
  ) : (
    null
  ));
}

AddNewCardOffcanvas.propTypes = {
  clientSecret: PropTypes.string,
  container: PropTypes.any,
  isUpgradeSubscriptionPlan: PropTypes.bool,
  onCancel: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setupIntentId: PropTypes.string,
  show: PropTypes.bool,
  updatePaymentMethodHook: PropTypes.func,
  updateSetupIntentHook: PropTypes.func.isRequired,
  pid: PropTypes.string, // for zendesk-sep-admin
};

AddNewCardOffcanvas.defaultProps = {
  updatePaymentMethodHook: useUpdatePaymentMethod,
  updateSetupIntentHook: useUpdateSetupIntent,
};
