import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@separate/hooks/translation';
import styles from './paymentWidget.module.scss';

export default function PayWithCardButton(props) {
  const { onClick } = props;

  const { t } = useTranslation();

  return (
    <p className="mb-5 mt-2 text-center">
      <span className={styles.text}>{ t("premium.payment.or") }</span>
      <button onClick={onClick} className={styles.link}>
        { t("premium.payment.pay_with_card") }
      </button>
    </p>
  );
}

PayWithCardButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
