const benefits = {
  index: {
    benefits_title: 'My Benefits',
    description: 'Explore medical discounts and access consultations with ease. Better healthcare is only a call or a click away!',
    featured_benefit: 'Featured',
    popular_benefit: 'Popular benefits',
    other_benefit: 'Other benefits',
    need_help: 'Got a question?',
    details: 'Details',
    call: 'Call',
    start_chat_now: 'Start chat',
    call_our_support: 'Call Us',
    start_live_chat: "Our customer support agents are more than happy to assist you!",
    information: 'About this benefit',
    hide_benefits: 'Show less',
    show_benefits: 'Show more',
    book_appointment: 'Ready to start saving?',
    call_service: 'Call Now',
    faq: 'FAQ',
    faqs: '<p>FAQ</p>',
    which_one_do_you_want_to_learn: 'Which one do you want to learn more about?',
    learn_more: 'Learn more',
    benefit_card: 'benefit card',
    view_card: 'View Card',
    your_membership_card: 'Your membership card',
    discount_card: 'Discount card',
    to_receive_your_discount: 'To receive your discount show your card at the reception desk.',
    this_is_not_insurance: 'THIS IS NOT INSURANCE',
    call_customer_care: 'Call Customer Care',
    membership_id: 'Membership ID:',
    membership_id_information: 'Use your membership ID when speaking to customer support',
    membership_id_is_not_available: 'Still loading… may take up to 15 minutes',
    podersalud: 'PODERsalud',
    my_benefits: 'My Benefits',
    header_subtitle: 'SEP+',
  },
  find_providers: {
    view_map: 'View map',
    view_list: 'View list',
    call: 'Call',
    loading: 'Loading...',
    providers: 'Providers',
    add_favorite_providers: 'Add favorite providers',
    tap_the_heart_to_add: 'Tap the heart to add',
    got_it: 'Got it',
    see_your_favorite_providers: 'See your favorite providers',
    favorite_providers: 'Favorite providers',
    provider_name_removed_from_favorites: 'This provider has been removed from your favorites',
    my_favorites: 'My favorites'
  },
  dental: {
    title: 'Dental',
    description: 'Big savings on dental services at thousands of locations nationwide.',
    descriptionDesktop: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide. Use your card over and over again to keep your teeth sparkling clean!',
    save_on: 'Save on dental care',
    information: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide. Use your card over and over again to keep your teeth sparkling clean!',
    htmlContent: '<ul><li>In most instances, save 15% to 50% per visit**</li><li>Save on dental services such as cleanings, X-rays, crowns, root canals and fillings</li><li>Need specialty dental care? Save on orthodontics and periodontics, too!</li><li>Contact the provider to confirm participation in Aetna Dental Access®</li><li>Show your card with the Aetna Dental Access® logo and pay the discounted price at checkout</li><li>As of April 2020 actual costs and savings vary by provider, service and geographical area</li></ul>',
    membershipCard: {
      title: 'Dental benefit card',
      subtitle: 'To receive your discount show your card at the reception desk.'
    },
    find_a_dentist: 'Find a dentist',
    providers_found_for_your_location: 'providers found for your location',
    save_your_favorite_providers: 'Save your favorite dentists',
    faq: {
      items: [
        {
          title: 'What if my dentist is not a participating provider?',
          htmlContent: "Recommend a Provider through the app, online or by calling Member Support. Submit the provider's information, and Aetna will contact the provider about joining the network."
        },
        {
          title: 'Can I receive a discount at any dental office?',
          htmlContent: 'You must go to a participating provider in order to receive your discount. Find participating providers in the app or online, or call Member Support Monday to Friday, 7:00 am to 7:00 pm and Saturday, 8:00 am to 5:00 pm Central Time for questions or help locating a provider.'
        },
        {
          title: 'How many times can I use the Dental benefit?',
          htmlContent: 'There is no limit to the amount of times this benefit can be used.'
        }
      ]
    }
  },
  telemedicine: {
    title: 'Telemedicine',
    description: '24/7 access to a licensed doctor is only a call away.',
    descriptionDesktop: 'Don’t wait until you’re sick! Register your account now. This program is available to you, your spouse or domestic partner, and children up to the age of 26.',
    save_on: '24/7 access to a doctor is a call away',
    information: "Feel better now! 24/7 access to a doctor is only a call or click away—anytime, anywhere with no per visit fee. With Teladoc, you can talk to a doctor by phone, mobile app, or online video to get a diagnosis, treatment options and prescription, if medically necessary. Save time and money by avoiding crowded waiting rooms in the doctor’s office, urgent care clinic or ER. Just use your phone, computer, smartphone or tablet to get a quick diagnosis by a U.S. licensed physician.",
    htmlContent: '<ul><li>On-demand healthcare—wherever, whenever</li><li>Treatment for common medical issues such as colds, flu, poison ivy, respiratory infections, bronchitis, pink eye, sinus problems, allergies, urinary tract infections and ear infections</li><li>10 minute average doctor response time</li><li>Visits for all ages—from children to seniors</li><li>U.S. board-certified doctors with an average 20 years practice experience</li><li>Upon request, Teladoc can share visit information with your doctor</li></ul>',
    faq: {
      items: [
        {
          title: 'Do I speak with real doctors?',
          htmlContent: 'Yes. You will speak with doctors who are U.S. board-certified internists, state-licensed family practitioners, and pediatricians licensed to practice medicine in the U.S. and living in the U.S. When you request a visit, Teladoc will connect you with a doctor licensed in your state.'
        },
        {
          title: 'Can I call Teladoc outside of the United States?',
          htmlContent: 'No. Teladoc visits are unavailable outside of the United States.'
        },
        {
          title: 'Is my electronic health record kept private?',
          htmlContent: 'Teladoc employs robust encryption methods to protect your personal information. You determine who can see the information in your record.'
        },
        {
          title: 'How are prescriptions sent to the pharmacy?',
          htmlContent: 'Teladoc does not dispense prescription drugs. If the doctor prescribes medication, it is submitted electronically or by phone to your pharmacy.'
        },
        {
          title: 'What are some of the common conditions Teladoc treats?',
          htmlContent: 'Common conditions include sinus problems, respiratory infections, allergies, urinary tract infections, cold and flu symptoms and many other non-emergency illnesses.'
        },
        {
          title: 'Can Teladoc handle my emergency situations?',
          htmlContent: 'Teladoc is designed to handle non-emergent medical problems. You should not use it if you are experiencing a medical emergency.'
        },
        {
          title: 'Can I request a particular doctor?',
          htmlContent: 'No. Teladoc is designed to support your relationship with your existing doctor. It is not a means of establishing an exclusive relationship with a Teladoc doctor. Please know that all Teladoc doctors are highly qualified and go through rigorous training and credentialing.'
        },
        {
          title: 'Can I get a prescription?',
          htmlContent: 'Teladoc does not guarantee prescriptions. It is up to the doctor to recommend the best treatment. Teladoc doctors do not issue prescriptions for substances controlled by the DEA, non-therapeutic and/or certain other drugs which may be harmful because of their potential for abuse. These include, but are not limited to, antidepressant drugs such as Cymbalta, Prozac and Zoloft. Based on treatment protocols, doctors may not prescribe an antibiotic for viral illnesses such as most colds, sore throats, coughs, sinus infections and the flu. Doctors may suggest alternative treatment options such as a prescription for symptom relief or over-the-counter medication. Also, non-therapeutic drugs such as Viagra and Cialis are not prescribed by Teladoc.'
        }
      ]
    }
  },
  vision: {
    title: 'Vision',
    description: 'Save on glasses, contacts, laser surgery and exams.',
    descriptionDesktop: 'Your eyes are the windows to your health. Save 10% to 60% on glasses, contacts, laser surgery, exams and more.',
    save_on: 'Save on glasses, contacts, laser surgery and exams',
    benefits: [
      {
        eyewear: {
          title: 'Eyewear and Exams',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save 10% to 60% on prescription eyewear and exams',
          information: 'Your eyes are the windows to your health. Now you and your family can see better savings at over 20,000 vision providers nationwide, including national chains and local retailers.',
          htmlContent: '<ul><li>20% to 60% off prescription eyewear including most frames, lenses and specialty items such as tints, coatings and UV protection</li><li>10% to 30% off exams</li><li>Participating chains include LensCrafters, Pearle Vision, Visionworks, JCPenney, Sears, Target and more</li><li>Schedule an appointment with a participating provider. At your appointment, present your membership card with the Coast To Coast Vision™ logo and pay the discounted total</li><li>Call Monday to Friday, 7:00 am to 7:00 pm and Saturday, 8:00 am to 5:00 pm Central Time for help locating providers</li></ul>',
          membershipCard: {
            title: 'Vision benefit card',
            subtitle: 'To receive your discount show your card at the reception desk.'
          },
          find_a_provider: 'Find a provider',
          providers_found_for_your_location: 'providers found for your location',
          save_your_favorite_providers: 'Save your favorite providers',
          faq: {
            items: [
              {
                title: 'Can I use this benefit if I already have vision insurance?',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.'
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.'
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.'
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.'
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.'
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.'
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network?',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.'
              },
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.'
              }
            ]
          }
        }
      },
      {
        order: {
          title: 'Mail Order Contacts',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save 10% to 40% on mail order contact lenses',
          information: "Save 10% to 40% on contact lenses through America's Eyewear mail order service",
          htmlContent: '<ul><li>To order or get a quote, call Monday to Friday, 7:00 am to 7:00 pm and Saturday, 8:00 am to 5:00 pm Central Time</li><li>Email (americaseyewear@newbenefits.com) or fax (972-991-5218) a copy of your prescription.</li></ul>',
          externalLinkTitle: 'Email prescription',
          faq: {
            items: [
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.'
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network?',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.'
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.'
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.'
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.'
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.'
              },
              {
                title: 'Can I use this benefit if I already have vision insurance?',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.'
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.'
              }
            ]
          }
        }
      },
      {
        laser: {
          title: 'Laser Surgery',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: '40% to 50% off the average cost of laser surgery',
          information: 'Save 40% to 50% on the national average cost of LASIK surgery',
          htmlContent: '<ul><li>Schedule an appointment with a participating LASIK surgery provider</li><li>At your appointment, present your membership card with the Coast To Coast Vision™ logo and pay the discounted total</li><li>Call Monday to Friday, 7:00 am to 7:00 pm, and Saturday, 8:00 am to 5:00 pm Central Time for help locating a provider</li></ul>',
          faq: {
            items: [
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.'
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.'
              },
              {
                title: 'Can I use this benefit if I already have vision insurance? ',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.'
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.'
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.'
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.'
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.'
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network? ',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.'
              }
            ]
          }
        }
      }
    ]
  },
  rx_valet: {
    title: 'RX Valet',
    description: 'Save 10% to 80% on prescription medications.',
    descriptionDesktop: 'Save 10% to 80% on prescription medications. Simply search for your medication and instantly see a discounted price!',
    save_on: 'Save 10% to 80% on medications.',
    information: 'Save 10% to 80% on prescription medications. Simply search for your medication and instantly see a discounted price! Prescriptions can be filled at the pharmacy of your choice. Discounts available at all retail pharmacies. For additional savings, a mail order option is available and includes free shipping. Can’t afford a medication? Contact us and we’ll see if we have options which can help.',
    htmlContent: '',
    membershipCard: {
      title: 'Rx Valet benefit card',
      subtitle: 'Discount only - NOT INSURANCE! Please present this card at the Pharmacy with a valid prescription',
      information: 'Discounts are available exclusively through participating pharmacies. The range of the discounts will vary depending on the type of prescription and the pharmacy chosen.',
      more_information: 'This program does not make payments directly to pharmacies. Members are required to pay for all prescription purchases. Cannot be used in conjunction with insurance. You may contact customer care anytime with questions or concerns, to cancel your registration or to obtain further information. This program is administered by Medical Security Card Company, LLC, Tucson, AZ.'
    },
    externalLinkTitle: 'Check prices'
  },
  mri: {
    title: 'MRI & CT scans',
    description: 'National savings on MRI and CT scans.',
    descriptionDesktop: 'A better image leads to a better diagnosis, better treatment and a better recovery.',
    save_on: 'National savings on MRI and CT scans',
    information: 'A better image leads to a better diagnosis, better treatment and a better recovery. Save 40% to 75% on usual charges for MRI and CT Scans at thousands of credentialed radiology centers nationwide. You will be referred to a certified radiologist based on condition, preferences and location.',
    htmlContent: '<ul><li>When calling One Call Medical, mention the membership code GALAXY to obtain your discount</li><li>Service representatives will assist you in selecting a network provider convenient to your home or work</li><li>Once a facility is chosen, an appointment will be scheduled through a three-way conference call with the service representative, the imaging center, and the patient</li><li>Service representatives can answer questions regarding tests, what to expect at your appointment, directions to the facility, etc.</li><li>You must have your doctor’s order before scheduling an appointment</li></ul>',
    faq: {
      items: [
        {
          title: 'How do I pay?',
          htmlContent: 'You’ll pay when you schedule the appointment. No payment is due at your appointment.'
        },
        {
          title: 'How do I use this benefit?',
          htmlContent: 'Call Monday to Friday, 7:00 am to 7:30 pm Central Time to find a participating facility, request an appointment, or for pricing information. Use the code GALAXY to obtain your discount.'
        }
      ]
    }
  },
  lab: {
    title: 'Lab Testing',
    description: 'Monitor your health with 10% to 80% off typical costs of lab work.',
    descriptionDesktop: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    save_on: 'National savings on lab work',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    htmlContent: '<ul><li>Test categories include allergies, vitamin and cholesterol levels, liver function, fertility, thyroid and more</li><li>Confidential results in as little as 24 hours for most tests</li><li>Order by phone Monday to Friday, 7:00 am to 10:00 pm and Saturday, 8:00 am to 12:00 pm Central Time or online (Provide code R-NEWB when ordering) Lab benefit not available in MD, NJ, NY and RI.</li></ul>',
    externalLinkTitle: 'Order labs',
    faq: {
      items: [
        {
          title: 'What is the testing procedure?',
          htmlContent:
            'First you order your test online, through chat or by telephone (Monday to Friday, 7:00 am to 10:00 pm and Saturday, 8:00 am to 12:00 pm Central Time). If you’re ordering by phone, you will need to provide codeR-NEWB.You then print your requisition form which you must have with you when you go to the patient service center for your blood draw. The lab will not draw your blood without the requisition*. There, your blood is drawn by a certified phlebotomist. The results are received by our office generally within 24-48 hours for most tests and uploaded to your secure online account. *When you go to the lab to have your blood drawn, only take the requisition form and your photo ID with you. If you bring a requisition to a lab OTHER than the one provided through this service, you will receive a bill from the lab for which you will be responsible. Bring NO other requisition forms. If you go to another lab that is not included in the list of participating patient service centers, you be also be responsible for the bill from that lab.'
        },
        {
          title: 'I feel healthy, so why should I get tested?',
          htmlContent:
            'A serious medical condition such as heart disease, prostate cancer or diabetes can exist without noticeable symptoms for up to two years. Early detection is your best defense. A simple blood test can increase your chances of identifying potential medical conditions, and establish a baseline of your normal ranges from which future tests can be monitored.'
        },
        {
          title: 'Will this test be paid for by insurance?',
          htmlContent:
            'This lab testing benefit service does not file insurance claims. Some insurance plans have a wellness or prevention benefit included. The lab testing service can provide you with CPT codes so that you may file for reimbursement yourself. There is no guarantee your claim will be reimbursed. Contact your insurance provider for your benefits and reimbursement options.'
        },
        {
          title: 'Do I need to see my healthcare provider to get tested?',
          htmlContent:
            "Direct access testing allows greater participation in one's own healthcare. Your healthcare provider can refer you to one of the participating lab facilities, but it’s not a requirement. However, you will be asked to provide the specific name of the test you wish to order. This service is not allowed to make any test recommendations. Participating lab facilities CLIA-certified and regulated by appropriate governmental agencies."
        },
        {
          title: 'What is a patient service center?',
          htmlContent:
            'Patient Service Centers are certified laboratories where patients have blood drawn. These centers are staffed by licensed phlebotomists, and are the same facilities referred to by healthcare providers.'
        },
        {
          title: 'Do I need an appointment?',
          htmlContent:
            'An appointment is usually not required at most Patient Service Centers. You are encouraged to call the lab location to confirm their hours of operation and that an appointment is not required.'
        },
        {
          title: 'Will I be able to understand the results?',
          htmlContent:
            'All test results include the normal reference ranges, with abnormalities indicated. It is recommended you seek a healthcare provider to discuss results outside normal ranges. For assistance in better understanding lab tests and results, please visit labtestsonline.org.'
        },
        {
          title: 'When will I receive the results?',
          htmlContent:
            'Please view test descriptions for expected turnaround time for each test. Most results are available in as little as 24-48 hours after your blood is drawn. You will receive an email when your results have been uploaded to your secure online account.'
        },
        {
          title: 'Will my healthcare provider receive a copy of my results?',
          htmlContent:
            'Your privacy is respected and will remain confidential. You are the only one who receives the results unless you specify otherwise in writing. You can request to have a copy of your results sent to your healthcare provider only with a signed HIPAA release form giving us the authorization to do so.'
        }
      ]
    }
  },
  hearing: {
    title: 'Hearing',
    description: 'Find your ideal hearing solution at retail stores.',
    descriptionDesktop: 'Find an affordable hearing solution, whether at a retail store or through home delivery.',
    save_on: 'Find your ideal hearing solution at retail stores',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    benefits: [
      {
        retail: {
          title: 'Retail Hearing Care',
          save_on: 'Find your ideal hearing solution at retail stores',
          information: 'Let Amplifon help you find the right hearing aid solution to fit your lifestyle through personalized service and exceptional products for every budget.',
          htmlContent: '<ul><li>Average savings of 64% off manufacturer suggested retail price for hearing aids</li><li>One year free follow-up care for cleaning and checkups with purchase</li><li>Free two-year supply of disposable batteries or a charging station to keep you powered</li><li>Three-year warranty for loss, repairs, or damage</li><li>100% money-back guarantee during your 60-day risk-free trial</li><li>Offering leading manufacturers including Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex, and Unitron</li></ul>',
          faq: {
            items: [
              {
                title: 'How do I find participating providers?',
                htmlContent: "Locate providers near you through the app or online or by calling Monday to Friday, 7:00 am to 7:00 pm Central Time."
              },
              {
                title: 'How does the program work?',
                htmlContent: 'When you call, an advocate will explain the Amplifon process, request your mailing information and assist you in making an appointment with a hearing care provider. Amplifon will send information to you and the hearing care provider. This will ensure your Amplifon program is activated.'
              },
              {
                title: 'How many times per year can I use my hearing discount?',
                htmlContent: 'There is no limit on the number of times you may use your discount card for any benefit.'
              },
              {
                title: 'Is the discount the same at all participating locations?',
                htmlContent: 'Yes, we have a nationwide network of over 5,100 hearing healthcare providers.'
              },
              {
                title: 'Do you offer more than one brand of hearing aids?',
                htmlContent: 'Yes. We offer more than 2,000 hearing aid models from leading manufacturers such as Oticon, Phonak and Starkey.'
              }
            ]
          }
        }
      },
      {
        home: {
          title: 'Home Delivery Hearing Aids',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save on hearing solutions with home delivery',
          information: 'Hearing Assist, the #1 direct to consumer hearing aid brand, brings access and affordability to the hearing aid industry with solutions designed to amplify the frequencies most commonly associated with mild to moderate hearing loss, shipped directly to your home.',
          htmlContent: "<ul><li>Discount of $100 off the lowest advertised price per pair of hearing devices at leading retailers or $50 off a single device*</li><li>Save 25% off any hearing accessories**</li><li>Enjoy a flat rate of $99 per year for the Peace of Mind protection plan**</li><li>Includes a 60-day money-back guarantee</li><li>Shipping is free</li><li>No prescription or costly exam needed to purchase your hearing solution <p><span>&#42;</span>Subject to change </p><span>&#42;</span><span>&#42;</span>Discount only applies when a hearing device is purchased</li></ul>",
          externalLinkTitle: 'Purchase hearing aids',
          faq: {
            items: [
              {
                title: 'How does the program work?',
                htmlContent: "Call or visit the website to find the right hearing aid solution for you and pay the discounted price at checkout. Your hearing solutions will be directly delivered to your home with free shipping."
              },
              {
                title: 'How do I know if the hearing aids will fit me?',
                htmlContent: 'Hearing Assist hearing aids are one size fits most. Some models work with a downloadable app and can be self-tuned to your individual needs. While our hearing aids are designed to work for most, we understand that they won’t work for everyone, so we give you 60 days to try them, risk-free.'
              },
              {
                title: 'Can I use my HSA to purchase hearing aids?',
                htmlContent: 'Yes! Hearing Assist hearing aids are a qualified purchase through Flexible Spending & Health Savings Accounts.'
              },
              {
                title: 'Does the discount only work on the initial purchase?',
                htmlContent: 'No! You can apply the discount to every device purchase, not just the first one.'
              },
              {
                title: 'Who do I call if I need help with my hearing aids?',
                htmlContent: 'US-based customer support can be reached at 800.280.4050 Monday to Saturday, 7:00 am to 10:00 pm Central Time and Sunday, 8:00 am to 5:00 pm Central Time.'
              }
            ]
          }
        }
      }
    ]
  },
  diabetic: {
    title: 'Diabetic supplies',
    description: 'Discounted diabetic supplies mailed to your home.',
    descriptionDesktop: 'Save 10% to 50% on diabetic testing supplies, and get a free fully-audible blood glucose meter with your first order.',
    save_on: 'Discounted diabetic supplies mailed to your home',
    information: 'Save 10% to 50% on diabetic testing supplies, and get a free fully-audible blood glucose meter with your first order. With the convenient online, pre-paid program, you receive discounted diabetic testing supplies shipped directly to your home. Services include:',
    htmlContent: '<ul><li>Free shipping on diabetic supply packages</li><li>Tracking information updates via email or text</li><li>Annual testing program or purchase-as-needed options available</li><li>No prescription required for OTC testing supplies</li><li>100% satisfaction guaranteed, or get your money back To activate your account, enter your Group ID located on your membership card. You will receive an email to confirm your email address and complete registration. Once you have completed activation, you can join a testing program or make a purchase.</li></ul>',
    externalLinkTitle: 'Shop and order',
    faq: {
      items: [
        {
          title: 'Is a prescription required?',
          htmlContent: 'No, a prescription is not required for over-the-counter (OTC) diabetic supplies.'
        },
        {
          title: 'What is the Annual Testing Program?',
          htmlContent: 'Signing up for the Annual Testing Program gives you greater savings on your diabetic supplies with automatic, quarterly shipping. Choose your program based on how many times a day you test. You can also just purchase supplies as needed.'
        },
        {
          title: 'Can I order supplies by phone?',
          htmlContent: 'Yes, call the Rx Valet Customer Care Team Monday to Friday 7:00 am to 7:00 pm or Saturday 8:00 am to 1:00 pm.'
        }
      ]
    }
  },
  travel: {
    title: 'Affinity travel',
    description: 'Exclusive discounts of up to 70% at more than 700,000 hotels.',
    descriptionDesktop: 'Affinity Travel is a wholesale travel and entertainment platform offering unpublished rates and exclusive discounts of up to 70% at more than 700,000 hotels.',
    save_on: 'Save up to 70% on hotels',
    information: 'Affinity Travel is a wholesale travel and entertainment platform offering unpublished rates and exclusive discounts of up to 70% at more than 700,000 hotels. They guarantee lower rates than any other online travel agency.',
    htmlContent: ''
  }
};

export default benefits;
