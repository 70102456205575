import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@separate/components/Button';
import AddNewCardOffcanvas from '@separate/components/PaymentMethods/AddNewCardOffcanvas';
import PaymentMethodsList from '@separate/components/PaymentMethods/PaymentMethodsList';
import PayWithNewlyAddedCardOffcanvas from '@separate/components/PaymentMethods/PayWithNewlyAddedCardOffcanvas';
import ProcessingModal from '@separate/components/ProcessingModal';
import SuccessModal from '@separate/components/SuccessModal';
import { SetShowMissedPaymentModalContext } from '@separate/context/SetShowMissedPaymentModalContext';
import { useConfirmCardPayment } from '@separate/hooks/payment';
import { useListPaymentMethods, useResetListPaymentMethods, useUpdateSetupIntent } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { CardSuccess, CloseCircle, NewCardAdd } from '@separate/icons';
import { logEvent, PAYMENT_FAILED } from '@separate/utilities/analytics';
import { scrollToView } from '@separate/utilities/window';
import styles from './paymentMethodChangerOffcanvas.module.scss';

export default function PaymentMethodChangerOffcanvas({
  confirmCardPaymentHook,
  container,
  listPaymentMethodsHook,
  onClickAddCard,
  onDismiss,
  onHide,
  onPaymentMethodClick,
  onSuccess,
  paymentSubscription,
  primaryPaymentMethodId,
  resetListPaymentMethodsHook,
  resetSubscriptionQueryHook,
  setupSubscription,
  show,
  updatePaymentMethodHook,
  updateSetupIntentHook,
  pid,
}) {
  const { t } = useTranslation();
  const resetListPaymentMethods = resetListPaymentMethodsHook();

  const [addedAsDefault, setAddedAsDefault] = React.useState(true);
  const [selectedMethod, setSelectedMethod] = React.useState();
  const [showAddCard, setShowAddCard] = React.useState(false);
  const [showAddSuccess, setShowAddSuccess] = React.useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = React.useState(false);
  const [showUseNewCard, setShowUseNewCard] = React.useState(false);

  const setShowMissedPaymentModal = React.useContext(SetShowMissedPaymentModalContext);

  const buttonRef = React.useRef();

  // Confirm payment
  function onPaymentSuccess() {
    setShowPaymentSuccess(true);
  }
  function onPaymentError() {
    logEvent(PAYMENT_FAILED);
    onDismiss?.();
    setShowMissedPaymentModal(true);
  }
  const {
    isLoading,
    confirmCardPayment,
  } = confirmCardPaymentHook({ onSuccess: onPaymentSuccess, onError: onPaymentError });

  // Local handler functions
  function handleClickAddCard() {
    setShowAddCard(true);
    onClickAddCard?.();
  }

  function handleHide() {
    onHide?.();
  }

  function handlePaymentMethodClick(paymentMethod) {
    if (selectedMethod && paymentMethod === selectedMethod) {
      setSelectedMethod(undefined);
    } else {
      setSelectedMethod(paymentMethod);
      scrollToView(buttonRef);
    }
    onPaymentMethodClick?.(paymentMethod);
  }

  function handlePayNow() {
    handleHide();
    confirmCardPayment({
      clientSecret: paymentSubscription.client_secret,
      data: { payment_method: selectedMethod.id },
    });
  }

  function handleAddNewCardSuccess(defaultVal) {
    setAddedAsDefault(defaultVal);
    setShowAddSuccess(true);
  }

  function handleShowAddSuccessHide() {
    resetListPaymentMethods(pid);
    setShowAddSuccess(false);
    setShowUseNewCard(true);
  }

  function handleShowPaymentSuccessHide() {
    setShowPaymentSuccess(false);
    onSuccess?.();
  }

  function handlePayNewlyAddedCardSuccess() {
    handleHide();
    onSuccess?.();
  }

  return (
    <>
      <Offcanvas
        backdropClassName={styles.backdrop}
        className={styles.container}
        container={container}
        onHide={handleHide}
        placement="bottom"
        show={show && !showAddCard && !showUseNewCard}
      >
        <Offcanvas.Header className={styles.header}>
          <Offcanvas.Title className={styles.title}>
            {t('premium.payment.change_payment_method')}
          </Offcanvas.Title>
          <CloseCircle aria-label="Hide" className={styles.closeButton} onClick={handleHide} />
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.body}>
          <PaymentMethodsList
            onClickAddCard={handleClickAddCard}
            onPaymentMethodClick={handlePaymentMethodClick}
            listPaymentMethodsHook={listPaymentMethodsHook}
            primaryPaymentMethodId={primaryPaymentMethodId}
            selectedPaymentMethodId={selectedMethod?.id}
            pid={pid}
          />
          <div className={styles.defaultAndPay}>
            <div className={cx(styles.hiddenDefaultAndPay, { [styles.shown]: selectedMethod })}>
              <div className={styles.buttonWrapper} ref={buttonRef}>
                <Button
                  className={styles.payNowButton}
                  disabled={!selectedMethod}
                  onClick={handlePayNow}
                >
                  {t('premium.payment.pay_now')}
                </Button>
              </div>
              <div className={styles.informational}>
                {t('premium.payment.will_be_default')}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <AddNewCardOffcanvas
        clientSecret={setupSubscription?.client_secret}
        container={container}
        onHide={() => setShowAddCard(false)}
        onSuccess={handleAddNewCardSuccess}
        setupIntentId={setupSubscription?.setup_intent_id}
        show={show && showAddCard && !showUseNewCard}
        updatePaymentMethodHook={updatePaymentMethodHook}
        updateSetupIntentHook={updateSetupIntentHook}
        pid={pid}
      />
      <PayWithNewlyAddedCardOffcanvas
        addedAsDefault={addedAsDefault}
        confirmCardPaymentHook={confirmCardPaymentHook}
        container={container}
        listPaymentMethodsHook={listPaymentMethodsHook}
        onDismiss={onDismiss}
        onHide={() => setShowUseNewCard(false)}
        onSuccess={handlePayNewlyAddedCardSuccess}
        primaryPaymentMethodId={primaryPaymentMethodId}
        resetListPaymentMethodsHook={resetListPaymentMethodsHook}
        resetSubscriptionQueryHook={resetSubscriptionQueryHook}
        show={show && !showAddCard && showUseNewCard}
        subscription={paymentSubscription}
        pid={pid}
      />
      <ProcessingModal
        container={container}
        show={isLoading}
        title={t('premium.payment.processing')}
      />
      <SuccessModal
        container={container}
        show={showAddSuccess}
        icon={<NewCardAdd />}
        title={t('user.profile.new_card_added')}
        description={t('user.profile.new_card_delay')}
        buttonText={t('close')}
        onHide={handleShowAddSuccessHide}
      />
      <SuccessModal
        container={container}
        show={showPaymentSuccess}
        icon={<CardSuccess />}
        title={t('premium.payment.confirmed')}
        description={t('premium.payment.received')}
        buttonText={t('close')}
        onHide={handleShowPaymentSuccessHide}
      />
    </>
  );
}

PaymentMethodChangerOffcanvas.propTypes = {
  confirmCardPaymentHook: PropTypes.func,
  container: PropTypes.any,
  listPaymentMethodsHook: PropTypes.func,
  onClickAddCard: PropTypes.func,
  onDismiss: PropTypes.func,
  onHide: PropTypes.func,
  onPaymentMethodClick: PropTypes.func,
  onSuccess: PropTypes.func,
  paymentSubscription: PropTypes.object,
  primaryPaymentMethodId: PropTypes.string,
  resetListPaymentMethodsHook: PropTypes.func,
  resetSubscriptionQueryHook: PropTypes.func,
  setupSubscription: PropTypes.object,
  show: PropTypes.bool,
  updatePaymentMethodHook: PropTypes.func,
  updateSetupIntentHook: PropTypes.func,
  pid: PropTypes.string, // for zendesk-sep-admin
};

PaymentMethodChangerOffcanvas.defaultProps = {
  confirmCardPaymentHook: useConfirmCardPayment,
  listPaymentMethodsHook: useListPaymentMethods,
  resetListPaymentMethodsHook: useResetListPaymentMethods,
  updateSetupIntentHook: useUpdateSetupIntent,
};
