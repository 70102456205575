const farmasep = {
  title: 'farmaSEP',
  main: { title: 'Save Money on Your Prescriptions' },
  form: {
    drugName: 'Drug Name',
    drugPlaceholder: 'Type the drug name (like Lexapro)',
    zipCode: 'Zip Code',
    search: 'Compare Prices',
  },
  content: {
    popular: 'Popular Searches',
    drugstores: 'Accepted at these retailers',
  },
  popularSeaches: {
    drug: [
      {
        drugName: 'Lexapro',
      },
      {
        drugName: 'Sildenafil',
      },
      {
        drugName: 'Norvasc',
      },
      {
        drugName: 'Lipitor',
      },
      {
        drugName: 'Zoloft',
      },
      {
        drugName: 'Cozaar',
      },
    ],
  },
  retailers: [
    {
      image: 'https://images.contentstack.io/v3/assets/bltd488044897c9abc0/bltd4b2d53fd99f1dd7/5f5fac59f0d2b04fae76a553/target.png',
    },
    {
      image: 'https://images.contentstack.io/v3/assets/bltd488044897c9abc0/blt8d27c610b9ff5b37/5f5fac590c4cec6883883558/walmart.png',
    },
    {
      image: 'https://images.contentstack.io/v3/assets/bltd488044897c9abc0/blt8f5b4a9b04f0d095/5f5fac596f40ab4648b5c2a0/safeway.png',
    },
    {
      image: 'https://images.contentstack.io/v3/assets/bltd488044897c9abc0/bltada585284b76b343/5f5fac5aad6ff34788c03540/walgreens.png',
    },
  ],
  result: {
    brand: "Brand",
    generic: "Generic",
    type: "Type",
    concentration: "Concentration",
    quantity: "Quantity",
    coupon: "Get Coupon",
    results: "RESULTS FOR",
    noResults:"No Results",
    resultMessage: "Try modifying your search",
    suggestion: "We suggest double-checking the spelling or try a different zipcode.",
    dismiss:"Try another search"
  },
  social_share: {
    copy_link: "Link copied!"
  }
};

export default farmasep;
