const premium = {
  signup: "Obtener mi membresía",
  already_enrolled: "Ya registrado",
  intro: {
    title: "Membresía PODERsalud",
    subtitle: "Revise los beneficios incluidos en su suscripción:",
    trusted_advisor: "Asesoría prioritaria",
    exclusive_content: "Recompensas exclusivas",
    medical_discounts_subtitle: "Ahorre con descuentos médicos en servicios como:"
  },
  benefits: {
    teladoc: "Telemedicina",
    vision: "Visión",
    hearing: "Ayuda auditiva",
    mri: "Rayos X",
    travel: "Turismo descontado",
    lab: "Análisis clínicos",
    diabetic: "Diabetes",
    pharmacy: "Farmacia",
    dental: "Dentista",
  },
  personal_info: {
    title: "Perfil",
    subtitle: "Rellene los campos a continuación. Sus datos se almacenan con los más altos estándares de seguridad.",
  },
  address: {
    title: "Domicilio",
    subtitle: "Rellene los campos a continuación. Sus datos se almacenan con los más altos estándares de seguridad.",
  },
  payment: {
    annual_membership: "Membresía anual",
    title: "Forma de pago",
    subtitle: "Seleccione cómo le gustaría pagar para empezar a disfrutar sus beneficios",
    coupon: "Cupón",
    redeem: "Redimir",
    plan: "Tipo de plan:",
    membership: "Membresia premium",
    monthly_membership: "Membresía mensual",
    monthly: "Mensual",
    price: "Precio",
    duration: "mes",
    credit_card_number: "Número de tarjeta",
    expiry: "Expiración mm/aa",
    cvv: "CVV",
    confirm_purchase: "Confirmar compra",
    failure_title: "¡Uh-oh!",
    product_name: "Membresía Premium",
    or: "O",
    pay_with_card: "pague con tarjeta",
    update_and_pay: "Actualizar + Pagar",
    update: "Actualizar",
    yearly: "Anual",
  },
  steps: {
    benefits: "Beneficios",
    personal: "Perfil",
    address: "Domicilio",
    plans: "Tipos",
    payment: "Pago",
  },
  teledoc_sso: 'Solicitar consulta online',
  disclaimer:
    "Inscripción para plan de descuento. Este programa NO ES una póliza de seguros, la intención NO ES reemplazar a su compañía de seguros, y tampoco cumple con los requisitos mínimos de cobertura que piden el Affordable Care Act o el M.G.L. c. 111M y 956 CRM 5.00 de Massachusetts. Su membresía es efectiva desde la recepción de los materiales de membresía. Este programa no está disponible para residentes de UT, VT o WA. Tras inscribirse, Tendrá 30 días para evaluar el servicio y si no queda satisfecho en ese período, puede cancelar el programa sin costo alguno y le devolveremos su dinero. Los descuentos los puede utilizar sólo en las oficinas de los proveedores de servicios médicos que forman parte de la red, y cada titular de la membresía tiene la obligación de pagar los servicios descontados en el momento en el que se reciben. Para una lista completa de los términos y condiciones, haga clic aquí. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475. Formulario # HPSEP2",
  not_available: "Lo sentimos, este producto no está disponible en su estado.",
  billing: {
    billing: "Pago",
    update_now: "Actualizar",
    edit_payment: "Editar",
    default_payment: "Pago preferido",
  },
  past_due: {
    status_message: "El pago de su suscripción a PODERsalud no pasó. Por favor consulte el saldo de su tarjeta y actualice su método de pago.",
    success_title: "Felicidades",
    success_message: "Su pago se ha procesado correctamente y la información de su tarjeta se ha actualizado.",
  },
  update_payment: {
    success_title: "Felicidades",
    // TODO: This is probably wrong.  We don't have copy for this in proton
    success_message: "La información de su tarjeta se ha actualizado.",
  },
  success: {
    congratulations: "",
    title1: "¡Le damos la bienvenida a PODERsalud!",
    title2: "Recibirá información en su correo sobre cómo acceder sus beneficios.",
    dismiss: "Cerrar",
  },
  coupon: {
    placeholder: "Añadir cupón",
    success: {
      title: "¡Éxito!",
      message: "Su cupón ha sido redimido",
    },
    error: {
      title: "¡Uh-oh!",
      message: "Código de Cupón Incorrecto",
    },
    redeem: "Redimir",
    payment_one: "pago",
    payment_other: "{{ count }} pagos",
    coupon_applied: "¡Felicidades! Recibirá un descuento del {{ discount }} en sus primeros {{ duration }}",
  },
  landing: {
    main: {
      title: "Membresía PODERsalud",
      subtitle: "Obtenga <span>servicios médicos</span> para usted y su familia",
      subtitle_mobile: "Obtenga <span>servicios médicos</span> para usted y su familia",
      caption: "Más oportunidades para ahorrar, aprender y crecer",
      cta: "Regístrate hoy",
      premium_benefits: [
        {
          title: "Descuentos médicos y dentales",
          icon: "MedicalDiscounts"
        },
        {
          title: "Recompensas exclusivas",
          icon: "ExclusiveRewards"
        },
        {
          title: "Incluye a familiares",
          icon: "Family"
        }
      ],
      only: "Solo",
      per_month: "/ al mes",
    },
    about: {
      title: "Cuidados médicos a costos accesibles",
      items:[
        {
          title: "Una membresía, múltiples beneficios",
          text: "PODERsalud le ofrece Telemedicina más ahorros en consultas médicas para usted y su familia inmediata. Además reciba recompensas extras.",
        },
        {
          title: "Pague la suscripción con los ahorros generados",
          text: "¡La membresía se paga sola con los ahorros! Descuentos de hasta el 80% en medicamentos recetados y entre el 15% y 50% en dentistas. Además, puede participar en encuestas exclusivas para generar ingresos adicionales."
        },
        {
          title: "Pensada para la comunidad latina en EE.UU.",
          text: "Todas las personas pueden inscribirse, sin importar si nacieron en el país o vienen llegando. PODERsalud le permite cuidar su salud aunque no tenga aseguranza, pero sin afectar su bolsillo: ¡gaste menos en servicios médicos y viva mejor!"
        }
      ],
    },
    information: {
      title: "Beneficios imparables",
      items_mobile: [
        {
          title: "Plan de descuentos médicos",
          text: "PODERsalud incluye consultas médicas por videollamada cuando las requiera, descuentos de hasta el 50% en dentistas, ahorros en cuidado de la vista, medicamentos y más.",
        },
        {
          title: "Acceso a recompensas exclusivas",
          text: "Su membresía incluye encuestas exclusivas que le permiten generar ingresos adicionales. Como parte del programa de Recompensas está ayudando a que las opiniones de los hispanos en este país cuenten.",
        },
        {
          title: "Reciba la ayuda que necesita en su idioma",
          text: "Nuestro servicio es 100% bilingüe y puede ver todos sus beneficios en el sitio en español. Además, los especialistas de Atención al Cliente hablan su idioma.",
        }
      ],
      items_desktop: [
        {
          key: 'medical',
          title: "Acceso instantáneo a plan de descuentos médicos<span>.</span>",
          text: "Plan de descuentos médicos que incluye consultas médicas por teléfono o videollamada cuando las requiera, descuentos de <span>10-80%</span> en medicamentos, <span>15-50%</span> en dentistas, y más."
        },
        {
          key: 'chat',
          title: "Reciba la ayuda que necesita más rápido<span>.</span>",
          text: "Reciba ayuda en tiempo real cuando sea, ¡desde dónde sea! Con el servicio Asesoría de Confianza de SEP+, envíe sus preguntas por el Chat y reciba respuestas antes que otras personas."
        },
        {
          key: 'exclusive',
          title: "Acceso a recompensas exclusivas<span>.</span>",
          text: "Su membresía incluye encuestas exclusivas que le permiten generar ingresos adicionales. Como parte del programa de Recompensas de SABEResPODER, esta oportunidad ayuda a que las opiniones de los hispanos en este país cuenten.",
        }
      ]
    },
    benefits: {
      title: '¿Para qué sirve PODERsalud?'
    },
    testimonial: {
      title: "Lo que dice la comunidad",
      items: [
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltf6582111a1580cb4/623e0c8f7c24b44cb0bf4988/jose.png",
          name: "Jose",
          location: "Miami, FL",
          text: "Les recomiendo PODERsalud a todas las personas que no tengan seguro médico. Con este programa, el cuidado de la salud es más barato.",
        },
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt7f1b1c137f18f036/62420afb7239af5fef4139a8/angela.png",
          name: "Angela",
          location: "Chicago, IL",
          text: "Contacté al equipo de PODERsalud para solicitar una consulta médica por video y recibimos un diagnóstico de inmediato. Fue increíble.",
        },
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt9e0b398fa962155a/635a6490d13cfe10efe62a30/Lucia.png",
          name: "Lucia",
          location: "Los Angeles, CA",
          text: "Me interesó porque los planes son a muy bajo costo y te incluye toda la familia, y te brinda servicios en todas las áreas. Para la salud de nosotros los hispanos es muy recomendable tener planes de salud como este.",
        }
      ]
    },
    savings: {
      title: 'Ejemplos de ahorros',
      description: 'Con PODERsalud puede ahorrar en promedio $1,050 al año*, y hasta $3,136 para una familia de 4 integrantes al año. Vea ejemplos debajo.'
    },
    faq: {
      title: "Preguntas frecuentes",
      items: [
        {
          title: "¿Para qué me sirve la membresía de PODERsalud?",
          htmlContent: "<p>Esta suscripción le apoya de tres maneras importantes:  </p> <ol> <li>Le facilita el acceso a servicios médicos a un bajo costo.</li>  <li>Le permite generar ingresos adicionales haciendo encuestas mientras descubre información valiosa sobre temas que le ayudarán a tomar decisiones informadas.</li> <li>Pone a su disposición al equipo de Atención al Cliente de SABEResPODER para asistirle cuando lo requiera.</li> </ol>"
        },
        {
          title: "¿Mi familia puede recibir los descuentos de PODERsalud?",
          htmlContent: "¡Sí! Los beneficios se extienden a usted, su pareja y dependientes menores de 26 años."
        },
        {
          title: "¿Qué pasa si quiero cancelar la suscripción?",
          htmlContent: "Cancelar es fácil. Para nosotros es importante ofrecer servicios que sean fáciles de usar. Si necesita hacer algún cambio en su suscripción, lo puede hacer en cualquier momento."
        },
      ]
    },
    fineprint: "*Este es un ejemplo de los ahorros que se pueden generar y no representa una garantía de ahorros específicos en todos los casos. Los costos y ahorros pueden variar con base en el servicio, proveedor y área geográfica."
  },
  appointment: {
    book_appointment_cta: "Hacer una cita",
    book_appointment_instruction: "Llame al siguiente número para reservar una cita"
  },
  annual: {
    billed_annually: "Un solo cobro <strong>anual</strong>",
    billed_monthly: "Cobro <strong>mensual</strong>",
    discount_percentage: "{{ discount }}% de descuento",
    months_free: "{{ number }} meses gratis",
    no_discount: "Sin discuento",
    pay_full_price: "Pagar sin descuento",
    per_month: "/ mes",
    per_year: "/ año",
  },
  missed_payment: {
    header: "¡Ups! Su pago no pasó",
    text_content: "Para seguir disfrutando los servicios de PODERsalud debe revisar su método de pago.",
    banner_text_content: "No pudimos procesar su pago. Puede reactivar su suscripción actualizando el método de pago.",
    close_cta: "Cerrar",
    update_now_cta: "Actualizar",
  },
  magic_coupon_applied: {
    header: "¡Tiene {{ discount }} de descuento!",
    text: "El cupón será aplicado cuando seleccione el tipo de plan en su suscripción.",
  },
  referral: {
    friend_invited_you_to_join: 'Ha recibido una invitación',
    claim_your_special_price: 'Suscríbase a PODERsalud con un crédito de ${{ discount }}. Aproveche esta oferta exclusiva, ¡solo disponible para familiares y amistades!',
    start_my_membership: 'Usar mi descuento',
    joined_with_your_referral: ' ¡Su recomendación funcionó!',
    has_successfully_enrolled:'Gracias por recomendar PODERsalud. ¡Alguien usó su enlace para suscribirse! Un crédito de ${{ discount }} será aplicado a su suscripción.',
    great: 'Genial'
  }
};

export default premium;
