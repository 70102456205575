const surveys_landing = {
  hero: {
    title_one: 'Earn money with',
    title_two: 'online surveys',
    subtitle: 'Get paid for sharing your opinion!',
    text_input: {
      mobile: 'Enter mobile number',
      join: 'Join',
    },
    items: [
      {
        text: 'All you’ll need is access to your smartphone or laptop',
      },
      {
        text: 'Add a new income stream without having to invest too much time or effort',
      },
      {
        text: 'Access your rewards instantly with PODERcard debit',
      },
      {
        text: 'It’s secure, reliable, and simple',
      },
    ],
    cta: 'Start today!',
  },
  register: {
    title: 'How Does It Work?',
    step_one: {
      cta: 'Click here',
      number: '1',
      text: 'or send a text message with the word <span_text>PANELIST</span_text> to <span_text>72237</span_text>',
    },
    step_two: {
      number: '2',
      text: 'Complete your profile in its entirety so we can send you <span_text>personalized survey opportunities</span_text>',
    },
    step_three: {
      number: '3',
      text: 'You’ll receive $$$ for each survey you successfully complete!',
    },
  },
  survey_types: {
    title: 'What are paid online surveys?',
    subtitle: 'Brands want to be in tune with the needs of their consumers. They pay members of their target audience to understand their needs and build  products that satisfy them. Our online surveys help brands to develop offerings that are aligned with their target community’s needs.',
    header_text: 'What types of surveys do we offer?',
    types: [
      {
        title: 'SABEResPODER surveys',
        htmlContent: 'These surveys are designed by the SABEResPODER team, which allows us to control their content and provide an optimal experience. You can identify our surveys by looking for the SABEResPODER logo. They can typically be completed within 1-5 minutes, and will earn you between $0.30 and $1.',
      },
      {
        title: 'Third party surveys',
        htmlContent: 'These are developed by our partner survey marketplaces, such as Lucid and Think Now Research. They tend to be a bit longer, which means the payout is usually higher. Keep in mind that as these earning opportunities aren’t created by SABEResPODER, therefore we don’t control who qualifies for the surveys or the user experience.',
      },
    ],
    text: 'Generalmente las encuestas requieren entre 10-15 minutos para realizarse. Algunas incluso menos y en ocasiones hay encuestas más largas, con un incentivo económico mayor. (EN)',
    cta: 'Complete my first survey',
  },
  rewards: {
    title: 'How does the Rewards program work?',
    subtitle: 'To participate in SABEResPODER surveys you need to join as a panelist. Once you complete your profile, you will have access to the earning opportunities that match your profile.',
    text_one_highlight: 'Rewards Center',
    text_one: 'The more you visit the <span_text>Rewards Center</span_text>, the more you’ll be able to access surveys and earn money!',
    text_two: 'You can also expect to receive text messages from time to time letting you know when new surveys are available.',
  },
  rewards_benefits: {
    title: 'Why should I join the Rewards program?',
    subtitle_one: 'At SABEResPODER, one of our core objectives is to create a space where you can be heard, share your perspective, and access resources to improve your life.',
    subtitle_two: 'There are several perks to participating in our paid online survey program.',
    benefits: [
      {
        title: 'Help your community',
        htmlContent: 'Few things bring us more pride and satisfaction than helping our community. It’s extremely rewarding to empower our members and provide access to tools that allow them to build a better life. By participating in our paid online surveys, you’re part of this core goal. Your opinion has a direct impact on how products and services are developed for the U.S. Hispanic community.',
        icon: 'community',
      },
      {
        title: 'Earn extra income',
        htmlContent: 'Who doesn’t want to have a little extra money in their pocket? We give you with the chance to add a new income stream without having to invest too much time or effort.',
        icon: 'wallet',
      },
      {
        title: 'It’s simple and secure',
        htmlContent: 'We keep your personal data safe, and every response is 100% anonymous. You can participate with your smartphone or other mobile device and it won’t take more than 10-15 minutes of your time.',
        icon: 'secure',
      },
    ],
    text: '¡Represente a la comunidad latina formando parte de nuestro Grupo de Opinión! (EN)',
  },
  testimonials: {
    title: 'Don’t take our word for it',
    subtitle_one: 'Some of our valued members share their experiences:',
    subtitle_two: 'What are you waiting for? Join our community of reward earners and start generating extra income today!',
    items: [
      {
        name: 'Marco V.',
        location: 'Chicago, IL',
        text: 'I’ve participated in many surveys, about 15-20, and I’ve earned about $300-$400 in all. It’s all been directly deposited into my PODERcard.',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt47741eac713a4e1a/6398fa406c25926899d2841c/surveys_testimonial_marco.png',
      },
      {
        name: 'Julia M.',
        location: 'Los Angeles, CA',
        text: 'It’s really easy to answer the questions and it helps us earn some extra money. The money is deposited the next day!',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt10ea15d6ccdd1686/6398fa41d973c923dab9de63/surveys_testimonial_julia.png',
      },
      {
        name: 'Raymond C.',
        location: 'New York, NY',
        text: 'One thing I really love is the surveys you send. They make the suggestions based upon the things they know I purchase, things I’ve told the company I like, basically things they know I’m familiar with. So when it comes down to taking the survey, it’s something I can be informed about and I feel like my opinion really matters!',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltd2be0ff9425c120b/6398fa41fdcf5869f6627040/surveys_testimonial_raymond.png',
      },
    ],
    cta: 'Join now',
  },
  tips: {
    title: 'How much money can I make?',
    subtitle: 'On average, each survey pays between $0.50 and $5. There are shorter surveys with lower rewards, and at times, longer surveys become available with bigger payouts ($10 or more), so look out for those!',
    header_text: '3 tips to maximize your earnings',
    items: [
      {
        title: 'Complete your profile',
        subtitle: 'By including all the required information in your profile you’ll receive notifications when surveys relevant to your background become available. Keep in mind that not everyone qualifies for every survey.',
        icon: 'profile',
      },
      {
        title: 'Answer as soon as you receive the notification',
        subtitle: 'Take the survey when you see it\'s available. There’s a limit to how many people can participate in a survey, so it’s in your best interest to confirm your spot as quickly as possible.',
        icon: 'notification',
      },
      {
        title: 'Visit the Survey Center often',
        subtitle: 'Don’t miss the chance to participate. You’ll find all the available surveys there, not just those that we send to you via SMS.',
        icon: 'surveyCenter',
      },
    ],
    cta: 'Start earning today!',
  },
  faq: {
    title: 'Frequently Asked Questions',
    items: [
      {
        title: 'How do I access my funds?',
        htmlContent: 'There are two ways you can access the funds you earn: <ol><li><strong>Most popular option:</strong> open a <strong>PODERcard</strong> debit account and get access to your rewards instantly*.</li> <li>Receive a prepaid card: it’s issued once you reach a minimum of $10 in earnings.</li></ol> *Your earnings are deposited in your account as soon as you finish a survey.'
      },
      {
        title: 'How is the information I provide in the surveys used?',
        htmlContent: 'The information that you share in the surveys is used to develop products and services. Your opinion is essential to understanding the needs of the community.',
      },
      {
        title: 'Are paid online surveys legal?',
        htmlContent: 'Yes, getting paid to answer surveys online is legal. In the digital age, it’s another means to do market research and develop or design different products and services.',
      },
      {
        title: 'Is the SABEResPODER Rewards Center trustworthy?',
        htmlContent: '<strong>All survey answers and other data collected are done so anonymously.</strong> Your privacy is extremely important to us. We never share your personal information without your consent.',
      },
      {
        title: 'How can I know if an online survey site is a scam?',
        htmlContent: 'There are different things you should look out for to determine if a survey site is legitimate: <ul><li><strong>No false promises or unrealistic expectations:</strong> If a site says you can earn millions of dollars, be careful! Paid online surveys are a great way to generate some extra money, but they cannot and should not replace your primary source of income, nor can they make miracles happen.</li><li><strong>Deposit your earned funds within a reasonable time frame:</strong> If you’re expected to accumulate a large amount of money for a long period of time before you can withdraw it, they are probably scamming you.</li><li><strong>Not sell your personal data or information to third parties:</strong> Legitimate sites’ privacy policies protect your information and don’t allow it to be misused.</li></ul> <strong>SABEResPODER meets all of these criteria! That is our promise and our guarantee to you.</strong>',
      },
    ],
    cta: 'Complete my first survey!',
  },
};

export default surveys_landing;
