import useInputFormState from '@separate/hooks/useInputFormState';
import { required, phone } from '@separate/validations';

const VALIDATIONS = {
  phone_number: {
    translationKey: 'user.phone_number',
    rules: [required, phone],
  }
};

export default function useLoginInputFormState() {
  return useInputFormState(VALIDATIONS);
}
