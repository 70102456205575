import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import PaymentWidget from '@separate/components/PaymentWidget';
import {
  useEditPaymentMethodFetch,
  useResetSubscriptionQuery,
  useUpdatePaymentMethod,
} from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import styles from './editPaymentMethod.module.scss';

export default function EditPaymentMethod(props) {
  const {
    onSuccess,
    resetSubscriptionQueryHook,
    fetchSubscriptionHook,
    updatePaymentMethodHook,
    pid,
    utmTags,
    useSepStyling,
  } = props;

  const [subscription, setSubscription] = React.useState();
  const [show, setShow] = React.useState(false);

  const { t } = useTranslation();

  const resetSubscriptionQuery = resetSubscriptionQueryHook();

  const onFetchSubscriptionSuccess = React.useCallback((data) => {
    setSubscription(data?.subscription);
  }, []);
  const { fetchSubscription } = fetchSubscriptionHook({ onSuccess: onFetchSubscriptionSuccess });

  const absorbUpdateSuccess = React.useCallback(() => {
    // no-op, the update is fire and forget and only serves to expidite processing on the backend
    // before the webhook is processed.
  }, []);
  const absorbUpdateError = React.useCallback(() => {
    // similarly a no-op.
  }, []);
  const { updatePaymentMethod } = updatePaymentMethodHook({
    onSuccess: absorbUpdateSuccess,
    onError: absorbUpdateError,
  });

  React.useEffect(() => {
    if (!fetchSubscription) return;

    fetchSubscription(pid ? { pid, utmTags } : undefined);
  }, [fetchSubscription, pid, utmTags]);

  function handleSuccess(result) {
    const setupIntentId = result?.setupIntent?.id;
    if (setupIntentId) {
      updatePaymentMethod({ setupIntentId, pid });
    }
    setShow(true);
  }

  function dismiss() {
    setShow(false);
    resetSubscriptionQuery(pid);
    onSuccess();
  }

  return (
    <div>
      <PaymentWidget
        setupMode
        clientSecret={subscription?.client_secret}
        price={subscription?.price}
        submitText={t('user.profile.submit_cta')}
        onSuccess={handleSuccess}
        className={styles.paymentStyling}
        useSepStyling={useSepStyling}
      />

      <Modal
        show={show}
        onHide={dismiss}
        title={t('premium.update_payment.success_title')}
        close={false}
      >
        <p> {t('premium.update_payment.success_message')} </p>

        <div className="text-center">
          <Button onClick={dismiss}>
            {t('dismiss')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

EditPaymentMethod.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  resetSubscriptionQueryHook: PropTypes.func.isRequired,
  fetchSubscriptionHook: PropTypes.func.isRequired,
  updatePaymentMethodHook: PropTypes.func.isRequired,
  pid: PropTypes.string, // for zendesk-sep-admin
  utmTags: PropTypes.object, // for zendesk-sep-admin
  useSepStyling: PropTypes.bool, // for zendesk-sep-admin look and feel
};

EditPaymentMethod.defaultProps = {
  resetSubscriptionQueryHook: useResetSubscriptionQuery,
  fetchSubscriptionHook: useEditPaymentMethodFetch,
  updatePaymentMethodHook: useUpdatePaymentMethod,
};
