import dayjs from 'dayjs';
import { presence } from '@separate/utilities/string';

export default function validateBirthday(birthday, isAdult) {
  const currentAge = dayjs().diff(birthday, 'year');

  if (!presence(birthday)) return null;

  if (currentAge === NaN) return null;

  if (isAdult && currentAge < 18) {
    return 'dependent.must_be_18_or_over';
  } else if (!isAdult && currentAge >= 18) {
    return 'dependent.must_be_under_18';
  } else {
    return null;
  }
}
