import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethodChangerOffcanvas from '@separate/components/PaymentMethods/PaymentMethodChangerOffcanvas';
import RetryCardOffcanvas from '@separate/components/PaymentMethods/RetryCardOffcanvas';
import { useEditPaymentMethodFetch, usePastDuePaymentFetch } from '@separate/hooks/premium';

export default function MissedPaymentFlowOffcanvas({
  confirmCardPaymentHook,
  confirmSetupIntentHook,
  container,
  fetchSetupSubscriptionHook,
  fetchPaymentSubscriptionHook,
  listPaymentMethodsHook,
  onChangePayment,
  onClickAddCard,
  onDismiss,
  onHide,
  onSuccess,
  primaryPaymentMethodId,
  resetListPaymentMethodsHook,
  resetSubscriptionQueryHook,
  updatePaymentMethodHook,
  updateSetupIntentHook,
  show,
  pid,
}) {
  const [setupSubscription, setSetupSubscription] = React.useState();
  const [paymentSubscription, setPaymentSubscription] = React.useState();
  const [retry, setRetry] = React.useState(true);
  const [visible, setVisible] = React.useState(true);

  // Fetch the "payment" subscription, which has a payment intent client secret
  function onFetchPaymentSubSuccess(data) {
    setPaymentSubscription(data.subscription);
  }
  const {
    fetchSubscription: fetchPaymentSubscription,
  } = fetchPaymentSubscriptionHook({ onSuccess: onFetchPaymentSubSuccess });

  React.useEffect(() => {
    if (!fetchPaymentSubscription) return;

    fetchPaymentSubscription(pid ? { pid } : undefined);
  }, [fetchPaymentSubscription, pid]);

  // Fetch the "setup" subscription, which has a setup intent id, needed for updating the default payment type
  function onFetchSetupSubSuccess(data) {
    setSetupSubscription(data.subscription);
  }
  function onFetchSetupSubError(data) {
    console.log('error', data);
  }
  const {
    fetchSubscription: fetchSetupSubscription,
  } = fetchSetupSubscriptionHook({ onSuccess: onFetchSetupSubSuccess, onError: onFetchSetupSubError });

  React.useEffect(() => {
    if (!fetchSetupSubscription) return;

    fetchSetupSubscription(pid ? { pid } : undefined);
  }, [fetchSetupSubscription, pid]);

  // Local handler functions
  function handleClickAddCard() {
    onClickAddCard?.();
  }

  function handleChangePayment() {
    setRetry(false);
    onChangePayment?.();
  }

  function handleHide() {
    setVisible(false);
    onHide?.();
  }

  return (
    <>
      {retry ? (
        <RetryCardOffcanvas
          confirmCardPaymentHook={confirmCardPaymentHook}
          container={container}
          listPaymentMethodsHook={listPaymentMethodsHook}
          onChangePayment={handleChangePayment}
          onDismiss={onDismiss}
          onHide={handleHide}
          onSuccess={onSuccess}
          primaryPaymentMethodId={primaryPaymentMethodId}
          show={show && visible}
          subscription={paymentSubscription}
          pid={pid}
        />
      ) : (
        <PaymentMethodChangerOffcanvas
          confirmCardPaymentHook={confirmCardPaymentHook}
          confirmSetupIntentHook={confirmSetupIntentHook}
          container={container}
          listPaymentMethodsHook={listPaymentMethodsHook}
          onClickAddCard={handleClickAddCard}
          onDismiss={onDismiss}
          onHide={handleHide}
          onSuccess={onSuccess}
          paymentSubscription={paymentSubscription}
          primaryPaymentMethodId={primaryPaymentMethodId}
          resetListPaymentMethodsHook={resetListPaymentMethodsHook}
          resetSubscriptionQueryHook={resetSubscriptionQueryHook}
          setupSubscription={setupSubscription}
          show={show && visible}
          updatePaymentMethodHook={updatePaymentMethodHook}
          updateSetupIntentHook={updateSetupIntentHook}
          pid={pid}
        />
      )}
    </>
  );
}

MissedPaymentFlowOffcanvas.propTypes = {
  confirmCardPaymentHook: PropTypes.func,
  confirmSetupIntentHook: PropTypes.func,
  container: PropTypes.any,
  fetchPaymentSubscriptionHook: PropTypes.func,
  fetchSetupSubscriptionHook: PropTypes.func,
  listPaymentMethodsHook: PropTypes.func,
  onChangePayment: PropTypes.func,
  onClickAddCard: PropTypes.func,
  onDismiss: PropTypes.func,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  resetListPaymentMethodsHook: PropTypes.func,
  resetSubscriptionQueryHook: PropTypes.func,
  show: PropTypes.bool,
  updatePaymentMethodHook: PropTypes.func,
  updateSetupIntentHook: PropTypes.func,
  pid: PropTypes.string, // for zendesk-sep-admin
};

// Hooks that are defaulted here are used directly in this component.  Hooks without a default are passthroughs used in
// one or more child components.  The defaults allow slightly more convenient invocations by projects which share the
// endpoints used in `separate`'s api files, while the props allow other components like `zendesk-sep-admin` to
// override as needed
MissedPaymentFlowOffcanvas.defaultProps = {
  fetchSetupSubscriptionHook: useEditPaymentMethodFetch,
  fetchPaymentSubscriptionHook: usePastDuePaymentFetch,
};
