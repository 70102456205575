import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { inputFormStateType } from "@separate/types";
import { applyMask } from "@separate/utilities/mask";
import styles from "./textInput.module.scss";

export default function TextInput(props) {
  const {
    placeholder,
    value,
    onChange,
    onBlur,
    isInvalid,
    className,
    wrapperClassName,
    labelClassName,
    name,
    inputFormState,
    mask,
    unmasked,
    maskChar,
    disabled,
    useSepStyling,
    children,
    ...extraProps
  } = props;

  function handleChange({ target }) {
    const { value: newValue } = target;

    let result = newValue;

    if (mask) {
      const maskedResult = applyMask({ mask, value: newValue });

      result = unmasked ? maskedResult.unmasked : maskedResult.masked;
    }

    if (onChange) {
      onChange(result);
    }
    if (inputFormState) {
      inputFormState.onFieldChange(name, result);
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur();
    }
    if (inputFormState) {
      inputFormState.onFieldBlur(name);
    }
  }

  let currentValue = (inputFormState ? inputFormState.value[name] : value) || "";

  if (mask) {
    const maskedResult = applyMask({ mask, value: currentValue });
    currentValue = maskedResult.masked;
  }

  const isCurrentlyInvalid = inputFormState
    ? name in inputFormState.fieldErrors
    : isInvalid;

  const currentPlaceholder = placeholder || inputFormState?.labelFor(name);

  return (
    <div className={cx(styles.inputContainer, wrapperClassName)}>
      <Form.Control
        id={name}
        placeholder={useSepStyling ? currentPlaceholder : undefined}
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        className={cx(styles.textInput, className, {
          [styles.isInvalid]: isCurrentlyInvalid,
        })}
        isInvalid={isCurrentlyInvalid}
        {...extraProps}
      />
      <Form.Label
        htmlFor={name}
        className={cx(styles.textLabel, labelClassName, {
          [styles.isInvalid]: isCurrentlyInvalid,
        })}
      >
        {currentPlaceholder}
      </Form.Label>
      {children}
    </div>
  );
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
  inputFormState: inputFormStateType,
  mask: PropTypes.string,
  unmasked: PropTypes.bool,
  maskChar: PropTypes.string,
  disabled: PropTypes.bool,
  useSepStyling: PropTypes.bool,
  children: PropTypes.node,
};
