import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { useTranslation } from '@/hooks/translation';
import AddDependentsOffcanvas from '@separate/components/Dependents/AddDependentsOffcanvas';
import { Add, DependentGradient } from '@separate/icons';
import { Meatballs } from '@separate/icons';
import styles from './index.module.scss';

export default function DependentsList({ container, userDependents, refetchDependents }) {
  const [showAddDependents, setShowAddDependents] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setShowAddDependents(true);
  };

  return (
    <div className={styles.container}>
      {userDependents.map((dependent, index) => (
        <div className={cx(styles.dependentsCard, 'mt-3 d-flex align-items-center justify-content-between')} key={index}>
          <div className='d-flex flex-column'>
            <h4>{`${dependent.first_name} ${dependent.last_name}`}</h4>
            <p className='mt-1'>
              {t('user.dependents.birthday', { birthday: dependent.birthday, interpolation: { escapeValue: false }})}
            </p>
          </div>
          <Button
            className={styles.editCta}
            iconButton
          >
            <Meatballs />
          </Button>
        </div>
      ))}
      <div
        className={cx(styles.newDependents, 'mt-3')}
        onClick={handleClick}
      >
        <DependentGradient className={styles.dependentIcon} />
        <p>{t('user.dependents.add_dependent')}</p>
        <Button
          className={styles.addCta}
          iconButton
        >
          <Add />
        </Button>
      </div>
      <AddDependentsOffcanvas
        show={showAddDependents}
        onHide={() => setShowAddDependents(false)}
        container={container}
        refetchDependents={refetchDependents}
      />
    </div>
  );
}

DependentsList.defaultProps = {
  userDependents: [],
};

DependentsList.propTypes = {
  userDependents: PropTypes.array.isRequired,
  container: PropTypes.any,
  refetchDependents: PropTypes.func,
};
