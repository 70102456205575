import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSingleSignOn } from '@separate/hooks/login';
import { useCurrentUser } from '@separate/hooks/user';

const SingleSignOnContext = React.createContext(null);

function SingleSignOnProvider({ children }) {
  const [isSigningOn, setIsSigningOn] = React.useState(true);
  const [hasSingleSignedOn, setHasSingleSignedOn] = React.useState(false);
  const [onSuccessCalled, setOnSuccessCalled] = React.useState(false);

  const router = useRouter();
  const { isLoggedIn } = useCurrentUser();

  React.useEffect(() => {
    if (isLoggedIn && onSuccessCalled) {
      setIsSigningOn(false);
      setHasSingleSignedOn(true);
    }
  }, [isLoggedIn, onSuccessCalled]);

  function onSuccess() {
    // We can't set isSigningOn to false here yet because we have to give
    // time for the `isLoggedIn` to ripple through the system or else we can show a
    // WithAuthentication page too early and pop up a login modal during that small race.
    setOnSuccessCalled(true);
  }

  function onError() {
    // This will be called if the attempt to use sso fails with a 400.  That means the token
    // is no good, so we should just ignore it and call signing on done without logging in the user.
    setIsSigningOn(false);
  }

  const { signOn } = useSingleSignOn({ onSuccess, onError });

  React.useEffect(() => {
    if (!router || !signOn) return;

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('sso_token') || urlParams.get('survey_token');
    if (token) {
      signOn(token);
    } else {
      setIsSigningOn(false);
    }
  }, [router, signOn]);

  return (
    <SingleSignOnContext.Provider
      value={{ isSigningOn, hasSingleSignedOn }}
    >
      {children}
    </SingleSignOnContext.Provider>
  );
}

SingleSignOnProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SingleSignOnProvider };

export function useIsSingleSigningOn() {
  return {
    isSigningOn: React.useContext(SingleSignOnContext)?.isSigningOn,
    hasSingleSignedOn: React.useContext(SingleSignOnContext)?.hasSingleSignedOn,
  };
}
