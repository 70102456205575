import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Button from '@/components/Button';
import AddDependentsCard from '@separate/components/Dependents/AddDependentsCard';
import { DEPENDENTS_FETCH } from '@separate/constants/analytics';
import { useGetDependents } from '@separate/hooks/dependents';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import styles from './index.module.scss';

export default function Dependents({ onClick }) {
  const [userDependents, setUserDependents] = React.useState([]);
  const { user } = useCurrentUser();
  const { t } = useTranslation();

  const ref = React.createRef();

  const onSuccess = (result) => {
    setUserDependents(result);
  };

  const onError = (err) => {
    logEvent(DEPENDENTS_FETCH, { result: err });
  };

  const { isLoading, fetchUserDependents } = useGetDependents({ onSuccess, onError });

  const fetchDependents = () => {
    fetchUserDependents({ userId: user?.id });
  };

  const handleClick = () => {
    onClick?.();
  };

  React.useEffect(() => {
    if (!user) return;

    fetchUserDependents({ userId: user?.id });
  }, [user, fetchUserDependents]);

  return (
    <div className='mb-4' ref={ref}>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}>{t("user.dependents.title")}</h4>
        <Button
          onClick={handleClick}
          className={styles.editCta}
        >
          {t("user.profile.edit_cta")}
        </Button>
      </div>
      {isLoading && (
        <div className='d-block text-center'>
          <Spinner variant='primary' size='lg' animation='border' className='mt-4' />
        </div>
      )}
      {(!isLoading && userDependents.length > 0) && userDependents.map((dependent, index) => (
        <div className='mt-2' key={index}>
          <div className='d-flex align-items-center mb-4'>
            <span className={styles.infoRow}>{t('user.dependents.dependent', { value: `${index + 1}` })}</span>
            <div className={styles.userInfo}>{`${dependent.first_name} ${dependent.last_name}`}</div>
          </div>
        </div>
      ))}
      {!isLoading && !userDependents.length && (
        <AddDependentsCard
          buttonClassName={styles.addDependentsCta}
          container={ref}
          refetchDependents={fetchDependents}
        />
      )}
    </div>
  );
}

Dependents.propTypes = {
  onClick: PropTypes.func.isRequired,
};
