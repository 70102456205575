import React from 'react';
import { phoneNumberType } from '@/types';
import InfoRow from './InfoRow';

export default function Phone(props) {
  const { phoneNumber } = props;

  function formatPhoneNumber(){
    let re = /(\d{3})(\d{3})(\d{4})/;
    let mobile = phoneNumber.toString();
    let newMobile = mobile.replace(re, "($1) $2-$3");

    return `+1 ${newMobile}`;
  }

  return (
    <InfoRow
      label="user.phone_number"
    >
      {formatPhoneNumber()}
    </InfoRow>
  );
}

Phone.propTypes = {
  phoneNumber: phoneNumberType,
};
