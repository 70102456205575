const surveys_landing = {
  hero: {
    title_one: 'Gane dinero con',
    title_two: 'encuestas pagadas',
    subtitle: '¡Genere ingresos por compartir su opinión!',
    text_input: {
      mobile: 'Ingrese su número de teléfono',
      join: 'Empezar',
    },
    items: [
      {
        text: 'Acceda desde su celular o computadora',
      },
      {
        text: 'Aumente los ingresos de su hogar sin mucho tiempo ni esfuerzo extra',
      },
      {
        text: 'Recompensas instantáneas con la tarjeta de débito PODERcard',
      },
      {
        text: 'Es seguro, confiable y sencillo',
      },
    ],
    cta: 'Tomar mi primera encuesta',
  },
  register: {
    title: '¿Cómo me uno al Grupo de Opinión?',
    step_one: {
      cta: 'Haga clic aquí',
      number: '1',
      text: 'o envíe un mensaje con la palabra <span_text>PANELISTA</span_text> al <span_text>72237</span_text>',
    },
    step_two: {
      number: '2',
      text: 'Rellene su perfil al 100% para recibir <span_text>encuestas elegibles</span_text>',
    },
    step_three: {
      number: '3',
      text: '¡Recibirá $$$ por cada encuesta realizada con éxito!',
    },
  },
  survey_types: {
    title: '¿Qué son las encuestas pagadas?',
    subtitle: 'Las empresas pagan a las personas por responder preguntas sobre hábitos de consumo. Al formar parte de nuestro Grupo de Opinión está ayudando a reflejar las aspiraciones que tenemos como comunidad. Las marcas interesadas en desarrollar productos y/o servicios buscan que les informemos sobre nuestras necesidades para estar alineados. ¡Su opinión siempre cuenta!',
    header_text: '¿Qué tipos de encuestas ofrece SABEResPODER?',
    types: [
      {
        title: 'Encuestas propias',
        htmlContent: 'Las encuestas del Grupo de Opinión son diseñadas por nuestro equipo, lo cual nos permite controlar su contenido y brindar la mejor experiencia. Para identificarlas, llevan el logo de SABEResPODER y son de breve duración (entre 1-5 minutos). Le pueden generar ingresos entre $0.30 y $1.',
      },
      {
        title: 'Encuestas de terceros',
        htmlContent: 'Estas son desarrolladas por nuestros socios, entre ellos Lucid, Think Now Research y Qualtrics. Son las que mejor pagan, sin embargo es importante tener en cuenta que SABEResPODER no controla quién califica para estas oportunidades ni la experiencia del usuario. La ventaja es que las ganancias que generan están integradas con nuestro Centro de Recompensas.',
      },
    ],
    text: 'Generalmente las encuestas requieren entre 10-15 minutos para realizarse. Algunas incluso menos y en ocasiones hay encuestas más largas, con un incentivo económico mayor.',
    cta: '¡Tomar encuesta!',
  },
  rewards: {
    title: '¿Cómo funcionan las encuestas para ganar dinero?',
    subtitle: 'Para acceder a las encuestas pagadas de SABEResPODER debe unirse al Grupo de Opinión. Al completar su perfil con el mayor detalle posible tendrá acceso a las oportunidades que coincidan.',
    text_one: 'Mientras más seguido visite el <span_text>Centro de Recompensas</span_text>, ¡tendrá más probabilidades de participar en encuestas y recibir dinero a cambio!',
    text_two: 'También puede esperar recibir mensajes de texto periódicamente para avisarle cuando hay nuevas encuestas disponibles.',
  },
  rewards_benefits: {
    title: '¿Para qué sirven las encuestas pagadas en EE.UU.?',
    subtitle_one: 'En SABEResPODER uno de nuestros objetivos principales es brindar un espacio para alzar su voz, compartir su perspectiva y acceder a recursos que pueden ayudar a mejorar su vida.',
    subtitle_two: 'Hay varias razones que le pueden beneficiar de nuestro programa de encuestas pagadas en línea.',
    benefits: [
      {
        title: 'Ayude a su comunidad',
        htmlContent: 'Pocas cosas nos dan tanto orgullo y satisfacción como ayudar a nuestra comunidad. Es un placer contribuir a su empoderamiento y facilitar el acceso a recursos para que tener una mejor vida sea posible. Participando de nuestras encuestas, usted forma parte de este objetivo. Su opinión tiene un impacto directo en el desarrollo de productos y servicios que sean de utilidad para la comunidad hispana en EE.UU.',
        icon: 'community',
      },
      {
        title: 'Genere ingresos extra',
        htmlContent: '¿A quién no le gusta tener un poco más de dinero? Le damos la oportunidad de sumarle otro ingreso a su hogar sin requerir de mucho tiempo ni esfuerzo extra.',
        icon: 'wallet',
      },
      {
        title: 'Es fácil y seguro',
        htmlContent: '<span>Protegemos sus datos personales y cada respuesta es 100% anónima.</span> Puede participar desde su celular o cualquier dispositivo. Completar una encuesta no lleva más de 10-15 minutos de su tiempo y puede hacerlo desde donde sea.',
        icon: 'secure',
      },
    ],
    text: '¡Represente a la comunidad latina formando parte de nuestro Grupo de Opinión!',
  },
  testimonials: {
    title: 'Testimonios de usuarios satisfechos con nuestras encuestas',
    subtitle_one: 'Conozca la experiencia de algunos de nuestros miembros:',
    subtitle_two: '¡No espere más! Súmese a nuestra comunidad de panelistas y empiece a generar ingresos extra compartiendo su valiosa opinión.',
    items: [
      {
        name: 'Marco V.',
        location: 'Chicago, IL',
        text: 'He participado en muchas encuestas, unas 15-20, y he ganado unos $300-$400 que han sido depositados directo a mi PODERcard.',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt47741eac713a4e1a/6398fa406c25926899d2841c/surveys_testimonial_marco.png',
      },
      {
        name: 'Julia M.',
        location: 'Los Angeles, CA',
        text: 'Es muy fácil de contestar las preguntas y nos ayuda ganar un dinerito extra. ¡El dinero te lo depositan al día siguiente!',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt10ea15d6ccdd1686/6398fa41d973c923dab9de63/surveys_testimonial_julia.png',
      },
      {
        name: 'Raymond C.',
        location: 'New York, NY',
        text: 'Una cosa que realmente me encanta son las encuestas que envían. Me hacen sugerencias basadas en las cosas que saben que compro, cosas que les he dicho que me gustan, básicamente cosas con las que saben que estoy familiarizado. Entonces, cuando voy a tomar la encuesta, es sobre algo de lo que sé y ¡siento que mi opinión realmente importa!',
        imageSrc: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltd2be0ff9425c120b/6398fa41fdcf5869f6627040/surveys_testimonial_raymond.png',
      },
    ],
    cta: '¡Únase hoy!',
  },
  tips: {
    title: '¿Cuánto dinero puedo ganar con las encuestas?',
    subtitle: 'En promedio, el pago por encuesta es entre $0.50 y $5. Algunas tienen recompensas más bajas por ser más cortas y de vez en cuando, hay encuestas más largas y mejor remuneradas ($10 o más).',
    header_text: 'Tips para aumentar sus ingresos con grupos de opinión',
    items: [
      {
        title: 'Rellene su perfil',
        subtitle: 'Añada el mayor nivel de detalle posible. De esta manera podremos hacerle saber cuando hay encuestas que se ajusten a su perfil. No todas las personas califican para cada encuesta.',
        icon: 'profile',
      },
      {
        title: 'Tome la encuesta lo antes posible ',
        subtitle: 'Actúe al recibir la notificación de la encuesta. Hay un límite de la cantidad de personas que pueden completarlas, por lo cual conviene que confirme su participación rápidamente.',
        icon: 'notification',
      },
      {
        title: 'Visite el Centro de Encuestas',
        subtitle: 'No se pierda de oportunidades para participar. Así podrá enterarse de todas las encuestas disponibles, y no solo las que enviamos por mensaje de texto.',
        icon: 'surveyCenter',
      },
    ],
    cta: 'Empiece a ganar',
  },
  faq: {
    title: 'Preguntas frecuentes',
    items: [
      {
        title: '¿Cómo recibo mis ganancias?',
        htmlContent: 'Cuenta con dos alternativas para acceder a los fondos acumulados: <ol><li><strong>La más popular:</strong> abrir una cuenta de tarjeta débito <strong>PODERcard*.</strong></li> <li>Recibir una tarjeta prepago: solo se puede emitir y enviar tras acumular un mínimo de $10 en recompensas.</li></ol> *Las ganancias se depositan en tiempo real después de responder cada encuesta.'
      },
      {
        title: '¿Qué se hace con la información de mis encuestas?',
        htmlContent: 'La información valiosa que nos brinda a través de sus respuestas es usada para desarrollar productos y servicios para la comunidad hispana en EE.UU. Su opinión es fundamental para entender las necesidades de nuestra comunidad. Las encuestas nos permiten fomentar el crecimiento y empoderamiento de gente como usted.',
      },
      {
        title: '¿Las encuestas para ganar dinero son legales?',
        htmlContent: 'Sí, es completamente legal recibir dinero por responder una encuesta en línea. En la era digital, esta es otra manera de llevar a cabo investigación de mercado y ayudar con el diseño y desarrollo de productos y/o servicios.',
      },
      {
        title: '¿El Grupo de Opinión de SABEResPODER es confiable?',
        htmlContent: '<strong>Las respuestas de las encuestas que administramos siempre son anónimas.</strong> Su privacidad es extremadamente importante para SABEResPODER. Nosotros nunca compartiremos su información personal sin su consentimiento.',
      },
      {
        title: '¿Cómo puedo saber si un sitio de encuestas me quiere estafar?',
        htmlContent: 'Hay varias maneras de comprobar si es legítimo: <ul><li><strong>No se hacen promesas falsas ni se generan expectativas no realistas:</strong> Si dicen que puede ganar millones de dólares, tenga cuidado antes de avanzar. Las encuestas pagadas son una buena manera de generar dinero extra, pero no reemplazan sus ingresos estables ni son magia.</li><li><strong>Se pagan las recompensas dentro de un período de tiempo razonable:</strong> Si el sitio requiere que usted acumule una gran cantidad de dinero por mucho tiempo antes de poder retirar los fondos, es probable que no sea de confianza.</li><li><strong>Jamás se venden sus datos personales a terceros:</strong> Las políticas de privacidad de sitios legítimos protegen su información y no permiten el mal uso de ella.</li></ul> <strong>¡SABEResPODER cumple con todas estas características! Puede confiar en nosotros en cada paso del camino. Es nuestra promesa y nuestra garantía.</strong>',
      },
    ],
    cta: '¡Tomar encuesta!',
  },
};

export default surveys_landing;
