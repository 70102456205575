import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "@separate/components/Button";
import FieldErrorsList from "@separate/components/FieldErrorsList";
import { useCreditCardInput } from "@separate/hooks/payment";
import { useTranslation, useLocale } from "@separate/hooks/translation";
import styles from './paymentWidget.module.scss';

const SEP_STYLES = {
  borderBottom: "3px solid #5C7AFF",
  padding: "20px 0px 0px 0px",
  minHeight: "44px",
  fontSize: "16px",
  "::placeholder": {
    color: "transparent",
  },
};

const ZEN_STYLES = {
  color: 'black',
  borderRadius: '.25rem',
  border: '1px solid #ced4da',
  fontSize: '16px',
  padding: '12px',
  minHeight: '44px',
  '::placeholder': {
    color: 'transparent',
  },
};

function CreditCardForm(props) {
  const {
    setupMode,
    clientSecret,
    loading,
    hide,
    onSuccess,
    onFailure,
    submitText,
    className,
    useSepStyling,
  } = props;

  const [fieldErrors, setFieldErrors] = React.useState(null);
  const [valid, setValid] = React.useState(false);

  const { t } = useTranslation();
  const locale = useLocale();

  const BASE_STYLES = React.useMemo(() => useSepStyling ? SEP_STYLES : ZEN_STYLES, [useSepStyling]);

  const STYLES = React.useMemo(() => ({
    base: BASE_STYLES,
    invalid: {
      color: "#000",
    },
  }), [BASE_STYLES]);

  const cardInputConfig = React.useMemo(
    () => ({
      number: {
        element: "#card-number",
        placeholder: t("premium.payment.credit_card_number"),
      },
      expiry: {
        element: "#card-expiry",
        placeholder: t("premium.payment.expiry"),
      },
      cvc: {
        element: "#card-cvc",
        placeholder: t("premium.payment.cvv"),
      },
    }),
    [t]
  );

  const handleCardChange = React.useCallback((status) => {
    setFieldErrors(status.fieldErrors);
    setValid(status.complete);
  }, []);

  const { isLoading: isConfirmLoading, confirmPayment } = useCreditCardInput({
    ...cardInputConfig,
    onChange: handleCardChange,
    setupMode,
    clientSecret,
    locale,
    onSuccess,
    onError: onFailure,
    style: STYLES,
  });

  function elementStyle(id) {
    if (fieldErrors && fieldErrors[id]) {
      return { ...BASE_STYLES, borderColor: "red" };
    } else {
      return { ...BASE_STYLES, borderColor: "#5C7AFF" };
    }
  }

  function submitPayment() {
    confirmPayment();
  }

  return (
    <div hidden={hide} className={className}>
      <div>
        <Row className={cx(styles.cardRow, "mb-4")}>
          <Col xsm={12}>
            <div className="stripeInputContainer">
              <div
                id="card-number"
                style={elementStyle("cardNumber")}
                className="stripeInput"
              />
              <label>{t("premium.payment.credit_card_number")}</label>
            </div>
          </Col>
        </Row>

        <Row className={styles.cardRow}>
          <Col xs={6} className="pr-1">
            <div className="stripeInputContainer">
              <div
                id="card-expiry"
                style={elementStyle("cardExpiry")}
                className="stripeInput"
              />
              <label>{t("premium.payment.expiry")}</label>
            </div>
          </Col>

          <Col xs={5} className="pl-1">
            <div className="stripeInputContainer">
              <div
                id="card-cvc"
                style={elementStyle("cardCvc")}
                className="stripeInput"
              />
              <label>{t("premium.payment.cvv")}</label>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <FieldErrorsList fieldErrors={fieldErrors} />
          </Col>
        </Row>
      </div>

      <div>
        <Row className="text-center">
          <Col>
            <Button
              loading={isConfirmLoading || loading}
              disabled={!valid}
              onClick={submitPayment}
              className="w-75"
            >
              {submitText}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

CreditCardForm.propTypes = {
  setupMode: PropTypes.bool,
  clientSecret: PropTypes.string,
  loading: PropTypes.bool,
  hide: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  className: PropTypes.string,
  useSepStyling: PropTypes.bool,
};

CreditCardForm.defaultProps = {
  useSepStyling: true,
};

export default CreditCardForm;
