import React from 'react';
import cx from 'classnames';
import Button from '@/components/Button';
import ProfileModal from '@/components/ProfileModal';
import { useTranslation } from '@separate/hooks/translation';
import styles from './missedPaymentBanner.module.scss';

export default function MissedPaymentBanner() {
  const { t } = useTranslation();
  const [showProfileModal, setShowProfileModal] = React.useState(false);

  return (
    <div className={cx(styles.wrapper, 'd-flex align-items-center justify-content-between')}>
      <p className={styles.textContent}>{t('premium.missed_payment.banner_text_content')}</p>
      <div>
        <Button
          className={styles.payButton}
          onClick={() => setShowProfileModal(true)}
        >
          {t('premium.missed_payment.update_now_cta')}
        </Button>
      </div>
      <ProfileModal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        showPayment
      />
    </div>
  );
}
