import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import RadioCard from '@separate/components/CTA/RadioCard';
import {
  PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
  PREMIUM_MEMBERSHIP_PRICE,
} from '@separate/constants/premium';
import { useTranslation } from '@separate/hooks/translation';
import PaymentPricing from './PaymentPricing';
import styles from './subscriptionOptionRadioCard.module.scss';

export default function SubscriptionOptionRadioCard({
  annualPrice,
  className,
  isAnnual,
  onClick,
  selected,
  disabled,
  ...extraProps
}) {
  const { t } = useTranslation();

  function handleClick() {
    if (!disabled && !selected && onClick) {
      onClick();
    }
  }

  const numberOfMonths = Math.floor(((PREMIUM_MEMBERSHIP_PRICE * 12) - annualPrice) / PREMIUM_MEMBERSHIP_PRICE);
  const discountPercentage = (numberOfMonths / 12) * 100;

  const heading = React.useMemo(() => {
    return (
      isAnnual ? (
        <>
          {!onClick ? 'ㅤ' : t('premium.payment.yearly')}
          <div className={cx('flair', styles.flair)}>
            {t('premium.annual.discount_percentage', { discount: discountPercentage })}
          </div>
        </>
      ) : (
        <>
          {t('premium.payment.monthly')}
          <span className={cx('payFullPrice', styles.payFullPrice)}>
            {t('premium.annual.pay_full_price')}
          </span>
        </>
      )
    );
  }, [isAnnual, discountPercentage, t, onClick]);

  const footerText = isAnnual ?
    t('premium.annual.billed_annually') :
    t('premium.annual.billed_monthly');

  return (
    <RadioCard
      className={cx(
        className,
        'subscriptionOptionRadioCard',
        styles.subscriptionOptionRadioCard,
        {
          [styles.selected]: selected,
          'selected': selected,
          [styles.disabled]: disabled,
          'disabled': disabled,
        },
      )}
      handleClick={handleClick}
      selected={selected}
      {...extraProps}
    >
      <div className={cx('heading', styles.heading, 'd-flex flex-row, justify-content-between mb-2')}>
        {heading}
      </div>
      <PaymentPricing
        annualPrice={annualPrice}
        className={cx({ [styles.paymentPricing]: disabled })}
        isAnnual={isAnnual}
      />
      <div
        className={cx('footer', styles.footer)}
        dangerouslySetInnerHTML={{ __html: footerText }}
      />
    </RadioCard>
  );
}

SubscriptionOptionRadioCard.propTypes = {
  annualPrice: PropTypes.number,
  className: PropTypes.string,
  isAnnual: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

SubscriptionOptionRadioCard.defaultProps = {
  annualPrice: PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
};
