import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '@separate/env';

// Usage:
//
// selector: The css-style selector of an html element to be replaced with the card input.
// onChange: A function of type (event) => void to handle changes to the input form.
// clientSecret: The secret returned from the server as the payment intent for what ever is being purchased.
// locale: The two letter code of the locale in order to localize error messages.
//
// The main things on the `event` passed to onChange that we care about are:
//
// complete: boolean, false until the form is fully filled out.
// fieldErrors: null or object.
//   When non-null, it contains a hash keyed by field name ('cardNumber', 'cardExpiry', 'cardCvc') mapping to
//   an array of localized (by Stripe) error messages per field.  This is the same format as the `fieldErrors`
//   returned from our own `useValidator` hook so on page error reporting can treat it the same way.
//
// Additionally the individual field names map at the top level to an object containing the full error from Stripe.
//   It contains a code for any error that is present that can be used to do our own localization if we don't like
//   theirs (it uses "tu" in Spanish):
//     code: "invalid_number" | "incomplete_number" | "incomplete_expiry" | "incomplete_cvc" | "incomplete_zip"

export async function createCardInput({
  number,
  expiry,
  cvc,
  onChange,
  clientSecret,
  setupMode,
  locale = 'es',
  style = {},
}) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY, {
    locale,
  });
  const elements = stripe.elements();
  const cardNumber = elements.create('cardNumber', {
    style,
    placeholder: number.placeholder,
  });
  const cardExpiry = elements.create('cardExpiry', {
    style,
    placeholder: expiry.placeholder,
  });
  const cardCvc = elements.create('cardCvc', {
    style,
    placeholder: cvc.placeholder,
  });

  let items = {
    complete: false,
    cardNumber: {
      complete: false,
      error: null,
    },
    cardExpiry: {
      complete: false,
      error: null,
    },
    cardCvc: {
      complete: false,
      error: null,
    },
  };

  function consolidateChange(event) {
    items = { ...items, [event.elementType]: event, complete: true, fieldErrors: {} };
    ['cardNumber', 'cardExpiry', 'cardCvc'].forEach(key => {
      items.complete &&= items[key].complete;
      const message = items[key].error?.message;
      if (message) {
        // This is to mimic how fieldErrors typically come back from Rails, and how they get reported from our
        // in-house validator hook.
        items.fieldErrors[key] = [message];
      }
    });
    onChange(items);
  }

  cardNumber.mount(number.element);
  cardExpiry.mount(expiry.element);
  cardCvc.mount(cvc.element);

  cardNumber.on('change', consolidateChange);
  cardExpiry.on('change', consolidateChange);
  cardCvc.on('change', consolidateChange);

  const stripeActionType = setupMode ? 'confirmCardSetup' : 'confirmCardPayment';
  async function confirmPayment() {
    const result = await stripe[stripeActionType](clientSecret, {
      payment_method: {
        card: cardNumber
      }
    });

    if (result?.error) {
      throw result.error.message;
    }
    return result;
  }

  return { confirmPayment };
}

export async function createPaymentButton({
  selector,
  productName,
  price,
  clientSecret,
  locale,
  onPaymentButtonAvailability,
  onPaymentRequestCompleted
}) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY, {
    locale
  });
  const elements = stripe.elements();

  const paymentRequest = stripe.paymentRequest({
    country: "US",
    currency: "usd",
    total: {
      label: productName,
      amount: price
    }
  });
  const paymentRequestButton = elements.create("paymentRequestButton", {
    paymentRequest
  });

  const success = await paymentRequest.canMakePayment();
  if (success) {
    paymentRequestButton.mount(selector);
  }
  if (onPaymentButtonAvailability) {
    onPaymentButtonAvailability(!!success);
  }

  paymentRequest.on("paymentmethod", async event => {
    try {
      const result = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      );

      await onPaymentRequestCompleted(result);
      event.complete(result.error ? "fail" : "success");
    } catch (error) {
      event.complete("fail");
    }
  });
}

export async function payWithSavedCard({
  clientSecret,
  payment_methods,
  locale,
}) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY, {
    locale,
  });

  async function confirmPayment() {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: payment_methods[0]?.id
    });

    if (result?.error) {
      throw result.error.message;
    }
    return result;
  }

  return { confirmPayment };
}

export async function confirmSetupIntent(clientSecret, paymentMethodId) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

  const paymentInfo = paymentMethodId ? { payment_method: paymentMethodId } : undefined;

  const response = await stripe.confirmCardSetup(clientSecret, paymentInfo);

  if (response?.error) {
    throw response.error.message;
  }
  return response;
}

export async function confirmCardPayment(clientSecret, data = undefined, options = undefined) {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

  const result = await stripe.confirmCardPayment(clientSecret, data, options);

  return result;
}
