import React from 'react';
import PropTypes from 'prop-types';
import { usePaymentButton } from '@separate/hooks/payment';
import { useLocale } from '@separate/hooks/translation';

export default function WalletButton(props) {
  const { clientSecret, hide, productName, price, onAvailable, onSuccess, onFailure } = props;

  const locale = useLocale();

  usePaymentButton({
    clientSecret,
    productName,
    price,
    locale,
    selector: '#payment-request-button',
    onAvailable,
    onSuccess,
    onFailure,
  });


  return (
    <div hidden={hide}>
      <b-col xsm={12}>
        <div id="payment-request-button" className='paymentButton' />
      </b-col>
    </div>
  );
}

WalletButton.propTypes = {
  clientSecret: PropTypes.string,
  hide: PropTypes.bool,
  productName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onAvailable: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};
