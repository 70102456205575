import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { capitalize } from "lodash";

dayjs.extend(relativeTime);

export function getBrowserTimezone() {
  //TODO: Intl only avail in modern browsers(Prob fine)
  //TODO: Do we store preferred TZ on User model?
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function shortISODate(datetime) {
  return dayjs(datetime).format('YYYY-MM-DD');
}

export function formatLocalDateSimple(datetime) {
  return dayjs(datetime).format("MM/DD/YYYY");
}

export function formatProfileDate(datetime) {
  return dayjs(datetime).format("MMMM YYYY");
}

export function formatDateWithComma(datetime) {
  return dayjs(datetime).format("MMMM DD, YYYY");
}

export function yearMonthDate(datetime) {
  return dayjs(datetime).format('YYYY-MM-DD');
}

export function surveyDate(datetime) {
  return dayjs(datetime).format("MMM-DD-YYYY");
}

export function articleDate(datetime) {
  return dayjs(datetime).format("MM.DD.YY");
}

export function formatMessageDate({ datetime, today, yesterday }) {
  if (today && yesterday) {
    const time = dayjs(datetime);
    const isToday = time.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
    const isYesterday = time.format('YYYY-MM-DD') === dayjs().subtract(1, 'day').format('YYYY-MM-DD');

    let date;
    if (isToday) {
      date = today;
    } else if (isYesterday) {
      date = yesterday;
    } else {
      date = time.format('MMM DD');
    }

    const hour = time.format('hh:mmA');
    return `${date} ${hour}`;
  }

  return dayjs(datetime).format("MMM DD hh:mmA");
}

export function inboundDates(inbound, fields) {
  const result = {...inbound};

  for (const field of fields) {
    result[field] = result[field] && dayjs(result[field]).format("MM/DD/YYYY");
  }

  return result;
}

export function outboundDates(outbound, fields) {
  const result = {...outbound};

  for (const field of fields) {
    result[field] = result[field] && dayjs(result[field], "MM/DD/YYYY").format("YYYY-MM-DD");
  }

  return result;
}

export function remainingInMonth() {
  // TODO properly localize (for some reason dayjs.extend(updateLocale) and related doesn't work?)
  const rightNow = dayjs();

  const startOfNextMonth = rightNow.add(1, 'month').date(1).hour(0).minute(0).second(0).millisecond(0);

  return(startOfNextMonth.fromNow(true));
}

export function addOneYear() {
  const date = new Date();
  return date.setFullYear(date.getFullYear() + 1);
}

export const capitalizeFirstLetter = (text) => {
  return capitalize(text);
};
