import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import PaymentWidget from '@separate/components/PaymentWidget';
import { PREMIUM_MEMBERSHIP_PRICE } from '@separate/constants/premium';
import { useTranslation } from '@separate/hooks/translation';
import styles from './payPastDue.module.scss';

export default function PayPastDue(props) {
  const {
    onSuccess,
    fetchPastDuePaymentHook,
    resetSubscriptionQueryHook,
    updatePaymentMethodHook,
    pid,
    useSepStyling,
  } = props;

  const [subscription, setSubscription] = React.useState();
  const [show, setShow] = React.useState(false);

  const { t } = useTranslation();

  const resetSubscriptionQuery = resetSubscriptionQueryHook();

  const onFetchSuccess = React.useCallback((data) => {
    setSubscription(data.subscription);
  }, []);
  const { fetchSubscription } = fetchPastDuePaymentHook({ onSuccess: onFetchSuccess });

  const absorbUpdateSuccess = React.useCallback(() => {
    // no-op, the update is fire and forget and only serves to expedite processing on the backend
    // before the webhook is processed.
  }, []);
  const absorbUpdateError = React.useCallback(() => {
    // similarly a no-op.
  }, []);
  const { updatePaymentMethod } = updatePaymentMethodHook({
    onSuccess: absorbUpdateSuccess,
    onError: absorbUpdateError,
  });

  React.useEffect(() => {
    if (!fetchSubscription) return;

    fetchSubscription(pid ? { pid } : undefined);
  }, [fetchSubscription, pid]);

  const handleSuccess = React.useCallback((result) => {
    const setupIntentId = result?.setupIntent?.id;
    if (setupIntentId) {
      updatePaymentMethod({ setupIntentId, pid });
    }
    setShow(true);
  }, [pid, updatePaymentMethod]);

  function dismiss() {
    setShow(false);
    resetSubscriptionQuery(pid);
    onSuccess();
  }

  return (
    <div>
      <PaymentWidget
        clientSecret={subscription?.client_secret}
        price={subscription?.price || PREMIUM_MEMBERSHIP_PRICE}
        productName={t('premium.payment.product_name')}
        submitText={t('premium.payment.update_and_pay')}
        onSuccess={handleSuccess}
        className={styles.paymentStyling}
        useSepStyling={useSepStyling}
      />

      <Modal
        show={show}
        onHide={dismiss}
        title={t('premium.past_due.success_title')}
        close={false}
      >
        <p>{t('premium.past_due.success_message')}</p>

        <div className="text-center">
          <Button onClick={dismiss}>
            {t('dismiss')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

PayPastDue.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  fetchPastDuePaymentHook: PropTypes.func.isRequired,
  resetSubscriptionQueryHook: PropTypes.func.isRequired,
  updatePaymentMethodHook: PropTypes.func.isRequired,
  pid: PropTypes.string, // for zendesk-sep-admin
  useSepStyling: PropTypes.bool, // for zendesk-sep-admin
};
