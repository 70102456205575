const earn = {
  alternative: 'Alternative',
  begin: 'Begin',
  begin_with_paid_surveys: 'Enroll',
  complete: 'Complete',
  completed: 'compeleted',
  courses_completed: 'Modules completed',
  deposit: 'Your earnings will be deposited to your PODERcard',
  enroll: 'Enroll',
  enroll_with_paid_surveys: 'Get started!',
  learn_and_earn: 'Rewards',
  learn_earn_spotlight: 'Learn + Earn spotlight',
  micro_header: 'Learn + Earn quizzes',
  micro_view_all: 'View more',
  enrollment: {
    earned_rewards: 'Earned Rewards',
    header_title: 'Start earning today',
    header_subtitle: 'Building additional income by sharing your opinion and gaining valuable information.',
    select_payout: 'Select payout method',
    step: 'Step {{ count }}:',
    enrollment_steps: {
      additional_info: {
        text: 'Additional Information',
        origin: 'Are you of Hispanic or Latino/a origin?',
        race: 'How do you identify?',
        select_all: 'Select all that apply.',
        language: 'What is your preferred language for Surveys and SEP content?',
        paid_surveys: {
          title: 'Are you interested in earning Rewards for completing Paid surveys?',
          subtitle: 'By opting in to this program, you will receive more frequent earning opportunities via SMS.',
          review_tos: 'Review Terms of Service',
        },
      },
      address: {
        text: 'Address',
      },
      personal_info: {
        text: 'Personal Info',
        title: 'Basic information',
        subtitle: 'This is where we’ll ship your Pre-paid card.',
        cta: 'Update my information',
      },
    },
    enrollment_success: {
      welcome: 'Welcome, {{ name }}!',
      title: 'Your Rewards signup is complete.',
      subtitle: 'We will send your prepaid card once you reach $10 in earnings. Payouts will be processed at the end of each month.',
      next_step: {
        title: 'Next Step:',
        subtitle: 'Earn ${{ amount }}',
      },
      cta: 'Complete my Rewards Profile',
    },
  },
  preferred: 'Preferred ★',
  prepaid: 'Prepaid card',
  prepaid_with_paid_surveys: 'Prepaid Rewards Card',
  prepaid_text: 'With our SEP Prepaid card, we’ll transfer your earnings to your card at the end of each month, and only once you’ve reached $10.',
  prepaid_text_with_paid_surveys: 'Once you reach $10 in earnings we’ll send your card, and at the end of each month you’ll receive your payouts.',
  profile: 'Complete your profile!',
  return_to_earn: "Return to dashboard",
  rewards_earned: 'Rewards earned',
  sign_up: 'Sign up for PODERcard',
  sign_up_with_paid_surveys: 'Sign up for PODERcard Debit',
  sign_up_text: 'Your earnings are credited immediately to your PODERcard Debit!',
  sign_up_cta_for_non_panelist: 'Choose your preferred payout method',
  sign_up_for_payout_methods: 'Earn real cash rewards as a SABEResPODER member. Select your desired payout method below and get started!',
  sign_up_for_podercard_to_unlock: 'Sign up for PODERcard to unlock instant payouts',
  spotlight_start: 'Start',
  survey_header: 'Survey spotlight',
  survey_view_all: 'View more',
  want_to_earn: 'Want to earn cash?',
  want_to_earn_with_paid_surveys: 'Ready to cash in your earnings? Let’s go!',
  way_to_pay: "You'll first need to select a payment method below.",
  start_survey_cta: "Start",
  perks: {
    title_1: 'Fast and easy cash rewards!',
    desc_1: 'Our Rewards program provides you with short, easy surveys or guides, and pays you for answering short questions. It’s really that easy!',
    title_2: 'Rewarding your participation',
    desc_2: 'Earn cash rewards by sharing your opinion, taking a short educational course, or simply by referring a friend!',
    title_3: 'Accessible to all',
    desc_3: 'No matter your familiarity with these topics, the Earn program is for everyone. Whether you’re a newcomer or have lived in the U.S. for decades.',
  },
  benefits: {
    title_1: 'Learn and Earn<span>.</span>',
    desc_1: 'Learn and Earn courses aren’t just about cash rewards, they’ll help you make more informed decisions when opening a bank account, buying healthcare services and much more!',
    title_2: 'Share your opinion and earn<span>.</span>',
    desc_2: 'By answering surveys (and getting paid!), you’re helping companies build more tailored products for the community. Participate today and start making your voice heard!',
    title_3: 'Grow the community and earn (coming soon)<span>.</span>',
    desc_3: 'If you love SABEResPODER then consider sharing it with your friends and family. We’ll give you a referral bonus for each person that signs up to one of our products.',
    title_4: 'Get paid faster<span>.</span>',
    desc_4:'If you’re a PODERcard user, you’ll see those earnings appear within moments into your account! If you’re not yet - consider signing up here.',
  },
  faq: {
    items: [
      {
        title: 'What topics can I expect to learn about?',
        htmlContent: 'We offer a variety of topics to learn about, however our focus are on a couple of key areas that impact everyone - finance and healthcare. Everyone needs to earn enough to support their family, and they need to keep them safe and healthy. We know this can sometimes be challenging in the U.S., that’s why we offer incentivized education around these topics.',
      },
      {
        title: "Why do we get paid for answering surveys?",
        htmlContent: "The Latino community continues to grow and our influence grows with it. That’s why brands want to know more about our needs and challenges. Your opinion is incredibly valuable, so you should be paid for it, it’s as simple as that. ",
      },
      {
        title: "How much could I be earning?",
        htmlContent: "Members can make on average between $0.50 and $5.00 per completed survey. Payment amount depends on the survey length and partner.",
      }
    ]
  },
  landing_page: {
    title: "Rewards",
    subtitle: "Discover helpful information and earn cash rewards!",
    earn_button: "Start earning",
    invest: "invests in you",
    banking: "Digital Banking for the Latino Community",
    signup_button: "Sign Up",
    testimonials: "Testimonials",
    comment: "I’ve participated in a number of surveys, 15-20 of them, and I’ve earned around $300-$400 and that’s been more accessible with the PODERcard.",
    faq: "FAQ"
  },
  premium: {
    heading: "Exclusive rewards",
    new: "New!",
    cta: {
      heading: "Start my PODERsalud membership!",
      subheading: "Access to medical discounts and exclusive surveys with higher payouts.",
      sign_up: "Join now",
    },
    survey: {
      available_for: "Ends in",
      earnable_rewards: "Earn",
      complete: "Congratulations! You earned {{ incentive }} for completing this month's exclusive survey.",
      next_available: "Next survey available in",
      subheader: "Members get an exclusive monthly survey with a higher payout. Take yours before it disappears!",
      take_survey: "Take my survey",
    },
  },
};

export default earn;
