import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import LanguageSelector from '@/components/LanguageSelector';
import LoginButton from '@/components/LoginButton';
import { useLocale } from '@separate/hooks/translation';
import { LINK_CLICK } from '@separate/utilities/analytics';
import HamburgerMenu from '../HamburgerMenu';
import styles from './headerBar.module.scss';

export default function HeaderBar(props) {
  const { className, disableLanguageSelect } = props;

  // TODO: This doesn't belong here.  We'll have a language toggle component to handle this, but this
  // is just for sanity checking localization.
  const router = useRouter();
  const locale = useLocale();

  function changeLocale(newLocale) {
    router?.replace(router?.asPath, router?.asPath, { locale: newLocale });
  }

  return (
    <div className={cx(styles.headerBar, className)}>
      <Link href="/">
        <a className={`${styles.home} me-auto`}>
          <img
            src="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltf1d60d238e2a2c15/629e558861670f0fb5dd1824/sep_logo_teal.png"
            alt="logo"
          />
        </a>
      </Link>
      <LanguageSelector
        locale={locale}
        onSelect={changeLocale}
        className={cx(styles.navBarBtn, "me-3")}
        disabled={disableLanguageSelect}
      />
      <LoginButton className={cx(styles.navBarBtn, "me-3")} />
      <HamburgerMenu />
    </div>
  );
}

HeaderBar.propTypes = {
  className: PropTypes.string,
  disableLanguageSelect: PropTypes.bool,
};
