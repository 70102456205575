import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
  PREMIUM_MEMBERSHIP_PRICE,
  PREMIUM_MEMBERSHIP_AS_CURRENCY,
} from '@separate/constants/premium';
import { useTranslation } from '@separate/hooks/translation';
import { asCurrency } from '@separate/utilities/string';
import styles from './paymentPricing.module.scss';

export default function PaymentPricing({ annualPrice, className, isAnnual, hideFullAnnualPrice }) {
  const { t } = useTranslation();

  const fullAnnualPrice = asCurrency((PREMIUM_MEMBERSHIP_PRICE * 12) / 100);
  const discountedAnnualPrice = asCurrency(annualPrice / 100);
  const monthPrice = isAnnual ? asCurrency(annualPrice / 100 / 12) : PREMIUM_MEMBERSHIP_AS_CURRENCY;

  return (
    <div className={cx('paymentPricing', styles.paymentPricing, className)}>
      {isAnnual && !hideFullAnnualPrice && (<span className={cx('fullAnnualPrice', styles.fullAnnualPrice)}>{fullAnnualPrice}</span>)}
      <span className={cx('price', styles.price)}>{isAnnual ? discountedAnnualPrice : monthPrice}</span>
      <span className={cx('perMonthOrYear', styles.perMonthOrYear)}>
        {isAnnual ? t('premium.annual.per_year') : t('premium.annual.per_month')}
      </span>
    </div>
  );
}

PaymentPricing.propTypes = {
  annualPrice: PropTypes.number,
  className: PropTypes.string,
  isAnnual: PropTypes.bool,
  hideFullAnnualPrice: PropTypes.bool,
};

PaymentPricing.defaultProps = {
  annualPrice: PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
  isAnnual: false,
  hideFullAnnualPrice: false
};
