import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Hamburger } from '@/icons';
import { HAMBURGER_MENU_CLICKED } from '@separate/constants/analytics';
import { logEvent } from "@separate/utilities/analytics";
import Button from '../Button';
import Menu from './Menu';
import styles from './menu.module.scss';

export default function HamburgerMenu(props) {
  const { className } = props;

  const [showMenu, setShowMenu] = React.useState(false);

  const handleOpenMenu = () => {
    logEvent(HAMBURGER_MENU_CLICKED, { destination: "menu_button" });
    setShowMenu(true);
    document.body.style.overflow = "hidden";
    if (!isMobile) {
      document.body.style.paddingRight = "15px";
    }
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = "auto";
    if (!isMobile) {
      document.body.style.paddingRight = "0";
    }
  };

  return (
    <div>
      <Button onClick={handleOpenMenu} className={styles.menuButton} iconButton>
        <Hamburger />
      </Button>
      <Menu show={showMenu} onHide={handleCloseMenu} />
    </div>
  );
}

HamburgerMenu.propTypes = {
  className: PropTypes.string,
};
