import React from 'react';
import PropTypes from 'prop-types';
import SuccessModal from '@separate/components/SuccessModal';
import { PAYMENT_METHOD_DELETE_FAILURE, PAYMENT_METHOD_DELETE_SUCCESS } from '@separate/constants/analytics';
import {
  useEditPaymentMethodFetch,
  useListPaymentMethods,
  useRemovePaymentMethod,
  useResetListPaymentMethods,
  useResetSubscriptionQuery,
  useUpdateSetupIntent,
} from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { NewCardAdd } from '@separate/icons';
import { logEvent } from '@separate/utilities/analytics';
import AddNewCardOffcanvas from './AddNewCardOffcanvas';
import DefaultDeletePaymentMethodOffcanvas from './DefaultDeletePaymentMethodOffcanvas';
import DeleteCardConfirmModal from './DeleteCardConfirmModal';
import PaymentMethodsList from './PaymentMethodsList';

export default function PaymentMethods({
  confirmSetupIntentHook,
  container,
  fetchSubscriptionHook,
  handleClickAddNewCard,
  handleHidePaymentMethods,
  isRetryPayment,
  listPaymentMethodsHook,
  primaryPaymentMethodId,
  removePaymentMethodHook,
  resetListPaymentMethodsHook,
  resetSubscriptionHook,
  title,
  updatePaymentMethodHook,
  updateSetupIntentHook,
  pid,
}) {
  const { t } = useTranslation();

  const resetListPaymentMethods = resetListPaymentMethodsHook();
  const resetSubscriptionQuery = resetSubscriptionHook();

  const [showAddCard, setShowAddCard] = React.useState(false);
  const [showAddCardSuccess, setShowAddCardSuccess] = React.useState(false);
  const [showDefaultCardSuccess, setShowDefaultCardSuccess] = React.useState(false);
  const [showDeleteCardConfirm, setShowDeleteCardConfirm] = React.useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = React.useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState({});
  const [subscription, setSubscription] = React.useState();

  const onFetchSubscriptionSuccess = React.useCallback((data) => {
    setSubscription(data?.subscription);
  }, []);
  const { fetchSubscription } = fetchSubscriptionHook({ onSuccess: onFetchSubscriptionSuccess });

  function handleClick(paymentMethod) {
    handleHidePaymentMethods?.();
    setShowPaymentMethod(true);
    setSelectedPaymentMethod(paymentMethod);
  }

  function handleDeleteSuccess() {
    logEvent(PAYMENT_METHOD_DELETE_SUCCESS, { payment_method_id: selectedPaymentMethod.id });
    resetListPaymentMethods(pid);
  }

  function handleDeleteError() {
    // TODO: pop up a modal or similar?
    logEvent(PAYMENT_METHOD_DELETE_FAILURE, { payment_method_id: selectedPaymentMethod.id });
  }

  const { removePaymentMethod } = removePaymentMethodHook({
    onSuccess: handleDeleteSuccess,
    onError: handleDeleteError,
  });

  function handleDelete(paymentMethodId) {
    removePaymentMethod({ paymentMethodId, pid });
  }

  React.useEffect(() => {
    if (!fetchSubscription) return;

    fetchSubscription({ pid });
  }, [fetchSubscription, pid]);

  const handleClickAddCard = () => {
    handleClickAddNewCard?.();

    if(!handleClickAddNewCard) setShowAddCard(true);
  };

  function handleAddNewCardSuccessHide() {
    setShowAddCardSuccess(false);
    resetSubscriptionQuery(pid);
    resetListPaymentMethods(pid);
  }

  function handleDefaultCardSuccessHide() {
    setShowDefaultCardSuccess(false);
    resetSubscriptionQuery(pid);
    resetListPaymentMethods(pid);
  }

  return (
    <>
      <PaymentMethodsList
        hideAddNewCard={isRetryPayment}
        listPaymentMethodsHook={listPaymentMethodsHook}
        onClickAddCard={handleClickAddCard}
        onPaymentMethodClick={handleClick}
        primaryPaymentMethodId={primaryPaymentMethodId}
        title={title}
        pid={pid}
      />
      <AddNewCardOffcanvas
        clientSecret={subscription?.client_secret}
        container={container}
        onHide={() => setShowAddCard(false)}
        onSuccess={() => setShowAddCardSuccess(true)}
        setupIntentId={subscription?.setup_intent_id}
        show={showAddCard}
        updatePaymentMethodHook={updatePaymentMethodHook}
        updateSetupIntentHook={updateSetupIntentHook}
        pid={pid}
      />
      <DefaultDeletePaymentMethodOffcanvas
        clientSecret={subscription?.client_secret}
        confirmSetupIntentHook={confirmSetupIntentHook}
        container={container}
        onDefaultSuccess={() => setShowDefaultCardSuccess(true)}
        onDeleteCard={() => setShowDeleteCardConfirm(true)}
        onHide={() => setShowPaymentMethod(false)}
        method={selectedPaymentMethod}
        setupIntentId={subscription?.setup_intent_id}
        show={showPaymentMethod}
        updateSetupIntentHook={updateSetupIntentHook}
        pid={pid}
      />
      <DeleteCardConfirmModal
        container={container}
        onDelete={() => handleDelete(selectedPaymentMethod.id)}
        onHide={() => setShowDeleteCardConfirm(false)}
        show={showDeleteCardConfirm}
      />
      <SuccessModal
        container={container}
        icon={<NewCardAdd />}
        title={t('user.profile.new_card_added')}
        description={t('user.profile.new_card_delay')}
        buttonText={t('close')}
        onHide={handleAddNewCardSuccessHide}
        show={showAddCardSuccess}
      />
      <SuccessModal
        container={container}
        icon={<NewCardAdd />}
        title={t('user.profile.new_default_card')}
        description={t('user.profile.new_default_delay')}
        buttonText={t('close')}
        onHide={handleDefaultCardSuccessHide}
        show={showDefaultCardSuccess}
      />
    </>
  );
}

PaymentMethods.propTypes = {
  confirmSetupIntentHook: PropTypes.func,
  container: PropTypes.any,
  handleClickAddNewCard: PropTypes.func,
  handleHidePaymentMethods: PropTypes.func,
  isRetryPayment: PropTypes.bool,
  fetchSubscriptionHook: PropTypes.func,
  listPaymentMethodsHook: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  removePaymentMethodHook: PropTypes.func,
  resetListPaymentMethodsHook: PropTypes.func,
  resetSubscriptionHook: PropTypes.func,
  title: PropTypes.string,
  updatePaymentMethodHook: PropTypes.func,
  updateSetupIntentHook: PropTypes.func,
  pid: PropTypes.string, // for zendesk-sep-admin
};

PaymentMethods.defaultProps = {
  fetchSubscriptionHook: useEditPaymentMethodFetch,
  listPaymentMethodsHook: useListPaymentMethods,
  removePaymentMethodHook: useRemovePaymentMethod,
  resetListPaymentMethodsHook: useResetListPaymentMethods,
  resetSubscriptionHook: useResetSubscriptionQuery,
  updateSetupIntentHook: useUpdateSetupIntent,
};
