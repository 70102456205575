import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useTranslation } from "@/hooks/translation";
import styles from './watermark.module.scss';

export default function Watermark() {
  const { t } = useTranslation();

  return (
    <footer className={cx(styles.watermark, 'mt-5 mb-5')}>
      <span className="d-block mb-1">
        &copy; {new Date().getFullYear()} SABEResPODER
      </span>
      <Link href="/legal/terms-and-conditions">
        <a
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login.terms_and_conditions')}
        </a>
      </Link>
      <span className="d-block mt-1">{process.env.NEXT_PUBLIC_VERSION}</span>
    </footer>
  );
}
