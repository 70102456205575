import baseAuthAdminQuery from "../../../sep/src/api/baseAuthAdminQuery";

export async function addDependent({ userId, data }) {
  const path = `users/${userId}/dependents`;

  const response = await baseAuthAdminQuery({
    method: 'POST',
    path,
    data,
  });

  return response?.data;
}

export async function getDependents({ userId }) {
  const path = `users/${userId}/dependents`;

  const response = await baseAuthAdminQuery({
    method: 'GET',
    path,
  });

  return response?.data;
}

export async function deleteDependent({ userId, dependentId }) {
  const path = `users/${userId}/dependents/${dependentId}`;

  const response = await baseAuthAdminQuery({
    method: 'DELETE',
    path,
  });

  return response?.data;
}
