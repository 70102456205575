const benefits = {
  index: {
    benefits_title: 'Mis Beneficios',
    description: 'Navegue todos los beneficios y descuentos médicos que están incluidos en su suscripción.',
    featured_benefit: 'Destacado',
    popular_benefit: 'Beneficios más populares',
    other_benefit: 'Otros beneficios',
    need_help: '¿Tiene preguntas?',
    details: 'Detalles',
    call: 'Contáctenos',
    start_chat_now: 'Abra un chat',
    call_our_support: 'Llámenos',
    start_live_chat: "Con gusto le ayudamos a solucionar todas sus dudas.",
    information: 'Acerca de este beneficio',
    hide_benefits: 'Mostrar menos',
    show_benefits: 'Mostrar más',
    book_appointment: '¿Quiere empezar a ahorrar?',
    call_service: 'Llame ya',
    faq: 'Preguntas frecuentes',
    faqs: '<p>Preguntas más</p><p>frecuentes</p>',
    which_one_do_you_want_to_learn: '¿Sobre qué beneficio le gustaría saber más?',
    learn_more: 'Aprenda más',
    benefit_card: 'Tarjeta de beneficios',
    view_card: 'Ver tarjeta',
    your_membership_card: 'Su tarjeta de descuentos',
    discount_card: 'Descuentos',
    to_receive_your_discount: 'Para recibir su descuento enseñe esta tarjeta en la recepción del dentista.',
    this_is_not_insurance: 'ESTO NO ES UN SEGURO MÉDICO',
    call_customer_care: 'Llame ya',
    membership_id: 'ID de membresía:',
    membership_id_information: 'Use su ID de membresía cuando hable con servicio al cliente',
    membership_id_is_not_available: 'Cargando aún… puede tomar hasta 15 minutos',
    podersalud: 'PODERsalud',
    my_benefits: 'Mis beneficios',
    header_subtitle: 'PODERsalud',
  },
  find_providers: {
    view_map: 'Ver mapa',
    view_list: 'Ver lista',
    call: 'Llamar',
    loading: 'Cargando...',
    providers: 'Proveedores',
    add_favorite_providers: 'Add favorite providers (SP)',
    tap_the_heart_to_add: 'Toque el corazón para añadir a sus favoritos',
    got_it: 'Listo',
    see_your_favorite_providers: 'Proveedores favoritos',
    favorite_providers: 'Proveedores favoritos',
    provider_name_removed_from_favorites: 'Este proveedor ha sido eliminado de sus favoritos.',
    my_favorites: 'Mis favoritos'
  },
  dental: {
    title: 'Dentista',
    description: 'Ahorre en atención y servicios dentales.',
    descriptionDesktop: 'Reciba el cuidado dental que necesita y ahorre dinero en más de 262,000* lugares de consultorios dentales. ¡Use su tarjeta y mantenga sus dientes saludables y brillantes!',
    save_on: 'Ahorre en atención y servicios dentales',
    information: 'Reciba el cuidado dental que necesita y ahorre dinero en más de 262,000* lugares de consultorios dentales. ¡Use su tarjeta y mantenga sus dientes saludables y brillantes!',
    htmlContent: '<ul><li>En la mayoría de los casos, los ahorros son entre de 15% y 50% por visita** en los servicios desde odontología general hasta procedimientos especiales como ortodoncia y periodoncia</li><li>Pregúntele al proveedor si participa en Aetna Dental Access®</li><li>Muestre su tarjeta con el logotipo de Aetna Dental Access® y pague el precio descontado en el momento de la compra</li><li>A partir de abril de 2020 Los costos y ahorros reales varían según el proveedor, el servicio y el área geográfica.</li></ul>',
    membershipCard: {
      title: 'Tarjeta de beneficios dentales',
      subtitle: 'Para recibir su descuento enseñe esta tarjeta en la recepción del dentista.'
    },
    find_a_dentist: 'Encontrar dentista',
    providers_found_for_your_location: 'dentistas encontrados en la ubicación seleccionada',
    save_your_favorite_providers: 'Guarde sus dentistas',
    faq: {
      items: [
        {
          title: '¿Qué sucede si mi dentista no es un proveedor participante?',
          htmlContent: "Recomendar un proveedor a través de la aplicación, en línea o llamando al soporte técnico de los miembros. Envíe la información del proveedor y Aetna se pondrá en contacto con el proveedor para unirse a la red."
        },
        {
          title: '¿Puedo recibir un descuento en cualquier consultorio dental?',
          htmlContent: 'No. Para recibir su descuento, debe ir a un proveedor participante. Encuentre proveedores en su aplicación móvil, en línea, o llame de lunes a viernes, de 7am a 7pm CT, sábados de 8am a 5pm, para preguntas o ayuda a localizar un proveedor.'
        },
        {
          title: '¿Cuántas veces puedo usar el beneficio dental?',
          htmlContent: 'No hay límite en la cantidad de veces que se puede usar este beneficio.'
        }
      ]
    }
  },
  telemedicine: {
    title: 'Telemedicina',
    description: 'El acceso 24/7 a un médico está solo a una llamada o un clic de distancia.',
    descriptionDesktop: 'Sentirse mejor ahora! 24/7 acceso a un médico es sólo una llamada o haga clic lejos—en cualquier momento, en cualquier lugar sin cuota de visita.',
    save_on: 'El acceso 24/7 a un médico es una llamada o un cli',
    information: "Sentirse mejor ahora! 24/7 acceso a un médico es sólo una llamada o haga clic lejos—en cualquier momento, en cualquier lugar sin cuota de visita. Con Teladoc, puede hablar con un médico por teléfono o video en línea para obtener un diagnóstico, opciones de tratamiento y receta, si es médicamente necesario. Ahorre tiempo y dinero evitando salas de espera abarrotadas en el consultorio del médico, la clínica de atención de urgencias o la sala de urgencias. Sólo tiene que utilizar su teléfono, computadora, teléfono inteligente o tableta para obtener un diagnóstico rápido por un médico con licencia de EE.UU.",
    htmlContent: '<ul><li>Atención sanitaria a petición;dondequiera que sea, siempre</li><li>Tratamiento para problemas médicos comunes como resfriados, gripe, hiedra venenosa, infecciones respiratorias, bronquitis, ojo rosado, problemas de seno, alergias, infecciones del tracto urinario e infecciones del oído</li><li>tiempo medio de respuesta al médico de 10 minutos</li><li>Visitas para todas las edades—desde niños hasta personas mayores</li><li>médicos certificados por la junta de los EE. UU. con un promedio de 20 años de experiencia en la práctica</li><li>A petición, Teladoc puede compartir información de visita con su médico</li></ul>',
    faq: {
      items: [
        {
          title: '¿Hablo con médicos de verdad?',
          htmlContent: 'Sí. Hablará con médicos que son internistas certificados por la junta de los Estados Unidos, médicos de familia con licencia estatal y pediatras con licencia para ejercer la medicina en los Estados Unidos y que viven en los EE.UU. Cuando solicite una visita, Teladoc lo conectará con un médico con licencia en su estado.'
        },
        {
          title: '¿Puedo llamar a Teladoc fuera de los Estados Unidos?',
          htmlContent: 'No. Las visitas a Teladoc no están disponibles fuera de los Estados Unidos.'
        },
        {
          title: '¿Mi registro médico electrónico se mantiene en privado?',
          htmlContent: 'Teladoc emplea métodos de cifrado robustos para proteger su información personal. Usted determina quién puede ver la información en su record.'
        },
        {
          title: '¿Cómo se envían las recetas a la farmacia?',
          htmlContent: 'Teladoc no dispensa medicamentos recetados. Si el médico le receta medicamentos, se envía electrónicamente o por teléfono a su farmacia.'
        },
        {
          title: '¿Cuáles son algunas de las afecciones comunes que Teladoc trata?',
          htmlContent: 'Condiciones comunes incluyen problemas sinusales, infecciones respiratorias, alergias, infecciones del tracto urinario, síntomas de resfriados y gripe y muchas otras enfermedades que no son de emergencia.'
        },
        {
          title: '¿Puede Teladoc manejar mis situaciones de emergencia?',
          htmlContent: 'Teladoc está diseñado para manejar problemas médicos no emergentes. No debe usarlo si está experimentando una emergencia médica.'
        },
        {
          title: '¿Puedo solicitar un médico en particular?',
          htmlContent: 'No. Teladoc está diseñado para apoyar su relación con su médico existente. No es un medio para establecer una relación exclusiva con un médico de Teladoc. Por favor, sepa que todos los médicos de Teladoc están altamente calificados y pasan por una rigurosa capacitación y credencialización.'
        },
        {
          title: '¿Puedo obtener una receta?',
          htmlContent: 'Teladoc no garantiza recetas. Depende del médico recomendar el mejor tratamiento. Los médicos de Teladoc no emiten recetas para sustancias controladas por la DEA, no terapéuticas y/o ciertos otros medicamentos que pueden ser perjudiciales debido a su potencial de abuso. Estos incluyen, pero no se limitan a, medicamentos antidepresivos como Cymbalta, Prozac y Zoloft. Según los protocolos de tratamiento, los médicos no pueden recetar un antibiótico para enfermedades virales como la mayoría de los resfriados, dolor de garganta, tos, infecciones sinusales y gripe. Los médicos pueden sugerir opciones de tratamiento alternativas, como receta para aliviar los síntomas o medicamentos de venta libre. Además, Teladoc. no prescriben medicamentos no terapéuticos como Viagra y Cialis.'
        }
      ]
    }
  },
  vision: {
    title: 'Visión',
    description: 'Ahorre en anteojos, contactos, cirugía láser y exámenes.',
    descriptionDesktop: 'Ahorre en anteojos, contactos, cirugía láser y exámenes.',
    save_on: 'Ahorre en anteojos, contactos, cirugía láser y exámenes',
    benefits: [
      {
        eyewear: {
          title: 'Lentes y exámenes',
          save_on: 'Ahorre de 10% a 60% en anteojos y exámenes',
          information: '¡El cuidado de la vista puede ser caro! Usted y su familia tienen acceso a descuentos en miles de proveedores de visión incluyendo cadenas nacionales y locales.',
          htmlContent: '<ul><li>20% a 60% en anteojos con graduación incluyendo la mayoría de marcos, lentes y artículos especiales tales como tintes, recubrimientos y protección UV</li><li>10% al 30% de descuento en exámenes de la vista</li><li>Las cadenas participantes incluyen LensCrafters, Pearle Vision, Visionworks, JCPenney, Target y más</li><li>Programe su cita y presente su tarjeta de membresía con el logotipo de Coast To Coast Vision™</li><li>Llame de lunes a viernes, de 7am a 7pm CT y sábados, de 8am a 5pm para obtener ayuda a localizar proveedores.</li></ul>',
          membershipCard: {
            title: 'Tarjeta de beneficios de visión',
            subtitle: 'Para recibir su descuento enseñe esta tarjeta en la recepción del consultorio.'
          },
          find_a_provider: 'Encontrar proveedor',
          providers_found_for_your_location: 'proveedores encontrados en la ubicación seleccionada',
          save_your_favorite_providers: 'Guarde sus proveedores',
          faq: {
            items: [
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.'
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.'
              },
              {
                title: '¿Puedo usar el beneficio en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.'
              },
              {
                title: '¿Este es un seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.'
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.'
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.'
              },
              {
                title: '¿Cómo consigo que mi oftalmólogo entre en la red de Coast to Coast Vision?',
                htmlContent: 'Recomiende a su proveedor a través de la aplicación móvil, en línea o llamando al número que está en la parte posterior de su tarjeta y de el nombre, domicilio y número de teléfono del doctor. Coast to Coast Vision se pondrá en contacto para que se unan a la red.'
              },
              {
                title: '¿Cómo me garantizan los grandes ahorros en los lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% de su precio.'
              }
            ]
          }
        }
      },
      {
        order: {
          title: 'Lentes de contacto a domicilio',
          save_on: 'Ahorre de 10% a 40% en lentes de contacto ordenado',
          information: "Ahorre de 10% a 40% en lentes de contacto ordenados por correo de America’s Eyewear.",
          htmlContent: '<ul><li>Para pedir u obtener un presupuesto, llame de lunes a viernes, de 7:00 am a 7:00 pm CT y sábados, de 8:00 am a 5:00 pm CT</li><li>Correo electrónico: Mande una copia de su receta a americaseyewear@newbenefits.com o por fax al 972.503.5671</li></ul>',
          externalLinkTitle: 'Prescripción por correo Electrónico',
          faq: {
            items: [
              {
                title: '¿Cómo puedo garantizar me los mayores ahorros en lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% por debajo de retail.'
              },
              {
                title: '¿Cómo consigo que mi oftalmólogo entre en la red de Coast to Coast Vision?',
                htmlContent: 'Recomiende a su proveedor a través de la aplicación móvil, en línea o llamando al número que está en la parte posterior de su tarjeta y de el nombre, domicilio y número de teléfono del doctor. Coast to Coast Vision se pondrá en contacto para que se unan a la red.'
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.'
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.'
              },
              {
                title: '¿Puedo usar el beneficio en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.'
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.'
              },
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.'
              },
              {
                title: '¿Este es un seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.'
              }
            ]
          }
        }
      },
      {
        laser: {
          title: 'Cirugía láser',
          save_on: 'Ahorre de 40% a 50% en el costo promedio de la cirugía',
          information: 'Ahorre del 40% al 50% en el costo promedio de cirugía LASIK.',
          htmlContent: '<ul><li>Haga una cita con un proveedor de cirugía LASIK, presente su tarjeta de membresía con el logotipo de Coast to Coast Vision™ y pague el precio descontado</li><li>Llame de lunes a viernes de 7am a 8pm CT, sábados y domingos de 9am a 5pm para obtener ayuda en localizar un proveedor.</li></ul>',
          faq: {
            items: [
              {
                title: '¿Cómo me garantizan los grandes ahorros en los lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% de su precio.'
              },
              {
                title: '¿Es este seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.'
              },
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.'
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.'
              },
              {
                title: '¿Puedo usar el benéfico en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.'
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.'
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.'
              },
              {
                title: '¿Cómo hago para que mi oftalmólogo u óptico participen en la red de Coast To Coast Vision?',
                htmlContent: 'Comuníquese al número que se encuentra en el reverso de su tarjeta de membresía y proporcione el nombre, la dirección y el número telefónico de su médico, y Coast To Coast Vision se comunicará con él y le brindará información acerca de cómo convertirse en proveedor.'
              }
            ]
          }
        }
      }
    ]
  },
  rx_valet: {
    title: 'Farmacia',
    description: 'Ahorre desde 10% hasta 80% en medicamentos recetados.',
    descriptionDesktop: 'Ahorre 10% a 80% en medicamentos recetados.',
    save_on: 'Ahorre 10% a 80% en medicamentos recetados',
    information: 'Ahorre 10% a 80% en medicamentos recetados. ¡Simplemente busque su medicina y vea al instante un precio con descuento! Las recetas se pueden surtir en la farmacia de su elección. Descuentos disponibles en todas las farmacias minoristas. Para ahorros adicionales, la opción de ordenar por correo está disponible e incluye envío gratuito. ¿No puede pagar por sus medicamentos? Llámenos para ver que opciones tenemos disponibles para ayudarle.',
    htmlContent: '',
    membershipCard: {
      title: 'Tarjeta de beneficios de farmacia',
      subtitle: 'Solo para descuentos - ¡NO ES UN SEGURO MÉDICO! Para recibir su descuento enseñe esta tarjeta junto a su receta médica en la farmacia.',
      information: 'Los descuentos son válidos exclusivamente en farmacias participantes y varían dependiendo del tipo de receta médica y la farmacia a la que vaya.',
      more_information: 'Este programa de descuentos no envía pagos directos a las farmacias. Cada miembro debe pagar por el costo de los medicamentos. No se puede utilizar en combinación con un seguro médico. Puede contactar a nuestro Servicio al Cliente en cualquier momento para preguntas, cancelaciones u obtener más información. Este programa es administrado por la compañía Medical Security Card, LLC, de Tucson, AZ.'
    },
    externalLinkTitle: 'Compare precios'
  },
  mri: {
    title: 'Rayos X',
    description: 'Ahorros nacionales en resonancias magnéticas y tomografías.',
    descriptionDesktop: 'Una mejor imagen conduce a un major diagnóstico, tratamiento y recuperación. Ahorre entre 40% a 75% en imágenes y tomografías computarizadas en miles de centros de radiología acreditados.',
    save_on: 'Ahorros nacionales en resonancias magnéticas y tomografías.',
    information: 'Una mejor imagen conduce a un major diagnóstico, tratamiento y recuperación. Ahorre entre 40% a 75% en imágenes y tomografías computarizadas en miles de centros de radiología acreditados. Será referido a un radiólogo certificado en base su condición, preferencias y ubicación.',
    htmlContent: '<ul><li>Al llamar a One Call Medical, mencione el código GALAXY para obtener su descuento</li><li>Los representantes le ayudarán a seleccionar un proveedor en la red conveniente para usted</li><li>Una vez que se elija una ubicación, se programará su cita a través de una conferencia telefónica entre el representante, centro de imágenes y usted</li><li>Los representantes pueden responder preguntas sobre los imágenes o tomografías, que esperar durante su cita, direcciones a las instalaciones, etc.</li><li>Debe tener la orden de su medico antes de programar una cita</li></ul>',
    faq: {
      items: [
        {
          title: '¿Cómo pago?',
          htmlContent: 'Pagará al programar la cita. No se debe realizar ningún pago en su cita.'
        },
        {
          title: '¿Cómo utilizar este beneficio?',
          htmlContent: 'Llame de lunes a viernes, de 7am a 7:30 pm CT para encontrar una ubicación participante, solicitar una cita o para obtener información sobre precios. Utilice el código GALAXY para obtener su descuento.'
        }
      ]
    }
  },
  lab: {
    title: 'Análisis clínicos',
    description: 'Ahorros nacionales en servicios de laboratorio.',
    descriptionDesktop: 'Usted tendrá acceso directo a laboratorios clínicos donde podrá ahorrarse entre 10% a 80% en los análisis de rutina.',
    save_on: 'Ahorros nacionales en servicios de laboratorios',
    information: 'Usted tendrá acceso directo a laboratorios clínicos donde podrá ahorrarse entre 10% a 80% en los análisis de rutina.',
    htmlContent: '<ul><li>Las categorías de análisis incluyen alergias, vitaminas, niveles de colesterol, función renal, fertilidad, tiroides y más</li><li>Los resultados son confidenciales y están disponibles en 24 horas para la mayoría de las pruebas</li><li>Ordene por teléfono de lunes a viernes de 7am a 10pm CT, sábado, 8am a 12pm o en línea (proporcione el código: R-NEWB al hacer la orden)</li><li>Los beneficios de laboratorios no están disponibles en MD, NJ, NY y RI.</li></ul>',
    externalLinkTitle: 'Ordenar laboratorios',
    faq: {
      items: [
        {
          title: '¿Cual es el procedimiento de los análisis?',
          htmlContent:'Primero ordene su examen por Internet, a través del chat o por teléfono (lunes a viernes, de 7am a 10pm CT, sábados de 8am a 12pm). Si hace su pedido por teléfono proporcione el código RNEWB. Después imprima el formulario de solicitud y lléveselo cuando vaya a su extracción de sangre. El laboratorio no extraerá su sangre sin la forma*. Allí su sangre será extraída por un flebotomista certificado. Los resultados son recibidos por nuestra oficina generalmente dentro de 24 a 48 horas para la mayoría de los análisis y cargados a su cuenta. *Cuando vaya al laboratorio, solamente lleve el formulario de solicitud y su identificación con usted. Si lleva la solicitud a un laboratorio OTRO del proporcionado a través de este servicio, recibirá una facture de ese laboratorio y usted será responsable por pagar. No lleve OTROS formularios de solicitud. Si va a otro laboratorio que no participe en este programa, también será responsable de la factura que reciba de ellos.'
        },
        {
          title: 'Me siento saludable, así que ¿por qué debería hacerme el análisis?',
          htmlContent: 'Una condición médica grave como enfermedades cardíacas, cáncer de próstata o diabetes pueden existir sin síntomas hasta por dos años. La detección temprana es su mejor defensa. Un simple análisis de sangre puede aumentar las posibilidades de identificar posibles condiciones médicas y establecer una línea de base de sus rangos normales a partir de los cuales se pueden monitorear los análisis futuros.'
        },
        {
          title: '¿Esta prueba será pagada por el seguro?',
          htmlContent: 'Este servicio no somete reclamos a seguros. Algunos planes de seguro tienen un beneficio de bienestar o prevención incluido. El servicio de análisis puede proporcionarle códigos CPT para que pueda solicitar el reembolso usted mismo. No hay garantía de que su reclamación será reembolsada. Póngase en contacto con su proveedor de seguros para que obtenga sus beneficios y opciones de reembolso.'
        },
        {
          title: '¿Necesito ver a mi proveedor de atención médica para hacerme la prueba?',
          htmlContent: "El acceso directo de los análisis permite una mayor participación en la atención médica propia. Su doctor puede referir a un laboratorio participante, pero no es un requisito. Sin embargo, se le pedirá que proporcione el nombre específico de la prueba que desea ordenar. Los laboratorios no pueden hacer ninguna recomendación de análisis. Los laboratorios participantes son certificados por CLIA y regulados por agencias gubernamentales."
        },
        {
          title: '¿Qué es un centro de servicio al paciente?',
          htmlContent: 'Centros de Servicio al paciente son laboratorios donde los pacientes tienen sangre extraída. Estos centros están atendidos por flebotomistas certificados y son los mismos laboratorios referidos por los doctores.'
        },
        {
          title: '¿Necesito una cita?',
          htmlContent: 'Por lo general, no se requiere una cita en la mayoría de los Centros de Servicio al Paciente. Se recomienda llamar a la ubicación para confirmar sus horas de operación y que no se requiera una cita.'
        },
        {
          title: '¿Podré entender los resultados?',
          htmlContent: 'Todos los resultados de los análisis incluyen los rangos de referencia normales, con anomalías indicadas. Se recomienda que busque un doctor para hablar de los resultados fuera de los rangos normales. Para obtener ayuda en comprender major las pruebas y los resultados de laboratorio, visite  labtestsonline.org.'
        },
        {
          title: '¿Cuándo recibiré los resultados?',
          htmlContent: 'Vea las descripciones de los análisis para el plazo de entrega de cada análisis. La mayoría de los resultados están disponibles en 24 a 48 horas después de extraer la sangre. Recibirá un email cuando sus resultados se hayan cargado en su cuenta.'
        },
        {
          title: '¿Mi doctor recibirá una copia de mis resultados?',
          htmlContent: 'Su privacidad es respetada y permanecerá confidencial. Usted es el único que recibirá los resultados a menos que especifique lo contrario por escrito. Puede pedir que se envié una copia de sus resultados a su doctor firmando el formulario de liberación de HIPPA que nos da autorización para hacerlo.'
        }
      ]
    }
  },
  hearing: {
    title: 'Ayuda auditiva',
    description: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
    descriptionDesktop: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
    save_on: 'Encuentre su solución auditiva ideal en las tiendas minoristas',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    benefits: [
      {
        retail: {
          title: 'Atención auditiva',
          save_on: 'Encuentre su solución auditiva ideal en tiendas',
          information: 'Deje que Amplifon le ayude a encontrar la solución de audífono adecuada que se adapte a su estilo de vida a través de un servicio personalizado y productos excepcionales para cada presupuesto.',
          htmlContent: '<ul><li>Ahorro promedio del 64% sobre el precio minorista sugerido por el fabricante para audífonos</li><li>Un año de atención de seguimiento gratuita para limpiezas y chequeos con la compra</li><li>Suministro gratuito de baterías desechables para dos años o una estación de carga para mantener a los miembros con energía</li><li>Garantía de tres años por pérdida, reparación o daño</li><li>Garantía de devolución del 100% del dinero durante la prueba sin riesgo de 60 días de los miembros</li><li>Ofreciendo fabricantes líderes como Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex y Unitron.</li></ul>',
          faq: {
            items: [
              {
                title: '¿Cómo encuentro proveedores participantes para el cuidado auditivo minorista de Amplifon?',
                htmlContent: "Busque proveedores cerca de usted a través de la aplicación o en línea o llamando de lunes a viernes, de 7:00 a. M. A 7:00 p. M., Hora central."
              },
              {
                title: '¿Cómo funciona el programa?',
                htmlContent: 'Cuando llame, un defensor le explicará el proceso de Amplifon, solicitará su información de correo y lo ayudará a programar una cita con un proveedor de atención auditiva. Amplifon le enviará información a usted y al audioprotesista. Esto asegurará que su programa Amplifon esté activado.'
              },
              {
                title: '¿Cuántas veces al año puedo usar mi descuento auditivo?',
                htmlContent: 'No hay límite en el número de veces que puede utilizar su tarjeta de descuento para cualquier beneficio.'
              },
              {
                title: '¿El descuento de atención auditiva minorista es el mismo en todas las ubicaciones participantes?',
                htmlContent: 'Sí, tenemos una red nacional de más de 5,100 proveedores de atención médica auditiva.'
              },
              {
                title: '¿Ofrece más de una marca de audífonos?',
                htmlContent: 'Si. Ofrecemos más de 2000 modelos de audífonos de fabricantes líderes como Oticon, Phonak y Starkey.'
              }
            ]
          }
        }
      },
      {
        home: {
          title: 'Audífonos a domicilio',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Ahorre en soluciones auditivas',
          information: 'Hearing Assist, la marca número uno de audífonos directos al consumidor, brinda acceso y asequibilidad a la industria de los audífonos con soluciones diseñadas para amplificar las frecuencias más comúnmente asociadas con la pérdida auditiva leve a moderada, enviadas directamente a su hogar.',
          htmlContent: "<ul><li>Descuento de $100 de descuento en el precio más bajo anunciado por par de audífonos en los principales minoristas o $50 de descuento en un solo dispositivo*</li><li>Salve un 25% de descuento en cualquier accesorio auditivo**</li><li>Mejoy una tarifa plana de $99 por año para el plan de protección Peace of Mind**</li><li>Incluye una garantía de devolución de dinero de 60 días</li><li>El envío es gratis</li><li>No se necesita receta médica ni un examen costoso para comprar su solución auditiva</li><li>Sujeto a cambios  **El descuento solo se aplica cuando se compra un audífono</li></ul>",
          externalLinkTitle: 'Comprar audífonos',
          faq: {
            items: [
              {
                title: '¿Cómo funciona el programa?',
                htmlContent: "Llame o visite el sitio web para encontrar la solución de audífono adecuada para usted y pague el precio con descuento al momento de pagar. Sus soluciones auditivas se entregarán directamente a su hogar con envío gratuito."
              },
              {
                title: '¿Cómo sé si los audífonos de entrega a domicilio me quedarán bien?',
                htmlContent: 'Los audífonos Hearing Assist son de talla única. Algunos modelos funcionan con una aplicación descargable y pueden ajustarse automáticamente a sus necesidades individuales. Si bien nuestros audífonos están diseñados para funcionar para la mayoría, entendemos que no funcionarán para todos, por lo que le damos 60 días para probarlos, sin riesgos.'
              },
              {
                title: '¿Puedo usar mi HSA para comprar audífonos?',
                htmlContent: '¡Sí! Los audífonos Hearing Assist son una compra calificada a través de Cuentas de ahorros para gastos médicos y gastos flexibles.'
              },
              {
                title: '¿El descuento solo funciona en la compra inicial?',
                htmlContent: '¡No! Puede aplicar el descuento a cada compra de dispositivo, no solo a la primera.'
              },
              {
                title: '¿A quién llamo si necesito ayuda con mis audífonos?',
                htmlContent: 'Puede comunicarse con el servicio de atención al cliente de EE. UU. Al 800.280.4050, de lunes a sábado, de 7:00 a.m. a 10:00 p.m., Hora central, y domingos, de 8:00 a.m. a 5:00 p.m., Hora central.'
              }
            ]
          }
        }
      }
    ]
  },
  diabetic: {
    title: 'Diabetes',
    description: 'Suministros para diabéticos con descuento a domicilio.',
    descriptionDesktop: '¡Obtenga un medidor de glucosa en la sangre gratis con su primer pedido y ahorre 10% - 50% en todos los suministros diabéticos!',
    save_on: 'Suministros para diabéticos con descuento enviados',
    information: '¡Obtenga un medidor de glucosa en la sangre gratis con su primer pedido y ahorre 10% - 50% en todos los suministros diabéticos! Con el conveniente programa prepagado en línea, sus suministros serán enviados directamente a su casa. Los servicios incluyen:',
    htmlContent: '<ul><li>Envío gratuito con actualizaciones de información por email o texto</li><li>Programa de pruebas anual o opciones de compra según sean necesarias</li><li>No se requiere receta médica para los suministros de pruebas OTC</li><li>Garantía de satisfacción 100%, si no esta contento, le regresaremos el dinero</li><li>Para activar su cuenta, ingrese su ID de grupo que se encuentra en frente de su tarjeta. Recibirá un email para confirmar su dirección de correo electrónico y completar el registro.</li></ul>',
    externalLinkTitle: 'Compre y ordene',
    faq: {
      items: [
        {
          title: '¿Se requiere una receta médica?',
          htmlContent: 'No, no se requiere receta médica para los suministros para diabéticos OTC.'
        },
        {
          title: '¿Qué es el Programa Anual de Pruebas?',
          htmlContent: 'Al inscribirse en el Programa Anual de Pruebas le otorga mayores ahorros en su suministro para la diabetes. Escoja su programa con base en las veces que usted usa los suministros. Usted también puede comprarlos como sea necesario.'
        },
        {
          title: '¿Puedo pedir suministros por teléfono?',
          htmlContent: 'Sí, llame a Rx Valet de lunes a viernes de 7:00 am a 7:00 pm o sábados de 8:00 am a 1:00 pm.'
        }
      ]
    }
  },
  travel: {
    title: 'Turismo descontado',
    description: 'Ahorre hasta el 70% en hoteles.',
    descriptionDesktop: 'Affinity Travel es una plataforma de viajes y entretenimiento al mayoreo que ofrece precios inéditos y descuentos exclusivos hasta el 70% en más de 700,000 hoteles.',
    save_on: 'Ahorre hasta el 70% en hoteles',
    information: 'Affinity Travel es una plataforma de viajes y entretenimiento al mayoreo que ofrece precios inéditos y descuentos exclusivos hasta el 70% en más de 700,000 hoteles. Garantizan los precios más bajos que cualquier otra agencia de viajes en línea.',
    htmlContent: ''
  }
};

export default benefits;
