import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { CloseButton, Navbar, Nav } from 'react-bootstrap';
import ProfileModal from '@/components/ProfileModal';
import * as env from '@/env';
import { useTranslation } from '@/hooks/translation';
import { useCurrentUser } from '@/hooks/user';
import {
  Articles,
  Chat,
  Earn,
  Home,
  PODERcard,
  Premium,
  FarmaSep,
  Profile,
  JobsNetwork
} from '@/icons';
import { isFeatureEnabled } from '@/utilities/features';
import LoginModal from '@separate/components/LoginModal';
import { HAMBURGER_MENU_CLICKED } from '@separate/constants/analytics';
import { logEvent } from "@separate/utilities/analytics";
import styles from './menu.module.scss';

export default function Menu(props) {
  const { show, onHide } = props;
  const { user, isLoggedIn } = useCurrentUser();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();
  const hideProfileModal = () => setShowProfileModal(false);
  const hideLoginModal = () => setShowLoginModal(false);
  const isPremiumMember = user?.is_premium_member;
  const isBenefitsCenterEnabled = isFeatureEnabled('premium_benefits_center');
  const isJobsSiteNavigationEnabled = isFeatureEnabled('allow_jobs_site_navigation_button');

  const menuEventProperties = {
    "menu.earn": "rewards",
    "menu.chat": "chat",
    "menu.articles": "articles",
    "menu.premium": "podersalud",
    "menu.digital_banking": "digital_banking",
    "menu.farmaSEP": "farmasep",
    "menu.jobs_network": "jobs_network",
    "menu.profile": "your_account"
  };

  const items = [
    {
      text: "menu.home",
      href: "/",
      component: Home,
    },
    {
      text: "menu.earn",
      href: "/earn",
      component: Earn,
      style: { paddingLeft: 2 },
    },
    {
      text: "menu.chat",
      href: "/qa",
      component: Chat,
    },
    {
      text: "menu.articles",
      href: "/articles",
      component: Articles,
    },
    {
      text: "menu.premium",
      href: isPremiumMember && isBenefitsCenterEnabled ? "/benefits" : "/premium",
      component: Premium,
      style: { paddingLeft: 2 },
    },
    {
      text: "menu.digital_banking",
      href: user?.is_podercard_user ? env.PODERCARD_LINK : "/podercard",
      style: { paddingLeft: 2 },
      component: PODERcard
    },
    // Hide the FarmaSep page until the third-party API is fixed
    // {
    //   text: "menu.farmaSEP",
    //   href: "/farmasep",
    //   component: FarmaSep
    // },
    {
      text: "menu.profile",
      href: "#",
      onClick: () => {
        if (isLoggedIn) {
          setShowProfileModal(true);
        } else {
          setShowLoginModal(true);
        }
      },
      component: Profile
    }
  ];

  const jobsRoute = {
    text: "menu.jobs_network",
    href: "/jobs",
    style: { paddingLeft: 2 },
    component: JobsNetwork,
  };

  const fullItems = isJobsSiteNavigationEnabled ? [...items.slice(0, 6), jobsRoute, ...items.slice(6)] : items;

  function handleRouting(menuOnClick, text) {
    logHamburgerMenuClicked(text);
    onHide();
    if (menuOnClick) {
      menuOnClick();
    }
  }

  const logHamburgerMenuClicked = (name) => {
    if(!menuEventProperties[name]) return;

    logEvent(HAMBURGER_MENU_CLICKED, { destination: menuEventProperties[name] });
  };

  return (
    <>
      {show && (
        <div className={styles.navbarContainer} onClick={onHide}>
          <Navbar className={styles.navbar} onClick={e => e.stopPropagation()}>
            <header className={`${styles.navbarHeader} px-4 py-5`}>
              <Navbar.Brand className={styles.headerTitle}></Navbar.Brand>
              <CloseButton className={styles.closeButton} onClick={onHide} />
            </header>
            <div className={styles.navbarBody}>
              <Nav className={styles.menuLinks}>
                {fullItems.map(item => (
                  <Link key={item.text} href={item.href}>
                    <a className={styles.menuLinkItems} onClick={() => handleRouting(item.onClick, item.text)}>
                      <item.component className={styles.menuLinksIcon} style={item.style} />
                      {t(item.text)}
                    </a>
                  </Link>
                ))}
              </Nav>
            </div>
          </Navbar>
        </div>
      )}
      <ProfileModal show={showProfileModal} onHide={hideProfileModal} />
      <LoginModal show={showLoginModal} onHide={hideLoginModal} />
    </>
  );
}

Menu.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired
};
