import React from 'react';
import { Amex, ApplePay, GooglePay, Mastercard, PODERcardGradient, Visa } from '@separate/icons';
import styles from './paymentMethodInfoCard.module.scss';

const defaultCardIcon = () => {
  return (
    <div className={styles.defaultIcon}>
      <PODERcardGradient />
    </div>
  );
};

const brandMapping = {
  amex: {
    name: 'American Express',
    icon: Amex,
  },
  amex_express_checkout: {
    name: 'Amex Express Checkout',
    icon: Amex,
  },
  apple_pay: {
    name: 'Apple Pay',
    icon: ApplePay,
  },
  diners: {
    name: 'Diners Club',
    icon: defaultCardIcon,
  },
  discover: {
    name: 'Discover',
    icon: defaultCardIcon,
  },
  google_pay: {
    name: 'Google Pay',
    icon: GooglePay,
  },
  jcb: {
    name: 'JCB',
    icon: defaultCardIcon,
  },
  mastercard: {
    name: 'Mastercard',
    icon: Mastercard,
  },
  masterpass: {
    name: 'Masterpass',
    icon: Mastercard,
  },
  samsung_pay: {
    name: 'Samsung Pay',
    icon: defaultCardIcon,
  },
  unionpay: {
    name: 'UnionPay',
    icon: defaultCardIcon,
  },
  visa: {
    name: 'Visa',
    icon: Visa,
  },
  visa_checkout: {
    name: 'Visa Checkout',
    icon: Visa,
  },
  unknown: {
    name: 'Card',
    icon: defaultCardIcon,
  }
};

export { brandMapping as default };
