const podercard = {
  main: {
    title: "Digital banking for the latino community",
    subtitle: "Inclusive, supportive and fair",
    cta: "Get started",
    ctaMember: "Take me to the app"
  },
  about: {
    items: [
      {
        title: "Banking that cares",
        text: "We listened to our community members and we heard your needs. That’s why we built a digital bank that makes banking simple, fair and attainable for as many people as possible."
      },
      {
        title: "Why do 1 million people love PODERcard?",
        text: "PODERcard is no nonsense banking. You can apply no matter your migration status. We don’t charge monthly fees², regardless of your average balance and your account is FDIC insured."
      },
      {
        title: "Everyone is welcome!",
        text: "PODERcard was built with the Latino community in mind, but it’s available to everyone, from all walks of life."
      }
    ]
  },
  information: {
    title: "Stand out <span>with PODERcard</span>",
    items: [
      {
        content: "<strong>Banking made easy.</strong> Sign up in minutes and enjoy total transparency on all your transactions",
        key: "banking"
      },
      {
        content: "<strong>No one left behind.</strong> You can apply using your Matricula Consular, ITIN or SSN. Your migration status doesn’t matter, you matter!",
        key: "group"
      },
      {
        content: "<strong>Security and support.</strong> We protect your personal and financial information and all your funds are FDIC insured. We offer bilingual customer service that can support you no matter your needs.",
        key: "security"
      },
      {
        content: "<strong>Get your funds faster.</strong> Get your paycheck up to 2 days early with ASAP Direct Deposit™³ and access your funds at 19,000 fee-free in-network ATMs.³",
        key: "fast"
      },
      {
        content: "<strong>Make your money grow.</strong> You can earn cash with our rewards program and instantly access your earnings with PODERcard.¹",
        key: "money"
      }
    ]
  },
  testimonials: {
    title: "Testimonials",
    items: [
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltce11db396494a883/623e0c8f549d685f55b5ace8/leticia.png",
        name: "Leticia L.",
        location: "Los Angeles, CA",
        text: "Many of the banks cannot… or rather, they don’t really help you with opening an account and store your money. PODERcard does, they make it very easy.",
      },
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltfa8294a477e6787e/623e0c8f159fc76504c18355/marco.png",
        name: "Marco V.",
        location: "Chicago, IL",
        text: "I’ve participated in a number of surveys, 15-20 of them, and I’ve earned around $300-$400 and that’s been more accessible with the PODERcard.",
      },
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltf6582111a1580cb4/623e0c8f7c24b44cb0bf4988/jose.png",
        name: "Jose M.",
        location: "Miami, FL",
        text: "Thanks to PODERcard I can transfer money for free to my wife and family back home at no additional charge.",
      }
    ]
  },
  faq: {
    title: "FAQ",
    items: [
      {
        title: "What is a digital bank?",
        htmlContent: "Digital banks, also called mobile banks or online banks, offer a digital-only bank account that you can access over a computer or a mobile app. They typically do not have physical branches. This doesn't impact the quality of customer experience, in fact it's much the opposite, as this type of bank is more cost-efficient, which means the user gets a better deal. This is why PODERcard is able to offer great features with no monthly fees and no minimum balance.² "
      },
      {
        title: "Is it safe?",
        htmlContent: "Our goal was to make banking more accessible, trustworthy and easy to use. PODERcard meets banking security standards in order to protect your personal and financial information, and you’ll always have real-time notifications of any activity on your account. If you need to lock your card for any reason, you can do so in moments from the app."
      },
      {
        title: "What do I do if I need help?",
        htmlContent: "You can easily get in touch with our bilingual customer service team at any stage of your banking journey. Your satisfaction and security are our #1 priority."
      }
    ]
  },
  links : {
    accountAgreement: "Deposit Account Agreement",
    greenDotBankPrivacyPolicy: "Green Dot Bank Privacy Policy",
    electronicCommunicationsAgreement: "Electronic Communications Agreement",
    greenDotTechnologyPrivacyStatement: "Technology Privacy Statement",
    termsAndConditions: "Terms and Conditions",
    termsOfUseAgreement: "Terms of Use Agreement",
    welcomeTechPrivacyPolicy: "Welcome Tech Privacy Policy",
  },
  descriptions:{
    otherOffers: "¹Other SaberEsPoder products, services, and discounts are not provided by Green Dot.",
    deposit: "²See Deposit Account Agreement for fees and additional details.",
    directDeposit: "³ASAP Direct Deposit™ - Early availability depends on timing of payor’s payment instructions and fraud prevention restrictions may apply. As such, the availability or timing of early direct deposit may vary from pay period to pay period.",
    freeATM: "⁴Fee-free ATM access applies to in-network ATMs only.",
    disclosure: "PODERcard account opening is subject to identity verification by Green Dot Bank. Banking services provided by and PODERcard Visa® Debit Card is issued by Green Dot Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc. Visa is a registered trademark of Visa International Service Association. Green Dot Bank operates under the following registered trade names: GoBank, Green Dot Bank and Bonneville Bank. All of these registered trade names are used by, and refer to, a single FDIC-insured bank, Green Dot Bank. Deposits under any of these trade names are deposits with Green Dot Bank and are aggregated for deposit insurance coverage. Green Dot is a registered trademark of Green Dot Corporation ©2020 Green Dot Corporation. All rights reserved.",
  }
};

export default podercard;
