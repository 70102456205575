import React from 'react';
import { useAddressValidate } from '@/hooks/address';

// In admin the name of the endpoint is "validate", but here in sep we use "validation" to mean input
// form validation.  To avoid overloading the term, everything here gets remapped to "verification"
// to distinguish verifying an address with USPS from validating that the input fields are supplied and
// in the correct format.
export default function useAddressVerification(props) {
  const { onAddressVerified, onReplaceAddress } = props;

  const [correctedAddress, setCorrectedAddress] = React.useState({});
  const [showCorrectionModal, setShowCorrectionModal] = React.useState(false);
  const [showFailureModal, setShowFailureModal] = React.useState(false);

  function onSuccess(data) {
    const { validity } = data;

    if (validity === 'valid') {
      onAddressVerified();
    } else if (validity === 'correctable') {
      setCorrectedAddress(data.address);
      setShowCorrectionModal(true);
    } else {
      setShowFailureModal(true);
    }
  }

  function onDismiss() {
    setShowCorrectionModal(false);
    setShowFailureModal(false);
  }

  function onAcceptCorrection() {
    setShowCorrectionModal(false);
    onReplaceAddress(correctedAddress);
  }

  const { isLoading, validateAddress: verifyAddress } = useAddressValidate({ onSuccess });

  return {
    isLoading,
    verifyAddress,
    addressVerificationState: {
      correctedAddress,
      showCorrectionModal,
      showFailureModal,
      onDismiss,
      onAcceptCorrection,
    }
  };
}
