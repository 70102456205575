const creditcard = {
  header: 'Coming soon!',
  hero: {
    credit: 'Credit',
    subtitle: 'The credit card designed with you in mind',
    description: 'Access credit with no annual fees. The protection and purchasing power that you deserve.',
    cta: 'Get started',
  },
  card_intro: {
    title: 'Let’s start the journey together:',
    description: [
      {
        icon: 'fee',
        text: 'Set up flexible payments with no annual fees',
      },
      {
        icon: 'cardEvolve',
        text: 'Take control of your credit score journey',
      },
      {
        icon: 'mastercard',
        text: 'Use your card everywhere Mastercard is accepted',
      },
      {
        icon: 'creditCard',
        text: 'Make purchases online, mobile, or in-person',
      }
    ],
  },
  products: {
    title: 'Choose what works best for you:',
    credit_limit: 'Credit limit up to',
    deposit: 'Security deposit',
    purchase: 'Purchase APR:',
    creditcard_type: [
      {
        credit_history: 'If you have a fair or good credit history',
        type: 'PODERcard Credit',
        limit: '$15,000',
        deposit: 'No',
        purchase_apr: '0.00 - 3.99% 6 months intro, 16.99% - 29.99% after',
        cta: 'Apply',
        href: 'https://link.podercard.com/ToT2YHmIm5&utm_campaign=cc-landing-cta&utm_source=sepv3&utm_content=unsecured-cta',
        terms: 'View Terms & Conditions',
        terms_href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltbb69a8aee4f17525/63658dee607d0e6f636b3ed6/10.28.2022_Unsecured_PODERcard_Application_Disclosures.docx.pdf',
      },
      {
        credit_history: 'If you have limited or no credit history',
        type: 'PODERcard Credit Secured',
        limit: '$3,500',
        deposit: 'Minimum $300<sup>1</sup>',
        purchase_apr: '18.99%',
        cta: 'Apply',
        href: 'https://link.podercard.com/ToT2YHmIm5&utm_campaign=cc-landing-cta&utm_source=sepv3&utm_content=secured-cta',
        terms: 'View Terms & Conditions',
        terms_href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt66f1d79e3438e954/63658e558a745d1056e8cee3/7.1.2022_Secured_PODERcard_Application_Disclosure.docx.pdf',
      }
    ],
  },
  benefits: [
    {
      title: 'No SSN required to apply',
      subtitle: 'No Social Security Number? No problem! You can apply with the Individual Taxpayer Identification Number (ITIN) or Mexican Matricula Consular Card. Once approved you may build your credit history and obtain a score!<sup>2</sup>',
      icon: 'creditCard',
    },
    {
      title: 'Bilingual Customer Service',
      subtitle: 'We have your back! Our in-depth Help Center and customer service specialists are available to assist you, in both English and Spanish.',
      icon: 'customerService',
    },
    {
      title: 'No annual fees and flexible payment',
      subtitle: 'There are NO annual maintenance fees. You’ll just need to make on-time monthly payments; which can be the minimum payment, the balance in full or anything in between.',
      icon: 'fee',
    },
    {
      title: 'A credit card that evolves with you',
      subtitle: 'No matter where you are in your credit journey, we’ve got you covered. Our credit card evolves with you — if you start with <span>PODERcard Credit Secured</span> you may be eligible to upgrade to the <span>PODERcard Credit</span> (no security deposit) 12 months later or sooner.<sup>3</sup>',
      icon: 'evolve',
    },
    {
      title: 'Earn Rewards to help pay off your balance',
      subtitle: 'You have access to the SABEResPODER Rewards Center, where you can earn extra income and use that to help pay your balance<sup>4</sup>. This is a unique way to ensure you never miss a minimum payment — one of the most effective ways to improve your credit score.',
      icon: 'trophy',
    },
    {
      title: 'You’re in control with the PODERcard app',
      subtitle: 'Easily manage your account 24/7 right from our mobile app — from checking your transactions to making payments or managing your personal information. It’s all at your fingertips!',
      icon: 'cardAndPhone',
    },
    {
      title: 'Access tools to build your credit',
      subtitle: 'We want you to feel informed and empowered as you discover new ways to leverage credit. That’s why we offer free access to resources on building your credit score, how to best use your credit card, and much more!<sup>5</sup>',
      icon: 'shield',
    },
  ],
  banner_one: {
    title: 'Your path to accessing credit starts here!',
    cta: 'Apply for PODERcard Credit',
  },
  banner_two: {
    title: 'Apply to PODERcard Credit',
    cta: 'I’m ready to get started',
  },
  mastercard: {
    header_title: 'Mastercard benefits',
    header_subtitle: 'Use your <span>PODERcard Credit</span> wherever Mastercard is accepted and access all these perks:',
    description: [
      {
        title: 'Mastercard Global Service',
        subtitle: 'Worldwide, 24-hour assistance with lost and stolen card replacement.',
      },
      {
        title: 'Mastercard ID Theft Protection<sup>TM</sup>',
        subtitle: 'You\'re covered if you fall victim to identity theft with access to resolution services.',
      },
      {
        title: 'Travel Assistance',
        subtitle: 'Access to information on travel requirements, medical and legal services, and roadside assistance.',
      },
      {
        title: 'Mastercard Airport Concierge<sup>TM</sup>',
        subtitle: 'Save 15% on airport meet and greet services.',
      },
    ],
  },
  faq: {
    title: 'FAQ',
    items: [
      {
        title: 'How do I open an account with PODERcard Credit?',
        htmlContent: 'You need to first download the PODERcard app on your phone to get started. Once you\'ve created your account, then follow the application instructions - it’s quick and easy, you’ll be done in less than 10 minutes!',
      },
      {
        title: 'What do I need to apply?',
        htmlContent: 'You\'ll need to log into the PODERcard app and provide the following details to apply: <ul><li>A valid form of government ID: SSN, Mexican Matricula Consular Card or Individual Taxpayer Identification Number (ITIN).</li><li>Full name, date of birth, employer information and additional personal details.</li><li>US address (PODERcard Credit is not available to residents of New York, Maryland, or Wisconsin).</li></ul>'
      },
      {
        title: 'How can I build credit with a PODERcard Credit Secured card?',
        htmlContent: 'The PODERcard Credit Secured is a credit card designed specifically for people who are new to credit or want to build their credit as they go. This option may help you achieve this goal.<sup>2</sup> If you use your card and pay at least the minimum due on a timely basis each month, you’ll benefit from: <ul><li>Having your credit history reported to the major credit bureaus (TransUnion and Equifax).</li><li>Accessing our tools to help keep you on track, including the Autopay option and free guides on how to use your credit card responsibly.<sup>5</sup></li><li>Account protection features: Mastercard ID theft protection and $0 liability on unauthorized charges.</li></ul>'
      },
      {
        title: 'What’s the difference between a secured credit card and a regular one?',
        htmlContent: '<p>The main difference is that with a <span>PODERcard Credit Secured</span> you pay a security deposit upfront to guarantee your credit line. The amount you deposit (starting at $300)<sup>1</sup> determines your credit limit up to $3,500, based on your creditworthiness and other factors. It’s the best option if you have limited or no credit history.</p> <p>On the other hand, the <span>PODERcard Credit</span> doesn’t require a security deposit and you can access up to $15,000, based on creditworthiness and other factors. This credit card is ideal if you already have a fair credit history.</p>'
      }
    ]
  },
  about: {
    title: 'About us',
    subtitle: 'At SABEResPODER we’re dedicated to uplifting and empowering the community by offering resources that help them succeed. From financial inclusion to healthcare and beyond, we’re committed to helping you and your family thrive.',
  },
  footer: {
    footnotes: [
      {
        text: '<sup>1</sup>The security deposit is not used for the payments on the PODERcard Credit Secured, it works as a guarantee.',
      },
      {
        text: '<sup>2</sup>Responsible use of your credit card, including making payments on time each month, may help build your credit score. Results will vary.',
      },
      {
        text: '<sup>3</sup>You may be eligible to upgrade to the PODERcard Credit (no security deposit required) within 12 months based on payment history, creditworthiness and other factors.'
      },
      {
        text: '<sup>4</sup>The SABEResPODER Rewards program is not associated with MRV Banks.',
      },
      {
        text: '<sup>5</sup>These resources are offered by SABEResPODER and are not asociated with MRV Banks.',
      },
    ],
    issued_by: 'The PODERcard Credit is issued by MRV Banks pursuant to a license from Mastercard International<sup>TM</sup>.',
    disclosures_and_agreements: [
      {
        title: 'Application Disclosures PODERcard Credit',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltbb69a8aee4f17525/63658dee607d0e6f636b3ed6/10.28.2022_Unsecured_PODERcard_Application_Disclosures.docx.pdf',
      },
      {
        title: 'Application Disclosures PODERcard Credit Secured',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt66f1d79e3438e954/63658e558a745d1056e8cee3/7.1.2022_Secured_PODERcard_Application_Disclosure.docx.pdf',
      },
      {
        title: 'E-Sign Disclosure and Consent Agreement',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt533efa9e8aa5ce74/62c60ab48437a5376761f3a9/11.16.2021_E-SIGN_Disclosure_&_Consent_Agreement.pdf',
      },
      {
        title: 'Terms of Use Agreement',
        href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt948c9a0e98577f24/10.12.2022_Terms_of_Use_Agreement.docx.pdf',
      },
    ],
    mrv_banks_pivacy_policy: {
      title: 'MRV Banks Privacy Policy',
      href: 'https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/bltfe20d542a05609bd/637ba4ad71c75510a0ca1854/MRV_Privacy_Policy_WT_Program_11.12.21_v5.pdf',
    },
    welcome_tech_privacy_policy: {
      title: 'Welcome Tech Privacy Policy',
      href: 'https://saberespoder.com/legal/3.3.3022_WT_Privacy_Policy',
    },
    contact_us: 'Contact us',
  }
};

export default creditcard;
