import React from 'react';
import PropTypes from 'prop-types';
import {
  ACCOUNT_CANCELLATION_VIEW,
  EDIT_BASIC_INFO_VIEW,
  EDIT_DEPENDENTS_VIEW,
  EDIT_PAYMENT_METHOD_VIEW,
  EDIT_SMS_PREFERENCES_VIEW,
  MAIN_VIEW,
  PAYMENT_STATUS_CHECKER,
  PAY_PAST_DUE_VIEW
} from '@/constants/profile';
import { useGetMissedPayment } from '@/hooks/premium';
import { useTranslation } from '@/hooks/translation';
import { useCurrentUser } from '@/hooks/user';
import Button from '@separate/components/Button';
import EditPaymentMethod from '@separate/components/EditPaymentMethod';
import styles from '@separate/components/EditPaymentMethod/editPaymentMethod.module.scss';
import PayPastDue from '@separate/components/PayPastDue';
import { PROFILE_UPDATED } from '@separate/constants/analytics';
import { ALLOW_MULTIPLE_PAYMENT_METHODS, ALLOW_NEW_MISSED_PAYMENT_FLOW } from '@separate/constants/features';
import {
  useEditPaymentMethodFetch,
  usePastDuePaymentFetch,
  useResetSubscriptionQuery,
  useUpdatePaymentMethod
} from '@separate/hooks/premium';
import { logEvent } from '@separate/utilities/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import EditBasicInfo from './EditViews/EditBasicInfo';
import PoderCardAlertModal from './PoderCardAlertModal';
import AccountCancellation from './steps/AccountCancellation';
import AccountInfo from './steps/AccountInfo';
import EditDependents from './steps/EditDependents';
import Main from './steps/Main';
import PaymentStatusChecker from './steps/PaymentStatusChecker';
import SMSPreferences from './steps/SMSPreferences';

export default function Profile(props) {
  const { onDismiss, showPayment } = props;

  const [view, setView] = React.useState(MAIN_VIEW);
  const [showPodercardAlert, setShowPodercardAlert] = React.useState(false);

  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const allowAddDependents = isFeatureEnabled('allow_dependents');

  const { userHasMissedPayment } = useGetMissedPayment();

  function backToMain() {
    setView(MAIN_VIEW);
  }

  function handleEditBasic() {
    if (user.is_podercard_user) {
      setShowPodercardAlert(true);
    } else {
      setView(EDIT_BASIC_INFO_VIEW);
    }
  }

  function onUpdateBasicInfoSuccess() {
    logEvent(PROFILE_UPDATED, { action: 'personal_information_udpated' });
    backToMain();
  }

  function onUpdatePaymentInfoSuccess() {
    logEvent(PROFILE_UPDATED, { action: 'payment_information_updated' });
    onDismiss();
  }

  React.useEffect(() => {
    if (!showPayment) return null;

    setView(PAYMENT_STATUS_CHECKER);

    return () => {
      setView(MAIN_VIEW);
    };
  }, [showPayment]);

  const ref = React.createRef();

  return (
    <div ref={ref}>
      {view === PAYMENT_STATUS_CHECKER && (
        <PaymentStatusChecker
          onPayPastDue={() => setView(PAY_PAST_DUE_VIEW)}
          onNoPaymentInfo={() => setView(MAIN_VIEW)}
          onEditPaymentMethod={() => setView(EDIT_PAYMENT_METHOD_VIEW)}
        />
      )}

      {view === MAIN_VIEW && (
        <Main
          onEdit={handleEditBasic}
          onDismiss={onDismiss}
          onPayPastDue={() => setView(PAY_PAST_DUE_VIEW)}
          onEditPaymentMethod={() => setView(EDIT_PAYMENT_METHOD_VIEW)}
          onEditSMSPreferences={() => setView(EDIT_SMS_PREFERENCES_VIEW)}
          onEditDependents={() => setView(EDIT_DEPENDENTS_VIEW)}
        />
      )}

      {view === EDIT_BASIC_INFO_VIEW && (
        <EditBasicInfo onUpdateBasicInfoSuccess={onUpdateBasicInfoSuccess} onCancel={backToMain} />
      )}

      {(view === EDIT_DEPENDENTS_VIEW && allowAddDependents) && (
        <EditDependents
          backToMain={backToMain}
        />
      )}

      {view === PAY_PAST_DUE_VIEW && (
        <>
          {isFeatureEnabled(ALLOW_NEW_MISSED_PAYMENT_FLOW) ? (
            <AccountInfo
              onCancel={backToMain}
              onDismiss={onDismiss}
              onSuccess={onUpdatePaymentInfoSuccess}
              missedPayment={userHasMissedPayment}
            />
          ) : (
            <>
              <div className='d-flex w-100 justify-content-between'>
                <h4 className={styles.ctaTitle}> {t("user.profile.payment_cta_title")} </h4>
                <Button onClick={backToMain} className={styles.editCta}> {t("user.profile.cancel_edit_cta")} </Button>
              </div>

              <PayPastDue
                onSuccess={onUpdatePaymentInfoSuccess}
                fetchPastDuePaymentHook={usePastDuePaymentFetch}
                resetSubscriptionQueryHook={useResetSubscriptionQuery}
                updatePaymentMethodHook={useUpdatePaymentMethod}
              />
            </>
          )}
        </>
      )}

      {view === EDIT_PAYMENT_METHOD_VIEW && (
        <>
          {isFeatureEnabled(ALLOW_MULTIPLE_PAYMENT_METHODS) ? (
            <AccountInfo onDismiss={onDismiss} onCancel={backToMain} />
          ) : (
            <>
              <div className='d-flex w-100 justify-content-between'>
                <h4 className={styles.ctaTitle}> {t("user.profile.payment_cta_title")} </h4>
                <Button onClick={backToMain} className={styles.editCta}> {t("user.profile.cancel_edit_cta")} </Button>
              </div>
              <EditPaymentMethod
                onSuccess={onUpdatePaymentInfoSuccess}
                resetSubscriptionQueryHook={useResetSubscriptionQuery}
                fetchSubscriptionHook={useEditPaymentMethodFetch}
                updatePaymentMethodHook={useUpdatePaymentMethod}
              />
            </>
          )}
        </>
      )}

      {view === EDIT_SMS_PREFERENCES_VIEW && (
        <SMSPreferences
          onAccountCancellationView={() => setView(ACCOUNT_CANCELLATION_VIEW)}
          backToMain={backToMain}
        />
      )}

      {view === ACCOUNT_CANCELLATION_VIEW && (
        <AccountCancellation
          onEditSMSPreferences={() => setView(EDIT_SMS_PREFERENCES_VIEW)}
        />
      )}

      <PoderCardAlertModal
        show={showPodercardAlert}
        onHide={() => setShowPodercardAlert(false)}
      />
    </div>
  );
}

Profile.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  showPayment: PropTypes.bool,
};
