import dayjs from 'dayjs';
import custom from 'dayjs/plugin/customParseFormat';
import { presence } from '@/utilities/string';

dayjs.extend(custom);

export default function dateMonthFirstValidator(value) {
  if (!presence(value)) return null;

  if (!dayjs(value, 'MM/DD/YYYY', true).isValid()) {
    return 'invalid';
  }

  return null;
}
