import { useMutation } from 'react-query';
import { getUserAddress, updateUserAddress, validateUserAddress } from '@/api/address';

export function useAddressFetch({ onSuccess }) {
  const fetcher = useMutation(getUserAddress, { onSuccess });
  const { isLoading, mutate } = fetcher;
  return {
    isLoading,
    fetchAddress: mutate,
  };
}

export function useAddressUpdate({ onSuccess }) {
  // TODO: We may need to invalidate or overwrite the cache upon success
  const fetcher = useMutation(({ pid, address }) => updateUserAddress(pid, address), { onSuccess });
  const { isLoading, mutate } = fetcher;
  return {
    isLoading,
    updateAddress: mutate,
  };
}

export function useAddressValidate({ onSuccess }) {
  const { isLoading, mutate } = useMutation((address) => validateUserAddress(address), { onSuccess });
  return {
    isLoading,
    validateAddress: mutate,
  };
}
