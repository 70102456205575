const premium = {
  signup: "Get my membership",
  already_enrolled: "Already enrolled",
  intro: {
    title: "PODERsalud membership",
    subtitle: "Check out all the benefits included in your subscription:",
    trusted_advisor: "Trusted Advisors",
    exclusive_content: "Exclusive Rewards",
    medical_discounts_subtitle: "Save with discounts on medical services:"
  },
  benefits: {
    teladoc: "Telemedicine",
    vision: "Vision",
    hearing: "Hearing",
    mri: "MRI & CT scans",
    travel: "Affinity travel",
    lab: "Lab testing",
    diabetic: "Diabetic supplies",
    pharmacy: "Rx valet",
    dental: "Dental",
  },
  personal_info: {
    title: "Let's get started!",
    subtitle: "We ensure the highest levels of security when storing your information",
  },
  address: {
    title: "Address",
    subtitle: "We ensure the highest levels of security when storing your information",
  },
  payment: {
    annual_membership: "Yearly membership",
    title: "Payment",
    subtitle: "Choose your payment preference to start enjoying your benefits",
    coupon: "Coupon",
    redeem: "Redeem",
    plan: "Plan type:",
    membership: "SEP+ Membership",
    monthly: "Monthly",
    monthly_membership: "Monthly membership",
    price: "Price",
    duration: "month",
    next: "Next",
    credit_card_number: "CC Number",
    expiry: "Exp mm/yy",
    cvv: "CVV",
    confirm_purchase: "Confirm purchase",
    failure_title: "There was a problem",
    product_name: "Premium Membership",
    or: "Or",
    pay_with_card: "Pay With Card",
    update_and_pay: "Update + Pay",
    update: "Submit Changes",
    yearly: "Yearly",
  },
  steps: {
    benefits: "Benefits",
    personal: "Personal info",
    address: "Address",
    plans: "Plans",
    payment: "Payment info",
  },
  teledoc_sso: 'Request consult online',
  disclaimer:
    "Discount Plan Application. This program is NOT insurance coverage and does not meet the minimum creditable coverage requirements under the Affordable Care Act or Massachusetts M.G.L. c. 111M and 956 CMR 5.00. Your membership is effective upon receipt of membership materials. This program is not available to UT, VT and WA residents. It contains a 30 day cancellation period, provides discounts only at the offices of contracted health care providers, and each member is obligated to pay the discounted medical charges in full at the point of service. The range of discounts for medical or ancillary services provided under the program will vary depending on the type of provider and medical or ancillary service received. Member shall receive a reimbursement of all periodic membership fees if membership is cancelled within the first 30 days after the effective date. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475 Form #HPSEP2",
  not_available: "Sorry, this product is not available in your state.",
  billing: {
    billing: "Billing",
    update_now: "Update Now",
    edit_payment: "Edit",
    default_payment: "Default payment",
  },
  past_due: {
    status_message: "Your PODERsalud subscription payment failed. Please update your payment method and check your card balance.",
    success_title: "Congratulations",
    success_message: "Your payment has been successfully processed and card information has been updated.",
  },
  update_payment: {
    success_title: "Congratulations",
    // TODO: We need copy for this for realz
    success_message: "Your card information has been updated.",
  },
  success: {
    congratulations: "Congratulations ",
    title1: "on your PODERsalud membership!",
    title2:
      "You'll soon receive an email with information on how to access to your benefits.",
    dismiss: "Dismiss",
  },
  coupon: {
    placeholder: "Add coupon",
    success: {
      title: "Success!",
      message: "Your coupon has been redeemed",
    },
    error: {
      title: "There was a problem",
      message: "Incorrect Coupon Code",
    },
    redeem: "Redeem",
    payment_one: "payment",
    payment_other: "{{ count }} payments",
    coupon_applied:
      "Congratulations! You will receive a {{ discount }} discount on your first {{ duration }}",
  },
  landing: {
    main: {
      title: "PODERsalud membership",
      subtitle: "Access to <span>healthcare</span> for you and your immediate family!",
      subtitle_mobile: "Access to <span>healthcare</span> for you and your immediate family",
      caption: "More opportunities to save, learn and grow",
      cta: "Sign Up Today",
      premium_benefits: [
        {
          title: "Medical and dental discounts",
          icon: "MedicalDiscounts"
        },
        {
          title: "Exclusive rewards",
          icon: "ExclusiveRewards"
        },
        {
          title: "Family members included!",
          icon: "Family"
        }
      ],
      only: "Only",
      per_month: "/ month",
    },
    about: {
      title: "Health care without the hassle or excessive costs",
      items:[
        {
          title: "One membership, multiple benefits",
          text: "PODERsalud includes access to Telemedicine and health care savings for you and your immediate family. Plus unlock more earning opportunities."
        },
        {
          title: "It pays for itself with the savings",
          text: "With discounts of up to 80% on medication, 15% - 50% on dental, and so much more — the yearly savings pay for your subscription. Not to mention the opportunity to earn money with exclusive surveys!"
        },
        {
          title: "Built for the community",
          text: "Access doctors, dentists and health care with no insurance! Your circumstances don't matter, the benefits are available to everyone."
        }
      ]
    },
    information: {
      title: "Unbeatable benefits",
      items_mobile: [
        {
          title: "Medical discounts plan",
          text: "PODERsalud includes unlimited virtual doctor’s appointments, up to 50% off on dental care, discounted vision services and prescription medications, and more!",
        },
        {
          title: "Access exclusive rewards",
          text: "PODERsalud members get exclusive opportunities to earn money participating in special surveys. As part of the Rewards program, this is a chance to support the community and make your opinion count!",
        },
        {
          title: "Get the answers you need!",
          text: "We have your back! With your membership you’ll get access to our bilingual customer service. No matter what you need, you’ll find trustworthy and personalized support.",
        }
      ],
      items_desktop: [
        {
          key: 'medical',
          title: "Instant access to medical discounts<span>.</span>",
          text: "The PODERsalud service includes telemedicine whenever needed, prescription discounts of <span>10-80%</span>, dental discounts of <span>15-50%</span> and more!"
        },
        {
          key: 'chat',
          title: "Get the answers you need, when you need them!",
          text: "With your SEP Plus membership you’ll get access to real-time chat service with our experts. No matter what you need, you’ll find trustworthy, confidential and personalized support."
        },
        {
          key: 'exclusive',
          title: "Access exclusive rewards<span>.</span>",
          text: "PODERsalud members get exclusive opportunities to earn money participating in special surveys. As part of the Rewards program, this is a chance to support the community and make your opinion count!"
        }
      ]
    },
    benefits: {
      title: 'How PODERsalud helps you'
    },
    testimonial: {
      title: "What our community is saying",
      items: [
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltf6582111a1580cb4/623e0c8f7c24b44cb0bf4988/jose.png",
          name: "José",
          location: "Miami, FL",
          text: "I recommend PODERsalud to anyone who doesn't have health insurance. This program makes taking care of your health affordable.",
        },
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt7f1b1c137f18f036/62420afb7239af5fef4139a8/angela.png",
          name: "Ángela",
          location: "Chicago, IL",
          text: "We used the telemedicine service with PODERsalud and we were able to get a diagnosis right away. It was incredible!",
        },
        {
          image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt9e0b398fa962155a/635a6490d13cfe10efe62a30/Lucia.png",
          name: "Lucia",
          location: "Los Angeles, CA",
          text: "PODERsalud interested me because it offers plans at a very low cost and it includes your entire immediate family, and you can access a multitude of services. I haven’t found these healthcare benefits anywhere else!",
        }
      ]
    },
    savings: {
      title: 'Sample savings',
      description: 'With PODERsalud you can save an average* of $1,050 per year, and $3,136 for a family of 4. See sample savings below.'
    },
    faq: {
      title: "FAQ",
      items: [
        {
          title: "How does PODERsalud help me?",
          htmlContent: "<p>This subscription supports you in three crucial ways:</p> <ol> <li>It makes healthcare more accessible and affordable.</li>  <li>You can earn extra income participating in surveys, where you’ll discover valuable information on hundreds of topics that will help you make more informed life decisions, saving you time and money.</li> <li>You can ask our team of experts any question.</li> </ol>"
        },
        {
          title: "Can my family benefit from these healthcare discounts?",
          htmlContent: "YES! With PODERsalud not only do you have access to telemedicine and discounted healthcare, but so does your partner and your children (under 26 years of age)."
        },
        {
          title: "Can I cancel my subscription?",
          htmlContent: "Absolutely. Making our services user friendly is incredibly important for us and if you need to make an adjustment you can do so at any time."
        },
      ]
    },
    fineprint: "*This is a savings example and does not guarantee a certain savings amount. Costs and savings may vary based on service, provider, and geographical area."
  },
  appointment:{
    book_appointment_cta: "Book an appointment",
    book_appointment_instruction: "Call the number below to book an appointment"
  },
  annual: {
    billed_annually: "Billed <strong>annually</strong>",
    billed_monthly: "Billed <strong>monthly</strong>",
    discount_percentage: "{{ discount }}% discount",
    months_free: "{{ number }} months free",
    no_discount: "No discount",
    pay_full_price: "Pay full price",
    per_month: "/ month",
    per_year: "/ year",
  },
  missed_payment: {
    header: "Oh no, your payment failed",
    text_content: "To keep your PODERsalud benefits you'll need to update your payment details.",
    banner_text_content: "We’re unable to process your payment. You can reactivate your subscription by updating your payment method.",
    close_cta: "Close",
    update_now_cta: "Update now",
  },
  magic_coupon_applied: {
    header: "{{ discount }} off from your subscription!",
    text: "Our special discount will be applied on your plan once you select a plan and subscribe.",
  },
  referral: {
    friend_invited_you_to_join: 'You have been invited to join PODERsalud with a ${{ discount }} credit',
    claim_your_special_price: 'Claim your special price, only available to friends and family.',
    start_my_membership: 'Start my membership',
    joined_with_your_referral: 'Your friend has joined PODERsalud',
    has_successfully_enrolled:'Your ${{ discount }} reward will be applied as a credit on your subscription.',
    great: 'Great'
  }
};

export default premium;
