import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { PODERCARD_LINK } from '@/env';
import { useTranslation } from '@/hooks/translation';
import styles from './podercard-alert-modal.module.scss';

export default function PoderCardAlertModal(props) {
  const { show, onHide } = props;

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      close={false}
      className={styles.modal}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
      backdrop="static"
    >
      <h2 className={styles.title}>
        {t("user.profile.podercard_alert.title")}
      </h2>
      <p className={styles.message}>
        {t('user.profile.podercard_alert.subtitle')}
      </p>
      <p>
        <a
          href={PODERCARD_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {t('user.profile.podercard_alert.open')}
        </a>
      </p>
      <Button onClick={onHide} className={styles.dismiss}>
        {t('user.profile.podercard_alert.cancel')}
      </Button>
    </Modal>
  );
}

PoderCardAlertModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};
