import * as React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useRegistration } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import { getUtmTags } from '@separate/utilities/utmTagsStore';
import InputForm from './InputForm';
import useRegistrationInputFormState from './useRegistrationInputFormState';

export default function RegistrationStep(props) {
  const { onPhoneNumberSuccess, onPhoneNumberAlreadyExist, currentPhoneNumber } = props;

  const inputFormState = useRegistrationInputFormState();
  const { value: userInputs, setValue } = inputFormState;

  const { locale } = useRouter();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (setValue) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [setValue, currentPhoneNumber]);

  const { isLoading, registration } = useRegistration({ onSuccess, onError });

  function onSuccess(response) {
    // TODO: We get back the pid and phone number.  Do we need to do anything with the pid?
    onPhoneNumberSuccess(userInputs.phone_number);
  }

  function onError({ response }) {
    let errorMessage;

    switch(response.status){
    case 422:
      errorMessage = t(`validations.invalid_phone_number`);
      break;

    case 409:
      errorMessage = t(`validations.phone_already_taken`);
      break;
    }

    const fieldErrors = {
      phone_number: [
        errorMessage
      ]
    };

    inputFormState.mergeErrors(fieldErrors);
  }

  function handleSubmit() {
    registration(
      {
        firstName: userInputs.first_name,
        lastName: userInputs.last_name,
        phoneNumber: userInputs.phone_number,
        language: locale,
        data: getUtmTags(),
      }
    );
  }

  return (
    <>
      <InputForm
        inputFormState={inputFormState}
        loading={isLoading}
        onSubmit={handleSubmit}
      />
    </>
  );
}

RegistrationStep.propTypes = {
  onPhoneNumberSuccess: PropTypes.func.isRequired,
  onPhoneNumberAlreadyExist: PropTypes.func.isRequired,
  currentPhoneNumber: PropTypes.string
};

RegistrationStep.defaultProps = {
  currentPhoneNumber: "",
};
