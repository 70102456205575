export const INPUT_FORM = 'input_form';
export const DEPENDENT_TYPE = 'dependent_type';
export const DEPENDENT_AGE = 'dependent_age';
export const SPOUSE = 'spouse';
export const CHILD = 'child';
export const OVER_18 = 'over_18';
export const UNDER_18 = 'under_18';

export const DEPENDENT_FF_OFF = "Feature flag for 'allow_dependents' is off";
export const MAX_SPOUSE_VALIDATION = 'Validation failed: You can only have one spouse';
export const MAX_DEPENDENT_VALIDATION = 'Validation failed: You can only have a maximum of 10 dependents';

export const VALIDATION_MESSAGES = {
  [DEPENDENT_FF_OFF]: 'dependent.feature_flag_off',
  [MAX_DEPENDENT_VALIDATION]: 'dependent.max_dependent',
  [MAX_SPOUSE_VALIDATION]: 'dependent.max_spouse',
};

export const DEPENDENT_AGE_QUESTION = [
  {
    id: UNDER_18,
    text_en: 'Yes, they are under 18',
    text_es: 'Sí, es menor de 18 años',
  },
  {
    id: OVER_18,
    text_en: 'No, they are above 18',
    text_es: 'No, es mayor de 18 años',
  },
];

export const DEPENDENT_TYPE_QUESTION = [
  {
    id: SPOUSE,
    text_en: 'Spouse',
    text_es: 'Cónyuge',
  },
  {
    id: CHILD,
    text_en: 'Child',
    text_es: 'Hijo o hija',
  },
];
