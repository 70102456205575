import * as React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Login from '@separate/components/LoginModal/Login';
import StandardModal from '@separate/components/Modal';
import { useTranslation } from '@separate/hooks/translation';
import { idType } from '@separate/types';
import styles from './loginModal.module.scss';


export default function LoginModal(props) {
  const { show, onHide, onLoginSuccess, onAbandon, providedPhoneNumber } = props;
  const router = useRouter();
  const { t } = useTranslation();

  function handleLoginSuccess() {
    if (onLoginSuccess) {
      onLoginSuccess();
    }
    onHide();
  }

  function handleAbandon() {
    if (onAbandon) {
      onAbandon();
    }
    onHide();
  }

  function handleRouting(route) {
    router.push(route);
    handleAbandon();
  }

  return (
    <StandardModal
      show={show}
      onHide={handleAbandon}
      close={false}
      fullscreen="md-down"
      bodyClassName={`${styles.modalBody} py-5`}
      contentClassName={styles.modalContent}
    >
      <div className="mb-4">
        <Login onSuccess={handleLoginSuccess} providedPhoneNumber={providedPhoneNumber} />
        <div className={`${styles.handles} mt-3`}>
          <Button className={styles.light} onClick={handleAbandon}>{t('login.exit')}</Button>
        </div>
      </div>
    </StandardModal>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func,
  onAbandon: PropTypes.func,
  providedPhoneNumber: idType
};

LoginModal.defaultProps = {
  providedPhoneNumber: "",
};
