import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@separate/components/Button';
import ProcessingModal from '@separate/components/ProcessingModal';
import SuccessModal from '@separate/components/SuccessModal';
import { SetShowMissedPaymentModalContext } from '@separate/context/SetShowMissedPaymentModalContext';
import { useConfirmCardPayment } from '@separate/hooks/payment';
import { useListPaymentMethods } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { CardSuccess, CloseCircle } from '@separate/icons';
import { logEvent, PAYMENT_FAILED } from '@separate/utilities/analytics';
import PaymentMethodInfoCard from '../PaymentMethodInfoCard';
import styles from './retryCardOffcanvas.module.scss';

export default function RetryCardOffcanvas({
  confirmCardPaymentHook,
  container,
  listPaymentMethodsHook,
  onChangePayment,
  onDismiss,
  onHide,
  onSuccess,
  primaryPaymentMethodId,
  show,
  subscription,
  pid,
}) {
  const { t } = useTranslation();
  const { data } = listPaymentMethodsHook(pid);

  const paymentMethods = data?.payment_methods;
  const defaultPaymentMethod = paymentMethods?.find(method => method.id === primaryPaymentMethodId);

  const [showSuccess, setShowSuccess] = React.useState(false);

  const setShowMissedPaymentModal = React.useContext(SetShowMissedPaymentModalContext);

  function handleHide() {
    onHide?.();
  }

  // Confirm payment
  function onPaymentSuccess() {
    setShowSuccess(true);
  }

  function onPaymentError() {
    logEvent(PAYMENT_FAILED);
    onDismiss();
    setShowMissedPaymentModal(true);
  }

  const {
    isLoading,
    confirmCardPayment,
  } = confirmCardPaymentHook({ onSuccess: onPaymentSuccess, onError: onPaymentError });

  function handleRetry() {
    handleHide();
    confirmCardPayment({
      clientSecret: subscription.client_secret,
      data: { payment_method: defaultPaymentMethod.id },
    });
  }

  function handleHideSuccess() {
    setShowSuccess(false);
    onSuccess?.();
  }

  return (
    <>
      <Offcanvas
        backdropClassName={styles.backdrop}
        className={styles.container}
        container={container}
        onHide={handleHide}
        placement="bottom"
        show={show}
      >
        <Offcanvas.Header className={styles.header}>
          <Offcanvas.Title className={styles.title}>
            {t('user.profile.revise_payment')}
            <div className={styles.subtitle}>
              {t('user.profile.revise_subtitle')}
            </div>
          </Offcanvas.Title>
          <CloseCircle aria-label="Hide" className={styles.closeButton} onClick={handleHide} />
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.body}>
          <Col className="d-flex flex-column align-items-center justify-content-center">
            {defaultPaymentMethod && (
              <PaymentMethodInfoCard
                method={defaultPaymentMethod}
                primary={true}
              />
            )}
            <Button className={styles.retryButton} disabled={!subscription} onClick={handleRetry}>
              {t('user.profile.retry_card')}
            </Button>
            <Button className={styles.changeButton} disabled={!subscription} onClick={onChangePayment}>
              {t('user.profile.change_payment')}
            </Button>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
      <ProcessingModal
        container={container}
        show={isLoading}
        title={t('premium.payment.processing')}
      />
      <SuccessModal
        container={container}
        show={showSuccess}
        icon={<CardSuccess />}
        title={t('premium.payment.confirmed')}
        description={t('premium.payment.received')}
        buttonText={t('close')}
        onHide={handleHideSuccess}
      />
    </>
  );
}

RetryCardOffcanvas.propTypes = {
  confirmCardPaymentHook: PropTypes.func,
  container: PropTypes.any,
  listPaymentMethodsHook: PropTypes.func,
  onChangePayment: PropTypes.func,
  onDismiss: PropTypes.func,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  show: PropTypes.bool,
  subscription: PropTypes.object,
  pid: PropTypes.string, // for zendesk-sep-admin
};

RetryCardOffcanvas.defaultProps = {
  confirmCardPaymentHook: useConfirmCardPayment,
  listPaymentMethodsHook: useListPaymentMethods,
};
