import React from "react";
import cx from 'classnames';
import PropTypes from "prop-types";
import { inputFormStateType, addressVerificationStateType } from "@/types";
import TextInput from "@separate/components/TextInput";
import styles from "./addressModal.module.scss";
import CorrectionModal from "./CorrectionModal";
import FailureModal from "./FailureModal";

export default function AddressInput(props) {
  const { disabled, inputFormState, addressVerificationState, className } = props;

  const {
    correctedAddress,
    showCorrectionModal,
    showFailureModal,
    onDismiss,
    onAcceptCorrection,
  } = addressVerificationState;

  return (
    <>
      <TextInput
        type="text"
        name="address_line_1"
        inputFormState={inputFormState}
        disabled={disabled}
        className={className}
      />

      <TextInput
        type="text"
        name="address_line_2"
        inputFormState={inputFormState}
        disabled={disabled}
        className={className}
      />

      <TextInput
        type="text"
        name="city"
        inputFormState={inputFormState}
        disabled={disabled}
        className={className}
      />

      <div className={styles.cols}>
        <div className={styles.leftColumn}>
          <TextInput
            type="text"
            name="state"
            mask="aa"
            maskChar=""
            inputFormState={inputFormState}
            disabled={disabled}
            className={cx(className, "text-uppercase")}
          />
        </div>

        <div className={styles.rightColumn}>
          <TextInput
            type="tel"
            name="zipcode"
            inputFormState={inputFormState}
            disabled={disabled}
            className={className}
          />
        </div>
      </div>

      <CorrectionModal
        show={showCorrectionModal}
        onDismiss={onDismiss}
        onAccept={onAcceptCorrection}
        correctedAddress={correctedAddress}
      />
      <FailureModal show={showFailureModal} onDismiss={onDismiss} />
    </>
  );
}

AddressInput.propTypes = {
  disabled: PropTypes.bool,
  inputFormState: inputFormStateType,
  addressVerificationState: addressVerificationStateType,
  className: PropTypes.string
};

AddressInput.defaultProps = {
  inputFormState: {},
};
