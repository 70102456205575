import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { SHORT_CODES_IDS, SHORT_CODES_TYPES } from '@/constants/user';
import { useGetUserShortCodesStatus, useUpdateUserShortCodes } from '@/hooks/user';
import Checkbox from '@separate/components/Checkbox';
import { SHORT_CODES } from '@separate/constants/analytics';
import { useTranslation } from '@separate/hooks/translation';
import { logEvent } from '@separate/utilities/analytics';
import styles from './smsPreferences.module.scss';

export default function SMSPreferences({ onAccountCancellationView, backToMain }) {
  const { t } = useTranslation();
  const [userSelections, setUserSelections] = React.useState({ channelIds: [] });
  const { channelIds } = userSelections;

  const handleUserSelections = (val, id) => {
    setUserSelections({ channelIds: val ? [...channelIds, id] : channelIds.filter((element) => element !== id)});
  };

  const onSuccess = (result) => {
    logEvent(SHORT_CODES, { result: 'successful' });
    const userShortCodesStatus = result
      .filter((status) => SHORT_CODES_IDS.includes(status.id) && status.selected)
      .map((status) => status.id);
    setUserSelections({ channelIds: userShortCodesStatus });
  };

  const onError = (err) => {
    logEvent(SHORT_CODES, { result: err });
  };

  const { isLoading: isUpdateLoading, updateUserShortCodes } = useUpdateUserShortCodes({ onSuccess, onError });
  const { isLoading: isGetLoading, getUserShortCodes } = useGetUserShortCodesStatus({ onSuccess, onError });
  const isLoading = isGetLoading || isUpdateLoading;

  const handleClick = () => {
    updateUserShortCodes({ "channel_ids": channelIds });
  };

  React.useEffect(() => {
    getUserShortCodes();
  }, [getUserShortCodes]);

  return (
    <div className={cx(styles.smsPreferences, 'd-flex flex-column justify-content-between')}>
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <h4 className={styles.ctaTitle}>{t('user.profile.sms_preferences.title')}</h4>
          <Button
            onClick={backToMain}
            className={styles.editCta}
          >
            {t('user.profile.cancel_edit_cta')}
          </Button>
        </div>
        <p className={cx(styles.text, 'mt-3')}>{t('user.profile.sms_preferences.text')}</p>
        <div className={cx(styles.checkBoxesWrapper, 'mt-4')}>
          {SHORT_CODES_TYPES.map(({ id, type }) => (
            <div key={id} className='d-flex align-items-center'>
              <Checkbox
                key={id}
                id={id}
                label={t(`user.profile.sms_preferences.${type}.title`)}
                disabled={isLoading}
                checked={channelIds.includes(id)}
                onChange={(val) => handleUserSelections(val, id)}
                className={cx(styles.checkBox, 'mb-2 d-flex align-items-center')}
              />
              <span>{t(`user.profile.sms_preferences.${type}.code`)}</span>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className='d-block text-center px-4'>
          <Button
            className={cx(styles.cta, 'w-100')}
            onClick={handleClick}
            disabled={isLoading}
          >
            {t('user.profile.sms_preferences.submit_cta')}
          </Button>
        </div>

        <p className='my-4 text-center'>
          {t('user.profile.sms_preferences.account_cancellation.text_one')}
          <Button
            onClick={onAccountCancellationView}
            className={styles.accountCancellationCta}
          >
            {t('user.profile.sms_preferences.account_cancellation.link')}
          </Button>
          {t('user.profile.sms_preferences.account_cancellation.text_two')}
        </p>
      </div>
    </div>
  );
}

SMSPreferences.propTypes = {
  onAccountCancellationView: PropTypes.func.isRequired,
  backToMain: PropTypes.func.isRequired,
};
