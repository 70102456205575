import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import styles from './langselectmodal.module.scss';

export default function LangSelectModal(props) {
  const { show, onHide, locale } = props;

  const [selected, setSelected] = useState(locale || 'es');

  useEffect(() => {
    setSelected(locale);
  }, [locale]);

  const setEn = () => {
    setSelected('en');
    onHide('en');
  };

  const setEs = () => {
    setSelected('es');
    onHide('es');
  };

  const handleHide = () => {
    onHide(selected);
  };

  return (
    <Modal
      close={false}
      contentClassName={styles.langSelect}
      dialogClassName={styles.options}
      onHide={handleHide}
      show={show}
    >
      <div onClick={setEn}>
        <span className={cx(styles.offset, { 'invisible': selected !== 'en' })}>✓</span>
        English
      </div>
      <hr />
      <div onClick={setEs}>
        <span className={cx(styles.offset, { 'invisible': selected !== 'es' })}>✓</span>
        Español
      </div>
    </Modal>
  );
}

LangSelectModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  locale: PropTypes.string,
};
