import { required, zipcode } from '@/validations';

const ADDRESS_VALIDATIONS = {
  address_line_1: {
    translationKey: 'address.address_line_1',
    rules: [required],
  },
  address_line_2: {
    translationKey: 'address.address_line_2',
  },
  city: {
    translationKey: 'address.city',
    rules: [required],
  },
  state: {
    translationKey: 'address.state',
    rules: [required],
  },
  zipcode: {
    translationKey: 'address.zipcode',
    rules: [required, zipcode],
  },
};

export default ADDRESS_VALIDATIONS;
