const benefits = {
  cancellation_policy: {
    title: 'Cancellation policy',
    this_is_not_insurance: 'THIS IS NOT INSURANCE',
    faq: {
      items: [
        {
          title: 'Disclosures:',
          htmlContent: '<ol><li>This plan is not a health insurance policy.</li><li>This plan provides discounts for medical services at certain health care providers.</li><li>This plan does not make payments directly to the providers of medical services.</li><li>The member is obligated to pay for all discounted health care services at the point of service. The discounts may vary based on service, provider, and geographic location.</li></ol>'
        },
        {
          title: 'Cancellation Policy:',
          htmlContent: 'To terminate or cancel the member agreement please call (726) 202-1213 or send a written cancellation notice to Attn: Member Services at P.O. Box 803475, Dallas, TX 75367-1309. This notice must include your name, member ID number, and be submitted at least three (3) days prior to your next scheduled payment date. This agreement can be canceled for non-payment. If a membership is cancelled for any reason other than non-payment, the member shall receive a pro-rata reimbursement of all periodic charges.'
        },
        {
          title: '30-day money back guarantee:',
          htmlContent: 'If you cancel for any reason within 30 days after the effective date, you will receive a full refund of paid membership fees.'
        },
        {
          title: 'Issue Resolution:',
          htmlContent: 'For any concerns or complaints when using your membership, please call (726) 202-1213 for assistance. Your issue will be researched, and a resolution reached within 3 business days.'
        }
      ]
    }
  },
  podersalud: {
    how_podersalud_helps_you: 'How PODERsalud helps you',
    get_my_membership: 'Get my membership',
    telehealth : {
      title: 'On-demand Telemedicine',
      description: 'We know you’re busy, so talk to a doctor or medical expert 24/7 by phone or video.',
      items: [
        {
          text: 'Available 24/7'
        },
        {
          text: 'U.S. board-certified doctors'
        },
        {
          text: 'Available in Spanish'
        }
      ]
    },
    prescription: {
      title: 'Prescription Discounts',
      description: 'Just show your PODERsalud card and save up to 80% on your prescriptions at popular pharmacies.',
      items: [
        {
          text: 'Find the best price near you '
        },
        {
          text: 'Available at 60,000 retail pharmacies nationwide'
        }
      ]
    },
    dental: {
      title: 'Dental Discounts',
      description: 'Save easy at dental appointments. Receive 15% - 50% discounts per visit from participating dentists.',
      items: [
        {
          text: 'Over 260,000 dentists'
        },
        {
          text: 'Cleanings, braces, x-rays, fillings, and more'
        }
      ]
    },
    vision: {
      title: 'Other Medical Discounts',
      description: 'Better vision and diagnosis. Save 10 - 60% on eye health and up to 75% on MRI/CT scans.',
      items: [
        {
          text: 'Schedule with participating provider and present card'
        },
        {
          text: 'Includes neighborhood doctor'
        }
      ]
    },
    earnings: {
      title: 'Exclusive Earnings',
      description: 'Be the first to know. Earn extra through Rewards with exclusive surveys to our PODERsalud members.',
      items: [
        {
          text: 'Access exclusive surveys in app'
        },
        {
          text: 'Exclusive payouts'
        }
      ]
    }
  },
  savings: {
    in_savings: '${{ price }} in savings',
    with_podersalud: 'With <span>PODER</span>salud',
    dental: {
      title: 'Dental',
      description: '<span>Average cost</span> of teeth cleaning for an adult or child',
      per_visit: '<span>${{ price }}</span> / per visit'
    },
    telemedicine: {
      title: 'Telemedicine',
      description: '<span>Average cost</span> for an in person doctors appointment',
      per_visit: '<span>${{ price }}</span> / per visit'
    },
    pharmacy_lisinopril: {
      title: 'Pharmacy',
      description: '<span>Average cost</span> of Lisinopril / (Blood Pressure)',
      per_visit: '<span>${{ price }}</span> / 20mg (30 tablets)'
    },
    pharmacy_amlodipine : {
      title: 'Pharmacy',
      description: '<span>Average cost</span> of Amlodipine (Blood Pressure)',
      per_visit: '<span>${{ price }}</span> / 10 mg (30 tablets)'
    },
    vision : {
      title: 'Vision',
      description: '<span>Average cost</span> for an Eyeglass exam',
      per_visit: '<span>${{ price }}</span> / per visit'
    }
  },
  rx_drug_prices: {
    header: {
      title: 'Rx Valet',
      text: 'Search and compare prices for:',
    },
    drug_name: 'DRUG NAME',
    enter_first_three_letters: 'Enter first 3 letters of drug name',
    zipcode: 'ZIP CODE',
    enter_zip_code: 'Enter zip code',
    update_location: 'Update location',
    find_lowest_price: 'Find the lowest price',
    pharmacies: 'Pharmacies',
    no_results: 'No results available',
    search_results: '{{ result }} providers found near',
    distance: '{{ distance }} Miles',
    view_savings: 'View savings card',
    drug_card: {
      discount: 'Discount card',
      not_insurance: 'THIS IS NOT INSURANCE.',
      estimated_discount: '*Estimated discount price',
      member_id: 'Member ID:',
      group_id: 'Group ID:',
      bin: 'BIN:',
      pcn: 'PCN:',
      customer_service: 'Customer service:',
      pharmacy_help: 'Pharmacy help desk:',
    },
  },
};

export default benefits;
