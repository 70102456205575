import { useTranslation as i18nUseTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

export function useTranslation() {
  return i18nUseTranslation();
}

export function useLocale() {
  const { locale } = useRouter();

  return locale;
}
