const premium = {
  coupon: {
    placeholder: "Add coupon",
    success: {
      title: "Success!",
      message: "Your coupon has been redeemed",
    },
    error: {
      title: "There was a problem",
      message: "Incorrect Coupon Code",
    },
    redeem: "Redeem",
    payment_one: "payment",
    payment_other: "{{ count }} payments",
    coupon_applied: "Congratulations! You will receive a {{ discount }} discount on your first {{ duration }}",
    your_subscription: 'You have ${{ coupon }} OFF your subscription!',
    discount_will_be_automatically_applied_at_checkout: 'Credit will be automatically applied at checkout.'
  },
  payment: {
    title: "Payment",
    choose_the_plan: "Choose the plan that works best for you ",
    subtitle: "Enter your payment information to start enjoying your benefits",
    plan_type: "Plan type",
    selected: "Selected:",
    plan_price: "Plan price",
    membership: "SEP+ Membership",
    annual_membership: "Yearly membership",
    monthly: "Monthly",
    monthly_membership: "Monthly membership",
    price: "Price",
    duration: "month",
    credit_card_number: "CC Number",
    expiry: "Exp mm/yy",
    cvv: "CVV",
    confirm_purchase: "Confirm purchase",
    failure_title: "There was a problem",
    product_name: "Premium Membership",
    or: "Or",
    pay_with_card: "Pay With Card",
    update_and_pay: "Update + Pay",
    yearly: "Yearly",
    proceed: "Go to payment",
    one_time_discount: 'One-time credit',
    total: 'Total',
    confirmed: 'Payment confirmation',
    received: 'Thank you! Your payment has been received.',
    processing: 'Processing payment...',
    change_payment_method: 'Payment method',
    pay_now: 'Pay now',
    added: 'New card added successfully!',
    added_subtitle: 'Do you want to pay your subscription with this card?',
    failed: 'Oh no, your payment failed',
    ensure_funds_or_new_card: 'Make sure you have sufficient funds on your card or try adding a new card.',
    will_be_default: 'This card will be used as your default card for future payments',
  },
  past_due: {
    status_message: "Your PODERsalud subscription payment failed. Please update your payment method and check your card balance.",
    success_title: "Congratulations",
    success_message: "Your payment has been successfully processed and card information has been updated.",
  },
  not_available: "Sorry, this product is not available in your state.",
  update_payment: {
    success_title: "Congratulations",
    // TODO: We need copy for this for realz
    success_message: "Your card information has been updated.",
  },
  annual: {
    billed_annually: "Billed <strong>annually</strong>",
    billed_monthly: "Billed <strong>monthly</strong>",
    discount_percentage: "{{ discount }}% savings",
    months_free: "{{ number }} months free",
    no_discount: "No discount",
    pay_full_price: "Pay full price",
    per_month: "/ month",
    per_year: "/ year",
  },
  ask_us_anything: {
    title: "Ask us anything",
    got_questions: "Got questions? Want to find out if PODERsalud is the right choice for you? We’re happy to assist!",
    talk_with_an_expert: "Talk with an expert",
  },
  upgrade_subscription_plan: {
    monthly: 'monthly',
    yearly: 'anual',
    next_billing_date: 'Next billing date:',
    save_more: 'Save more than $50 with the annual plan!',
    update_your_plan: 'Update your plan',
    upgrade_now: 'Upgrade now',
    save_over_50_per_year: 'Save over $50 per year on your PODERsalud subscription with our annual plan.',
    you_will_be_charge_today_the_full_year: 'You will be charged today the full year. Your next billing date will be {{ next_payment }}.',
    proceed_to_payment: 'Proceed to payment',
    processing_payment: 'Processing payment...',
    your_payment_has_been_received: 'Thank you! Your payment has been received. Your next billing date is {{ next_payment }}.',
    your_plan_has_been_upgraded: 'Your plan has been upgraded!',
    close: 'Close',
    your_payment_failed: 'Oh no, your payment failed',
    make_sure_you_have_sufficient_funds: 'Make sure you have sufficient funds on your card or try adding a new card.',
    try_again: 'Try again',
    add_payment: 'Add payment',
    revise_your_payment_method: 'Revise your payment method',
    confirm_your_payment_method: 'Confirm your payment method',
    retry_with_this_card: 'Retry with this card',
    you_can_try_again: 'You can try again with your selected method of payment or change it.',
    go_back: 'Go Back',
    select_payment_option: 'Select payment option',
    change_payment_method: 'Change payment method',
    upgrade_plan: 'Upgrade now'
  }
};

export default premium;
