import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Button from "@/components/Button";
import { useTranslation } from "@/hooks/translation";
import { addressType } from "@/types";
import styles from "./addressModal.module.scss";

export default function CorrectionModal(props) {
  const { show, onDismiss, onAccept, correctedAddress } = props;

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onDismiss}
      className={styles.modal}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
      backdrop="static"
    >
      <h2 className={styles.title}>
        {t("address.verification.is_this_correct")}
      </h2>
      <p className={styles.message}>
        {correctedAddress.address_line_1} {correctedAddress.address_line_2}
        <br />
        {correctedAddress.city}, {correctedAddress.state}{' '}
        {correctedAddress.zipcode}
      </p>
      <Row>
        <Button onClick={onAccept} className={styles.next}>
          {t("address.verification.yes")}
        </Button>
      </Row>
      <Row>
        <Button onClick={onDismiss} className={styles.dismiss}>
          {t("address.verification.no_edit_address")}
        </Button>
      </Row>
    </Modal>
  );
}

CorrectionModal.propTypes = {
  show: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  correctedAddress: addressType,
};
