function isFalseyButNot0(value) {
  return (!value && value !== 0 && value !== BigInt(0));
}

export function asPercent(value, locales='en-US') {
  if (isFalseyButNot0(value)) {
    return '--%';
  }

  return Number(value).toLocaleString(locales, { style: 'percent' });
}

export function asCurrency(value, locales='en-US') {
  if (isFalseyButNot0(value)) {
    return '$--.--';
  }

  return Number(value).toLocaleString(locales, { style: 'currency', currency: 'USD' });
}

export function clampText(text, lines, cols) {
  const res = [];
  const input = text.split(/\b/g);
  let line = 0;
  let col = 0;
  while (input.length && line < lines) {
    const next = input.shift();
    if (col === 0 && next.length > cols) {
      // Full word doesn't fit in one line, split between two
      res.push(next.substr(0, cols));
      res.push("\u200b");
      input.unshift(next.substr(cols));
      line++;
    } else if (next.length + col > cols) {
      // Line feed
      col = 0;
      line++;
      res.push("\u200b");
      input.unshift(next);
    } else {
      res.push(next);
      col += next.length;
    }
  }

  const clampedText = res.join("").replace(/[\s\u200b]+$/, "");
  if (/\S/.test(input.join(""))) {
    return `${clampedText}…`;
  } else {
    return clampedText;
  }
};

export function presence(value) {
  if (value == null) return null;

  return `${value}`.match(/\S/) ? value : null;
}
