import React from 'react';
import PropTypes from 'prop-types';
import posthog from 'posthog-js';
import { AnalyticsBrowser } from '@segment/analytics-next';
import PageLoader from '@separate/components/PageLoader';

const FeatureFlagsContext = React.createContext(null);
export function FeatureFlagsProvider({ children, posthogHost, posthogKey, segmentHost, segmentKey }) {
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!posthogKey || !segmentKey) {
      setTimeout(() => { setLoading(false); }, 1000);
      return;
    };
    // Segment
    const analytics = AnalyticsBrowser.load({ cdnURL: segmentHost, writeKey: segmentKey });

    // Init PostHog with Segment info when Segment is ready
    analytics.ready(() => {
      posthog.init(posthogKey, {
        api_host: posthogHost,
        segment: analytics.instance,
        capture_pageview: false,
        loaded: () => window.analytics.page(),
      });
      setTimeout(() => { setLoading(false); }, 1500);
    });

    // Globalify
    window.analytics = analytics;
  });

  return (
    <FeatureFlagsContext.Provider
      value={posthog}
    >
      {isLoading ?
        <PageLoader />
        : <>{children}</>}
    </FeatureFlagsContext.Provider>
  );
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  posthogHost: PropTypes.string,
  posthogKey: PropTypes.string,
  segmentHost: PropTypes.string,
  segmentKey: PropTypes.string
};

export function useFeatureFlagsProvider() {
  return React.useContext(FeatureFlagsContext);
}
