import baseAuthAdminQuery from './baseAuthAdminQuery';
import { compact } from "@separate/utilities/object";

const BASE_PATH = "/premium_subscriptions";

export async function getSubscription() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/status`,
    method: 'get',
  });

  return response?.data;
}

export async function getPendingPremiumSubscription(utmTags = {}) {
  const query = new URLSearchParams(compact(utmTags)).toString();
  const path = `${BASE_PATH}/initialize?${query}`;

  const response = await baseAuthAdminQuery({
    path,
    method: 'get',
  });

  return response?.data;
}

export async function postPaymentSuccess() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/activate`,
    method: 'post',
  });

  return response?.data;
}

export async function getPastDuePayment() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/edit_current_payment_method`,
    method: 'get',
  });

  return response?.data;
}

export async function getEditPaymentMethod() {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/edit_future_payment_method`,
    method: 'get',
  });

  return response?.data;
}

export async function updateCouponCode(couponCode) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/coupon_code`,
    method: 'post',
    data: {
      coupon_code: couponCode,
    },
  });

  return response?.data;
}

export async function updatePaymentMethod(setupIntentId) {
  const response = await baseAuthAdminQuery({
    path: `${BASE_PATH}/future_payment_method`,
    method: 'post',
    data: {
      setup_intent_id: setupIntentId,
    },
  });

  return response?.data;
}

// This is effectively an alias.  It hits the same backend endpoint, but is named after its specific purpose
export async function submitFreeTrialSetup(setupIntentId) {
  return updatePaymentMethod(setupIntentId);
}
