const podercard = {
  main: {
    title: "Banco digital para la comunidad",
    subtitle: "Accesible, confiable y sencillo",
    cta: "Comience aquí",
    ctaMember: "Abrir la App de PODERcard"
  },
  about: {
    items: [
      {
        title: "Finanzas sin estrés",
        text: "Escuchamos las necesidades de la comunidad, y creamos una cuenta bancaria para administrar finanzas de forma sencilla, segura y accesible para la mayor cantidad de personas posible."
      },
      {
        title: "1,000,000+ personas aman PODERcard",
        text: "Únase al millón de personas con la app. Aplique con ITIN, Matrícula Consular de México o SSN. Administre sus finanzas sin cuotas mensuales², sin saldos mínimos, sin sobregiros, ¡sin complicaciones!"
      },
      {
        title: "¡Cuenta bancaria para la comunidad!",
        text: "PODERcard fue lanzada con nuestra comunidad en mente para todas y todos, sin importar de dónde vienen ni a dónde van."
      }
    ]
  },
  information: {
    title: "Tome control de <span>sus finanzas</span>",
    items: [
      {
        content: "<strong>Fácil de usar.</strong> Le tomará solo unos minutos aplicar, y ya que comience a utilizar su tarjeta de débito podrá cuidar sus transacciones con total transparencia.",
        key: "banking"
      },
      {
        content: "<strong>Toda la comunidad es elegible.</strong> Aplique con una Matrícula Consular de México, ITIN o SSN. Su estatus migratorio no es prioridad, ¡usted sí!",
        key: "group"
      },
      {
        content: "<strong>Seguridad y apoyo.</strong> Su información personal y financiera está protegida, y los fondos en su cuenta cuentan con el respaldo del FDIC. Ofrecemos atención al cliente en español para brindarle el apoyo que necesite.",
        key: "security"
      },
      {
        content: "<strong>Reciba pagos rápidos.</strong> Obtenga su sueldo hasta 2 días por adelantado con ASAP Direct Deposit™³ y retire efectivo en más de 19,000 cajeros automáticos sin costo dentro de la red.³",
        key: "fast"
      },
      {
        content: "<strong>Cuide su dinero.</strong> Obtenga recompensas en efectivo con las encuestas pagadas de SABEResPODER y reciba sus ganancias al instante en su PODERcard.¹",
        key: "money"
      }
    ]
  },
  testimonials: {
    title: "La comunidad dice",
    items: [
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltce11db396494a883/623e0c8f549d685f55b5ace8/leticia.png",
        name: "Leticia L.",
        location: "Los Angeles, CA",
        text: "Muchos bancos no pueden… o más bien, no quieren ayudarte con abrir una cuenta para guardar tu dinero. PODERcard sí, y hacen que sea muy fácil.",
      },
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltfa8294a477e6787e/623e0c8f159fc76504c18355/marco.png",
        name: "Marco V.",
        location: "Chicago, IL",
        text: "He participado en muchas encuestas, unas 15-20, y he ganado unos $300-$400 que han sido depositados directo a mi  PODERcard.",
      },
      {
        image: "https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltf6582111a1580cb4/623e0c8f7c24b44cb0bf4988/jose.png",
        name: "Jose M.",
        location: "Miami, FL",
        text: "Gracias a PODERcard puedo transferir dinero gratis a mi esposa y familia allá del otro lado sin cargos.",
      }
    ]
  },
  faq: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Qué es un banco digital?",
        htmlContent: "Un banco digital, también conocido como banca móvil o banco en línea, ofrece cuentas bancarias accesibles por medio de una app en el celular. Normalmente no cuentan con tiendas presenciales, sin embargo esto no impacta la calidad ni la agilidad de la atención al cliente. La ventaja de este servicio es que permite ser más eficiente en costos, lo que se traduce a una mejor experiencia para el cliente ya que no es necesario cobrar cuotas mensuales ni cumplir con saldos mínimos.²"
      },
      {
        title: "¿Es segura la banca móvil?",
        htmlContent: "Nuestra meta es hacer la bancarización más incluyente, confiable y sencilla. PODERcard cuenta con los más altos estándares de seguridad para proteger su información personal y financiera. Podrá optar por recibir notificaciones en tiempo real sobre cualquier actividad en su cuenta. Si necesita bloquear su tarjeta por cualquier motivo, lo podrá hacer al instante desde la app."
      },
      {
        title: "¿Qué hago si necesito ayuda?",
        htmlContent: "Póngase en contacto con nuestro Equipo de Atención al Cliente en cualquier momento. Su satisfacción es nuestra prioridad #1."
      }
    ]
  },
  links : {
    accountAgreement: "Acuerdo de Cuenta de Depósito",
    greenDotBankPrivacyPolicy: "Green Dot Bank Política de Privacidad",
    electronicCommunicationsAgreement: "Acuerdo de Comunicación Electrónica",
    greenDotTechnologyPrivacyStatement: "Declaración De Privacidad De Tecnología",
    termsAndConditions: "Términos y Condiciones",
    termsOfUseAgreement: "Acuerdo de Términos de Uso",
    welcomeTechPrivacyPolicy: "Política de Privacidad de Welcome Tech",
  },
  descriptions:{
    otherOffers: "¹Otros productos, servicios o descuentos de SaberEsPoder no son proveídos por Green Dot.",
    deposit: "²Es posible que se apliquen otros cargos. Para obtener detalles, consulte los Acuerdo de Cuenta de Deposito.",
    directDeposit: "³ASAP Direct Deposit™: La disponibilidad anticipada de un depósito directo depende de la verificación del depósito y de cuando el banco reciba el aviso por parte del empleador o proveedor de beneficios. Además, se pueden emplear restricciones de prevención de fraude. Por ello, la disponibilidad de fondos de un depósito directo anticipado puede variar de un período de pago a otro.",
    freeATM: "⁴El acceso gratuito a cajeros automáticos aplica solamente para los cajeros automáticos de la red.",
    disclosure: "La apertura de la cuenta PODERcard está sujeta a una verificación de identidad por Green Dot Bank. Los servicios bancarios y la PODERcard VISA® Debit Card son ofrecidos por Green Dot Bank, Miembro FDIC, en conformidad con la licencia de Visa U.S.A. Inc. Visa es una marca comercial registrada por Visa International Service Association. Green Dot opera bajo los siguientes nombres registrados: GoBank, Green Dot Bank y Bonneville Bank. Todos estos nombres registrados son usados y se refieren al mismo banco asegurado por el FDIC, Green Dot Bank. Depósitos hecho bajo cualquier de estos nombres registrados son depósitos con Green Dot Bank y son sumados para la cobertura de seguro de depósitos. Green Dot es una marca registrada de Green Dot Corporation ©2020 Green Dot Corporation. Todos los derechos reservados.",
  },
};

export default podercard;
