import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import ProfileModal from '@/components/ProfileModal';
import { useCurrentUser } from '@/hooks/user';
import { AvatarLight } from '@/icons';
import LoginModal from '@separate/components/LoginModal';
import { PROFILE_VIEW } from '@separate/constants/analytics';
import { logEvent } from '@separate/utilities/analytics';
import styles from './loginButton.module.scss';

export default function LoginButton(props) {
  const { className, ...extraProps } = props;
  const { isLoggedIn } = useCurrentUser();

  const [showLogin, setShowLogin] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);

  function setShow() {
    if(isLoggedIn) {
      logEvent(PROFILE_VIEW);
      setShowProfile(true);
    } else {
      setShowLogin(true);
    }
  }

  function hideLogin() {
    setShowLogin(false);
  }

  function hideProfile() {
    setShowProfile(false);
  }

  return (
    <>
      <Button onClick={setShow} iconButton className={className}>
        <AvatarLight className={styles.avatar} />
      </Button>
      {isLoggedIn
        ? <ProfileModal show={showProfile} onHide={hideProfile} />
        : <LoginModal show={showLogin} onHide={hideLogin} />
      }
    </>
  );
}

LoginButton.propTypes = {
  className: PropTypes.string
};
