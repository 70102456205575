import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from '@separate/components/Button';
import Checkbox from '@separate/components/Checkbox';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import { useCreditCardInput } from '@separate/hooks/payment';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { GoBack } from '@separate/icons';
import styles from './addNewCardOffcanvas.module.scss';

const BASE_STYLES = {
  borderBottom: "3px solid #5C7AFF",
  padding: "20px 0px 0px 0px",
  minHeight: "44px",
  fontSize: "16px",
  "::placeholder": {
    color: "transparent",
  },
};

export default function CreditCardInputForm({
  clientSecret,
  isDefault,
  onFailure,
  onSuccess,
  setIsDefault,
  setupIntentId,
  isUpgradeSubscriptionPlan,
  onCancel,
  updateSetupIntentHook,
  pid,
}) {
  const locale = useLocale();
  const { t } = useTranslation();

  const [fieldErrors, setFieldErrors] = React.useState(null);
  const [valid, setValid] = React.useState(false);

  function elementStyle(id) {
    if (fieldErrors && fieldErrors[id]) {
      return { ...BASE_STYLES, borderColor: "red" };
    } else {
      return { ...BASE_STYLES, borderColor: "#5C7AFF" };
    }
  }

  const STYLES = React.useMemo(() => ({
    base: BASE_STYLES,
    invalid: {
      color: "#000",
    },
  }), []);

  const cardInputConfig = React.useMemo(() => ({
    number: {
      element: "#offcanvas-card-number",
      placeholder: t("premium.payment.credit_card_number"),
    },
    expiry: {
      element: "#offcanvas-card-expiry",
      placeholder: t("premium.payment.expiry"),
    },
    cvc: {
      element: "#offcanvas-card-cvc",
      placeholder: t("premium.payment.cvv"),
    },
  }), [t]);

  React.useEffect(() => {
    if(isUpgradeSubscriptionPlan)
      setIsDefault(true);
  }, [isUpgradeSubscriptionPlan, setIsDefault]);

  function handleConfirmFailure() {
    onFailure();
  }

  const handleCardChange = React.useCallback((status) => {
    setFieldErrors(status.fieldErrors);
    setValid(status.complete);
  }, [setFieldErrors]);

  const { isLoading: isConfirmLoading, confirmPayment: confirmSetup } = useCreditCardInput({
    ...cardInputConfig,
    clientSecret,
    locale,
    onChange: handleCardChange,
    onError: handleConfirmFailure,
    onSuccess: onSuccess,
    setupMode: true,
    style: STYLES,
  });

  function handleUpdateFailure() {
    onFailure();
  }

  const { updateSetupIntent } = updateSetupIntentHook({
    onSuccess: confirmSetup,
    onError: handleUpdateFailure,
  });

  function submitPaymentMethod() {
    updateSetupIntent({ setupIntentId, optional: !isDefault, pid });
  }

  return (
    <>
      <div className={styles.cardInfo}>
        <Row className={cx(styles.cardRow, "mb-4")}>
          <Col xsm={12}>
            <div className="stripeInputContainer">
              <div
                id="offcanvas-card-number"
                style={elementStyle("cardNumber")}
                className="stripeInput"
              />
              <label>{t("premium.payment.credit_card_number")}</label>
            </div>
          </Col>
        </Row>

        <Row className={styles.cardRow}>
          <Col xs={6} className="pr-1">
            <div className="stripeInputContainer">
              <div
                id="offcanvas-card-expiry"
                style={elementStyle("cardExpiry")}
                className="stripeInput"
              />
              <label>{t("premium.payment.expiry")}</label>
            </div>
          </Col>

          <Col xs={5} className="pl-1">
            <div className="stripeInputContainer">
              <div
                id="offcanvas-card-cvc"
                style={elementStyle("cardCvc")}
                className="stripeInput"
              />
              <label>{t("premium.payment.cvv")}</label>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <FieldErrorsList fieldErrors={fieldErrors} />
          </Col>
        </Row>
      </div>

      {!isUpgradeSubscriptionPlan && (
        <Checkbox
          className={styles.checkbox}
          checked={isDefault}
          label={t("user.profile.make_default")}
          onChange={() => setIsDefault(!isDefault)}
        />
      )}

      <div className="d-flex justify-content-center">
        {isUpgradeSubscriptionPlan && (
          <Button
            onClick={() => onCancel()}
            className={styles.buttonLight}
          >
            <GoBack />
            {t("premium.upgrade_subscription_plan.go_back")}
          </Button>
        )}
        <Button
          loading={isConfirmLoading}
          disabled={!valid}
          onClick={submitPaymentMethod}
          className={cx(styles.button, isUpgradeSubscriptionPlan ? "w-50" :  "w-75")}
        >
          {isUpgradeSubscriptionPlan
            ? t("premium.upgrade_subscription_plan.upgrade_now")
            : t("user.profile.add_card")}
        </Button>
      </div>
    </>
  );
}

CreditCardInputForm.propTypes = {
  clientSecret: PropTypes.string,
  isDefault: PropTypes.bool,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setIsDefault: PropTypes.func.isRequired,
  setupIntentId: PropTypes.string,
  isUpgradeSubscriptionPlan: PropTypes.bool,
  onCancel: PropTypes.func,
  updateSetupIntentHook: PropTypes.func.isRequired,
  pid: PropTypes.string, // for zendesk-sep-admin
};
