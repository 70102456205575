const INELIGIBLE_STATES = ['UT', 'VT', 'WA'];

// The observed values from Stripe when a payment has been missed ... _so far_
const PAY_DUE_STATUSES = [
  'past_due',
  'unpaid',
];

export function isStateEligible(state) {
  return !INELIGIBLE_STATES.includes(state);
}

export function isSubscriptionPastDue(subscription) {
  return PAY_DUE_STATUSES.includes(subscription?.status);
}

// Similarly here, I _believe_ active is the only possible status for being in good standing.  We want true here
// as long as the subscription is live, whether in good standing or not.
export function isSubscriptionActive(subscription) {
  return (
    subscription?.status === 'active' || isSubscriptionPastDue(subscription)
  );
}

export function isSubscriptionLegacy(subscription) {
  return !subscription?.last4 && isSubscriptionActive(subscription);
}

export function isAnnual(subscription) {
  return subscription?.interval == 'year';
}

export function isSubscriptionMonthlyActive(subscription) {
  return isSubscriptionActive(subscription) && subscription?.interval === 'month';
}
