import React from 'react';
import PropTypes from 'prop-types';
import { useSubscriptionQuery } from '@/hooks/premium';
import { capitalizeFirstLetter, formatDateWithComma } from '@separate/utilities/datetime';
import { isSubscriptionActive, isSubscriptionPastDue } from '@separate/utilities/premium';
import BillingStatus from './BillingStatus';

export default function PlanInfo(props) {
  const { onPayPastDue, onEditPaymentMethod } = props;

  const { isLoading, subscription } = useSubscriptionQuery({ fastRefresh: true });

  const active = isSubscriptionActive(subscription);
  const pastDue = isSubscriptionPastDue(subscription);
  const last4 = subscription?.last4;

  const shouldDisplay = !isLoading && active && last4;

  return (
    <>
      {shouldDisplay && (
        <BillingStatus
          pastDue={pastDue}
          last4={last4}
          onPayPastDue={onPayPastDue}
          onEditPaymentMethod={onEditPaymentMethod}
          interval={subscription?.interval}
          nextPaymentDate={`${capitalizeFirstLetter(formatDateWithComma(subscription?.next_payment_at))}`}
        />
      )}
    </>
  );
}

PlanInfo.propTypes = {
  onPayPastDue: PropTypes.func.isRequired,
  onEditPaymentMethod: PropTypes.func.isRequired,
};
