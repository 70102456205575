import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getEditPaymentMethod,
  getPastDuePayment,
  getPendingPremiumSubscription,
  getSubscription,
  listPaymentMethods,
  postPaymentSuccess,
  removePaymentMethod,
  submitFreeTrialSetup,
  updateCouponCode,
  updateInterval,
  updatePaymentMethod,
  updateSetupIntent,
  upgradeSubscription
} from '@separate/api/premium';
import { useCurrentUserSession } from '@separate/hooks/session';

function subscriptionQueryKey(idToken) {
  return ['subscription', idToken];
}

export function useResetSubscriptionQuery() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(subscriptionQueryKey(idToken));
}

export function useSubscriptionQuery(options = {}) {
  const { idToken } = useCurrentUserSession();

  const { fastRefresh } = options;
  const refetchOnMount = fastRefresh ? 'always' : true;

  const { isLoading, data } = useQuery(subscriptionQueryKey(idToken), getSubscription, { refetchOnMount });

  return { isLoading, subscription: data };
}

export function usePendingPremiumSubscriptionFetch({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ utmTags }) => getPendingPremiumSubscription(utmTags),
    {
      onSuccess,
      onError,
    }
  );
  return { isLoading, fetchSubscription: mutate };
}

export function useActivatePremiumMembership({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    postPaymentSuccess,
    {
      onSuccess,
    }
  );
  return { isLoading, activatePremiumMembership: mutate };
}

export function usePastDuePaymentFetch({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    getPastDuePayment,
    {
      onSuccess,
    }
  );
  return { isLoading, fetchSubscription: mutate };
}

export function useEditPaymentMethodFetch({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    getEditPaymentMethod,
    {
      onSuccess,
    }
  );
  return { isLoading, fetchSubscription: mutate };
}

export function useCouponCodeUpdate({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ couponCode }) => updateCouponCode(couponCode),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updateCouponCode: mutate };
}

export function useIntervalUpdate({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ interval }) => updateInterval(interval),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updateInterval: mutate };
}

export function useUpdatePaymentMethod({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ setupIntentId }) => updatePaymentMethod(setupIntentId),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updatePaymentMethod: mutate };
}

export function useFreeTrialSetup({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ setupIntentId }) => submitFreeTrialSetup(setupIntentId),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, setupFreeTrial: mutate };
}

function paymentMethodsQueryKey(idToken) {
  return ['payment_methods', idToken];
}

export function useListPaymentMethods() {
  const { idToken } = useCurrentUserSession();

  const { isLoading, data } = useQuery(paymentMethodsQueryKey(idToken), listPaymentMethods);

  return { isLoading, data };
}

export function useResetListPaymentMethods() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(paymentMethodsQueryKey(idToken));
}

export function useUpdateSetupIntent({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ setupIntentId, optional, paymentMethodId }) => updateSetupIntent(setupIntentId, optional, paymentMethodId),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    }
  );

  return { isLoading, updateSetupIntent: mutate };
}

export function useRemovePaymentMethod({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ paymentMethodId }) => removePaymentMethod(paymentMethodId),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    }
  );

  return { isLoading, removePaymentMethod: mutate };
}

export function useUpgradeSubscription({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    upgradeSubscription,
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422
    }
  );

  return { isLoading, upgradeSubscription: mutate };
}
