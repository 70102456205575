import React from 'react';
import { useRouter } from 'next/router';
import {
  PODERSALUD_PRODUCT_ID,
  PANELIST_PRODUCT_ID,
  PODERCARD_PRODUCT_ID,
  CTA_CLICKED,
  LINK_CLICK,
  REWARDS_ENGAGEMENT_ENDED,
  SSO_SUCCESSFUL
} from '@separate/constants/analytics';
import { GOOGLE_TAG_KEY } from '@separate/env';
import { useGetUrl } from '@separate/hooks/getUrl';
import { useIsSingleSigningOn } from '@separate/hooks/singleSignOn';
import { useLocale } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import { logEvent, logPageView } from '@separate/utilities/analytics';
import { deviceType } from '@separate/utilities/device';
import { compact } from '@separate/utilities/object';
import {
  isPremiumPath,
  isEarnPath,
  isPodercardPath
} from "@separate/utilities/routes";
import { makeInternalUrlRelative } from '@separate/utilities/urlsProcessing';
import { getUtmTags } from '@separate/utilities/utmTagsStore';

export const useLogSSOSuccessful = () => {
  const { user, isLoggedIn } = useCurrentUser();
  const locale = useLocale();
  const { canonicalUrl } = useGetUrl();
  const { hasSingleSignedOn } = useIsSingleSigningOn();
  const pid = user?.pid;

  const [hasLoggedEvent, setHasLoggedEvent] = React.useState(false);

  React.useEffect(() => {
    if (isLoggedIn) return;

    setHasLoggedEvent(false);
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (!hasSingleSignedOn || hasLoggedEvent) return;

    logEvent(SSO_SUCCESSFUL, compact({
      source: 'web',
      pid,
      utm_parameters: getUtmTags(),
      language: locale,
      source_url: canonicalUrl,
    }));

    setHasLoggedEvent(true);
  }, [hasSingleSignedOn, hasLoggedEvent, pid, locale, canonicalUrl]);
};

export function useSetWelcomeTechReferrer() {
  const router = useRouter();

  React.useEffect(() => {
    function setWelcomeTechReferrer() {
      window.WelcomeTech = window.WelcomeTech || {};
      window.WelcomeTech.referrer = location.href;
    }

    router.events.on('routeChangeStart', setWelcomeTechReferrer);

    return () => {
      router.events.off('routeChangeStart', setWelcomeTechReferrer);
    };
  }, [router.events]);
}

export function useLogPageView() {
  useSetWelcomeTechReferrer();
  const utmTags = getUtmTags();

  React.useEffect(() => {
    if (!utmTags) return; // wait for tags to resolve; {} is true

    logPageView(utmTags);
  }, [utmTags]);
}

export function useGlobalClickListener() {
  React.useEffect(() => {
    window.addEventListener('click', (clickEvent) => {
      const { track, event, ...dataset } = clickEvent.target.dataset;
      if (track === 'true') { // i.e., the data-track property on the element
        logEvent(event, compact(dataset));
      }
    });
  }, []);
}

// Yoinked from sep
export function useRouterChangeAnalytics() {
  const router = useRouter();
  React.useEffect(() => {
    if(!GOOGLE_TAG_KEY) return true;

    const routerChangeAnalytics = (url) => {
      window.dataLayer.push({
        event: "content-view",
        "content-name": url,
        "content-view-name": router.asPath,
        pageTitle: document.title || ""
      });
    };
    router.events.on('routeChangeComplete', routerChangeAnalytics);
    return () => {
      router.events.off('routeChangeComplete', routerChangeAnalytics);
    };
  }, [router.events, router.asPath]);
}

export const useLogLinkClick = (props) => {
  const { href, position, activity, step, type, isExternal } = props;
  const { user } = useCurrentUser();
  const locale = useLocale();
  const { rootUrl, canonicalUrl, localePath } = useGetUrl();

  const destinationUrl = isExternal ? href : `${rootUrl}${localePath}${href}`;

  const logLinkClick = React.useCallback(() => {
    logEvent(LINK_CLICK, compact({
      source: 'web',
      pid: user?.pid,
      utm_parameters: getUtmTags(),
      device_type: deviceType(),
      language: locale,
      source_url: canonicalUrl,
      destination_url: destinationUrl,
      position,
      activity,
      step,
      type,
    }));
  }, [activity, canonicalUrl, destinationUrl, locale, position, step, type, user]);

  return { logLinkClick };
};

export const useLogRewardsEngagementEndedEvent = (survey) => {
  React.useEffect(() => {
    if (!survey) return;

    logEvent(REWARDS_ENGAGEMENT_ENDED, compact(
      {
        type: survey.is_study ? 'study' : 'survey',
        id: survey.id,
        product_id: survey.premium
          ? `[${PANELIST_PRODUCT_ID}, ${PODERSALUD_PRODUCT_ID}]`
          : PANELIST_PRODUCT_ID,
        service_utilization: "true",
      }
    ));
  }, [survey]);
};

export const useLogCtaClicked = ()  => {
  const { isLoggedIn } = useCurrentUser();
  const utmTags = getUtmTags();

  const handleLogEvent = (productId) =>  {
    logEvent(CTA_CLICKED, {
      product_id: productId,
      logged_in: isLoggedIn.toString(),
      ...utmTags
    });
  };

  const logCtaHandleClick = (href) => {
    const ctaURL = makeInternalUrlRelative(href);

    if (isPremiumPath(ctaURL)) handleLogEvent(PODERSALUD_PRODUCT_ID);

    if (isEarnPath(ctaURL)) handleLogEvent(PANELIST_PRODUCT_ID);

    if (isPodercardPath(ctaURL)) handleLogEvent(PODERCARD_PRODUCT_ID);
  };

  return { logCtaHandleClick };
};
